
import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Row, Col, InputRef, Tag, Upload, Typography, UploadProps, UploadFile } from "antd";
import { Else, If, Then } from 'react-if';
import { Rule } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import { PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";


interface Props {
    onButtonsChange: (buttons: Array<string>) => void,
    selectedButtons: string[],
    fileList: UploadFile<any>[],
    onFileListChange: (fl: UploadFile<any>[]) => void,
}

export const Step1 = ({ onButtonsChange, onFileListChange, selectedButtons, fileList }: Props) => {
    const [buttons, setButtons] = useState<string[]>(selectedButtons);
    const [buttonsVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<InputRef>(null);
    const { campaign, duplicated } = useSelector((state: RootState) => state.campaign);

    const rules: Record<string, Rule[]> = {
        name: [
            {
                required: true,
                min: 3,
                message: 'Informe o nome da campanha'
            },
        ],
        message: [
            {
                required: true,
                min: 2,
                message: 'Informe o conteúdo da mensagem'
            },
        ],
    }

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const onChangeButton = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const onConfirmButton = () => {
        if (inputValue && buttons.indexOf(inputValue) === -1) {
            console.log('Ammmm', [...buttons, inputValue])
            setButtons([...buttons, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const onRemoveButton = (removedTag: string) => {
        setButtons(buttons.filter(tag => tag !== removedTag));
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const renderizeButtons = (button: string) => {
        const tagElem = (
            <Tag
                closable
                onClose={(e) => {
                    e.preventDefault();
                    onRemoveButton(button);
                }}
            >
                {button}
            </Tag>
        );
        return (
            <span key={button} style={{ display: 'inline-block' }}>
                {tagElem}
            </span>
        );
    };

    const handleUploadChange: UploadProps['onChange'] = (info) => {
        let newFileList = [...info.fileList];

        newFileList = newFileList.map((file) => {
            return { ...file, status: 'success' };
        });

        onFileListChange(newFileList);
    };

    useEffect(() => {
        if (buttonsVisible) {
            inputRef.current?.focus();
        }
    }, [buttonsVisible]);

    useEffect(() => {
        if (Array.isArray(buttons)) {
            onButtonsChange(buttons);
        }

    }, [buttons]);

    useEffect(() => {
        if (duplicated && campaign.args.buttons) {
            setButtons(campaign.args.buttons.map((b: any) => b.body))
        }
    }, [])

    return (
        <div>
            <Col md={24} style={{ marginBottom: 20 }}>
                <Form.Item
                    name="name"
                    label="Nome"
                    rules={rules.name}
                    hasFeedback
                    style={{ marginBottom: 5 }}
                >
                    <Input placeholder="Digite o nome da campanha" />
                </Form.Item>
                <Typography.Text className="text-muted">
                    Define um nome para sua campanha. Exemplo: "Ofertas 2023"
                </Typography.Text>
            </Col>

            <Col md={24} style={{ marginBottom: 20 }}>
                <Form.Item
                    name="message"
                    label="Mensagem"
                    rules={rules.message}
                    hasFeedback
                    style={{ marginBottom: 5 }}
                >
                    <TextArea
                        rows={6}
                        placeholder="Digite a mensagem que será enviada"
                    />
                </Form.Item>
                <Typography.Text className="text-muted">
                    A mensagem que você inserir nesse campo será enviada para todos os usuários selecionados nesta campanha.
                </Typography.Text>
            </Col>

            {/* <Col md={24} style={{ marginBottom: 20 }}>
                <Form.Item
                    label="Botões"
                    hasFeedback
                >
                    <>
                        <div style={{ marginBottom: 16 }}>
                            <TweenOneGroup
                                enter={{
                                    scale: 0.8,
                                    opacity: 0,
                                    type: 'from',
                                    duration: 100,
                                }}
                                onEnd={(e) => {
                                    if (e.type === 'appear' || e.type === 'enter') {
                                        (e.target as any).style = 'display: inline-block';
                                    }
                                }}
                                leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                                appear={false}
                            >
                                {buttons.map((e) => renderizeButtons(e))}
                            </TweenOneGroup>
                        </div>
                        <If condition={buttonsVisible}>
                            <Then>
                                <Input
                                    ref={inputRef}
                                    type="text"
                                    size="small"
                                    style={{ width: 78 }}
                                    value={inputValue}
                                    onChange={(e) => onChangeButton(e)}
                                    onBlur={onConfirmButton}
                                    onPressEnter={onConfirmButton}
                                />
                            </Then>
                            <Else>
                                <Tag onClick={showInput} className="site-tag-plus">
                                    <PlusOutlined />Novo botão
                                </Tag>
                            </Else>
                        </If>
                    </>
                </Form.Item>
                <Typography.Text className="text-muted">
                    Os botões são opcionais, são as opções em forma de botão que estarão disponíveis na mensagem a ser enviada.
                </Typography.Text>
            </Col> */}
            <Row>
                <Col span={24}>
                    <Form.Item label="Selecionar arquivo" name="dragger" valuePropName="fileList" getValueFromEvent={normFile}>
                        <Typography.Text className="text-muted">
                            O envio de arquivo na campanha também é opcional. Você pode enviar imagens ou documentos no formato pdf.
                        </Typography.Text>
                        <Upload.Dragger
                            onChange={handleUploadChange}
                            multiple={false}
                            maxCount={1}
                            accept="image/*,.pdf"
                            name="files"
                            fileList={fileList}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Clique ou arraste um arquivo</p>
                            <p className="ant-upload-hint">Envie apenas um arquivo.</p>
                        </Upload.Dragger>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}