export const fileToBase64 = async (file: Blob): Promise<string> => {
    return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        reader.readAsDataURL(file);
  
        reader.onload = () => {
            baseURL = reader.result as string;
            resolve(baseURL as string);
        };
      });
}