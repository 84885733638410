import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Input, message, Alert, Drawer, Tabs, Checkbox, Row, Col, Button, Typography } from "antd";
import { If, When } from 'react-if';
import { motion } from "framer-motion";
import { Rule } from "antd/lib/form";
import { MailOutlined, UserOutlined, SolutionOutlined } from '@ant-design/icons';

import { organizationService } from '../../../services/organization.service';
import { RootState } from "../../../store";
import { OrganizationTypes, UserTypes } from "../../../types";
import { permissionService } from "../../../services/permission.service";
import Loading from "../../loading";
import { departmentService } from "../../../services/department.service";
import { DepartmentList } from "../../lists/department-list";
import Flex from "../../flex";
import { flagsLabelText, modulePermissions } from "../../../constants/module.permissions";

export const UserForm = () => {
    const [form] = Form.useForm<OrganizationTypes.UserForm>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [permissions, setPermissions] = useState<string[]>([]);
    const [selectedFlags, setSelectedFlags] = useState<string[]>([]);
    const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
    const [fetchingPermissions, setFetchingPermissions] = useState(false);
    const { modal, user, loading } = useSelector((state: RootState) => state.organization);

    const isFromEdit = modal.userForm && user?.id;
    const userFlags = user ? Object.keys(user?.permissions?.flag || {}).map((f) => f) : [];

    const rules: Record<string, Rule[]> = {
        name: [
            { 
                required: true,
                message: 'Informe o nome completo'
            },
        ],
        email: [
            { 
                required: true,
                message: 'Por favor, informe o seu email',
            },
            { 
                type: 'email',
                message: 'O email informado está incorreto'
            }
        ],
    }

    const onClose = () => {
        form.resetFields();
        
        organizationService.dispatch.setUser({} as UserTypes.Model);
        organizationService.dispatch.toggleModal('userForm', false);
    }

    const flagIsSelected = (flag: string) => selectedFlags?.includes(flag);

    const selectFlag = (flag: string) => {
        if (flag === 'ADMIN') {
            if (flagIsSelected(flag)) {
                setSelectedFlags([]);
                return;
            }
            setSelectedFlags(permissions);
            return;
        }

        if (flagIsSelected(flag)) {
            const newFlags = selectedFlags?.filter(f => f !== flag && f !== 'ADMIN');
            setSelectedFlags(newFlags);
            return;
        }

        setSelectedFlags([flag, ...selectedFlags]);
    }

    const onSubmit = async (values: OrganizationTypes.UserForm) => {
        if (!isFromEdit) {
            return await organizationService.addUser({ ...values, flags: selectedFlags, departments: selectedDepartments }, (isValid, msg) => {
                if (!isValid) {
                    message.error(msg);

                    setErrorMessage(msg);
                    return;
                }

                onClose();

                message.success(msg);
            });
        }

        return await organizationService.updateUser({ ...values, id: user.id, flags: selectedFlags, departments: selectedDepartments }, (isValid, msg) => {
            if (!isValid) {
                message.error(msg);

                setErrorMessage(msg);
                return;
            }

            onClose();

            message.success(msg);
        });
    }

    useEffect(() => {
        form.setFieldValue('flags', selectedFlags);
    }, [selectedFlags]);

    useEffect(() => {
        (async () => {
            setFetchingPermissions(true);

            const permissionsResult = await permissionService.list();
            setPermissions(permissionsResult!);

            setFetchingPermissions(false);

            if (isFromEdit) {
                setSelectedDepartments(user?.usersxdepartments?.map((ud => ud.department.id)));
                setSelectedFlags(userFlags?.includes('ADMIN') ? permissionsResult as string[] : userFlags);
                form.setFieldsValue({
                    name: user?.name,
                    email: user?.email,
                    enabled: user?.enabled || true,
                });
            }
        })();
    }, []);

    return (
        <Modal
            centered
            open={modal.userForm}
            onCancel={onClose}
            width={1000}
            cancelText={'Cancelar'}
            confirmLoading={loading.userForm}
            okText={'Salvar Alterações'}
            title={isFromEdit ? 'Alterar atendente' : 'Novo atendente'}
            onOk={() => form.submit()}
        >
            <If condition={errorMessage !== null && !loading.userForm}>
                <motion.div 
                    initial={{ opacity: 0, marginBottom: 0 }} 
                    animate={{ 
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0 
                    }}
                > 
                    <Alert 
                        onClose={() => setErrorMessage(null)} 
                        type="error" 
                        showIcon
                        message={errorMessage}
                        closable
                    />
                </motion.div>
            </If>
            <Form
                layout="vertical"  
                form={form}
                onFinish={async values => await onSubmit(values)}
            >
                <Tabs
                    defaultActiveKey="1"
                    type="card"
                >
                    <Tabs.TabPane tab="Dados para acesso" key="tab-user-1">
                        <Form.Item 
                            name="name" 
                            label="Nome" 
                            rules={rules.name}
                            hasFeedback
                        >
                            <Input 
                                placeholder="Digite o nome completo do atendente"
                                suffix={<UserOutlined className="text-default" />}
                            />
                        </Form.Item>
                        <Form.Item 
                            name="email"
                            id="email"
                            label="Email"
                            hasFeedback
                            rules={[
                                { 
                                    required: true,
                                    message: 'Por favor, informe o seu email',
                                },
                                { 
                                    type: 'email',
                                    message: 'O email informado está incorreto'
                                }
                            ]}>
                            <Input placeholder="Informe o email do atendente" suffix={<MailOutlined className="text-default" />}/>
                        </Form.Item>
                        <Form.Item
                            name="enabled"
                            id="enabled"
                        >
                            <Checkbox 
                                defaultChecked={user?.enabled || true}
                            >
                                <strong style={{ marginTop: 0}}>
                                    Atendente habilitado?
                                </strong>
                            </Checkbox>
                        </Form.Item>
                    </Tabs.TabPane>
                    
                    <Tabs.TabPane tab="Permissões do sistema" key="tab-user-2">
                        <Row gutter={24} justify="space-between">
                            {[
                                'ADMIN',
                                'CHAT',
                                ...Object.values(modulePermissions.bots),
                                ...Object.values(modulePermissions.departments),
                                ...Object.values(modulePermissions.leads)
                            ]?.map((p) => (
                                <Col md={8} key={`permission-${p}`}>
                                    <Checkbox
                                        checked={flagIsSelected(p)}
                                        onChange={(e) => selectFlag(p)}
                                    >
                                        {flagsLabelText[p]}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                        
                    </Tabs.TabPane>
                    
                    <Tabs.TabPane tab="Departamentos" key="tab-user-3">
                        <div className="container-fluid">
                            <Flex flexDirection="row-reverse" justifyContent="between" alignItems="center" className="py-3">
                                <>
                                    <Button size='small' type="primary" className="ml-2" onClick={() => departmentService.dispatch.toggleModal('form', true)}>
                                        <span>Novo departamento</span>
                                        <SolutionOutlined />
                                    </Button>
                                </>
                            </Flex>
                        </div>
                      
                        <DepartmentList 
                            enableFilters={false}
                            defaultSelectedRowKeys={selectedDepartments}
                            onRowSelect={(rows) => setSelectedDepartments(rows?.map(r => r.id))}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </Form>
        </Modal>
    )
}
