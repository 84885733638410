import { useSelector } from "react-redux";
import { RootState } from "../store";

export const useCheckPriv = () => {
    const { authUser } = useSelector((state: RootState) => state.user);

    const checkPriv = (priv: string | string[]) => {
        if (Object.keys(authUser?.permissions?.flag)?.includes('ADMIN')) {
            return true;
        }

        if (authUser?.permissions?.flag) {
          if (Array.isArray(priv)) {
            const result = priv.every((flag) => {
              return Object.keys(authUser?.permissions?.flag).includes(flag);
            });
            return result;
          } else {
            return Object.keys(authUser?.permissions?.flag).includes(priv);
          }
        }
        return false;
    }

    return { checkPriv };
}