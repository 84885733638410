import React from "react";
import { PersistGate } from "redux-persist/lib/integration/react";
import { getPersistor } from '@rematch/persist';
import { Provider } from 'react-redux';
import * as dayjs from 'dayjs';
import moment from 'moment';
import "@fontsource/poppins";
import { store } from './store';
import { AppRoutes } from './routes';
import Loading from "./components/loading";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/react-query";

dayjs.locale('pt-br');
moment.locale('pt-br');

const persistor = getPersistor();

function App() {

  return (
    <PersistGate persistor={persistor} loading={<Loading cover="content" />}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AppRoutes />
        </QueryClientProvider>
      </Provider>
    </PersistGate>
  );
}

export default App;
