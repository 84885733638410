import React, { Component } from 'react';

interface Props {
  type: any,
  className?: string,
}

const Icon: React.FC<Props> = ({
  type,
  className,
}) => (
  <>{React.createElement(type, { className })}</>
);

export default Icon;
