import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { DefaultRes } from '../types';
import { api } from '../http/http';

export function useGenericMutation<Params, ResultWithoutDefaultRes>(
    queryName: string, 
    urlToHit: string,
    method: 'post' | 'put' = 'post'
) {
    const makeGenericMutation = async (params: Params) => {
        const caller = method === 'post' ? api.post : api.put;

        const { data } = await caller<
            DefaultRes<ResultWithoutDefaultRes>,
            AxiosResponse<DefaultRes<ResultWithoutDefaultRes>>
        >(`/${urlToHit}`, {
            ...params,
        });

        return data;
    };

    return useMutation([queryName], makeGenericMutation);
}
