import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        {`${new Date().getFullYear()}`}
        {' '}
        <span className="font-weight-semibold">Tiger Desk</span>
      </span>
      <div>
        <a className="text-gray" href="/#" onClick={(e) => e.preventDefault()}>Termos & Condições</a>
        <span className="mx-2 text-muted"> | </span>
        <a className="text-gray" href="/#" onClick={(e) => e.preventDefault()}>Políticas de Privacidade</a>
      </div>
    </footer>
  );
}
