import { DepartmentTypes } from "./department.types";
import { LeadTypes } from "./lead.types";
import { UserTypes } from "./user.types"

export namespace AttendanceTypes {
    export enum Status {
        AWAITING = 'AWAITING',
        STARTED  = 'STARTED',
        FINISHED = 'FINISHED',
        POTENTIAL = 'POTENTIAL',
    }

    export type GetChatAttendancesByUser = {
        [Status.AWAITING]: Model[],
        [Status.STARTED]: Model[],
        [Status.POTENTIAL]: Model[],
        [Status.FINISHED]: Model[]
    }

    export type Filters = {
        status: Status
    };

    export type ByOrganizationFilters = {
        status: Status,
        userName: string,
        leadName: string,
        leadContact: string,
        protocol: string,
    };

    export enum MessageStatus {
        ERRORED,
        AWAITING,
        AWAITING_HANDSHAKE,
        SENT,
        READ,
        OPENED,
    }

    export interface Message {
        id: number
        identificator: string | null
        fromMe: boolean
        attendanceId: number
        content: any | null
        status: number
        createdAt: Date
        user: UserTypes.Model
        attendance: AttendanceTypes.Model
    }
    
    export interface Model {
        id: number
        protocol: string | null
        phoneNumber: string | null
        pushName: string | null
        photo: string | null
        userId: number
        user: UserTypes.Model
        status: Status
        leadId: number | null
        organizationId: number
        botId: number
        createdAt: Date
        updatedAt: Date
        messages?: Message[]
        lead: LeadTypes.Model
        department: DepartmentTypes.Model
    }

}