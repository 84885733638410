import React, { useEffect, useState } from 'react';
import { Button, Table, Card, Input, Menu, message, Tag, Space, Empty, Form } from 'antd';
import { DeleteOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Rule } from 'antd/lib/form';
import _ from 'lodash';

import { RootState } from '../../../store';
import Flex from '../../flex';
import { EllipsisDropdown } from '../../ellipsis-dropdown';
import ConfirmActionModal from '../../confirm-action-modal'; 
import { TagForm } from '../../forms/tag-form';
import { TagTypes } from '../../../types/tag.types';
import { tagService } from '../../../services/tag.service';

interface Props {
    defaultSelectedRowKeys?: number[] | string[],
    onRowSelect?(rows: TagTypes.Model[]): void,
}

export const TagList: React.FC<Props> = ({ defaultSelectedRowKeys, onRowSelect }) => {
    const [filterForm] = Form.useForm<TagTypes.Form>();

    const { pagination: tagPagination, loading } = useSelector((state: RootState) => state.tag);
    const [selectedTag, setSelectedTag] = useState<TagTypes.Model>({} as TagTypes.Model);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState([] as any);

    const onSelectChange = (selectedKeys: any) => {
        setSelectedRowKeys(selectedKeys);
    };

    const rules: Record<string, Rule[]> = {
        name: [
            { 
                required: false,
            },
        ],
    }

    const onEditPress = (tag: TagTypes.Model) => {
        tagService.dispatch.setTag(tag);
        tagService.dispatch.toggleModal('form', true);
    }

    const onDeletePress = () => {
        setDeleteModal(true);
    }

    const onDeleteCancel = () => {
        setDeleteModal(false);
    }

    const onChangePagination = (page: number, perPage: number) => {
        setPagination(p => ({ ...p, page, perPage }));
    }

    const onDeleteConfirm = async () => {
        await tagService.destroy(selectedTag?.id, (isValid, msg) => {
            if (!isValid) {
                message.error(msg);
                return;
            }

            message.success('Sua tag foi excluída com sucesso 🧨');
            onDeleteCancel();
        });
    }

    const onFilter = async (filter: TagTypes.Filters, clear = false) => {
        if (clear) {
            filterForm.resetFields();
        }

        await tagService.getMany({ ...pagination, filter: clear ? {} as TagTypes.Filters : { ..._.omitBy(filter, _.isNil) } as TagTypes.Filters });
    }

    useEffect(() => {
        tagService.getMany({ ...pagination });
    }, [pagination]);

    return (
        <>
            <TagForm />
            <ConfirmActionModal
                title={'Excluir tag'}
                loading={loading.destroy}
                visible={deleteModal}
                onCancel={onDeleteCancel}
                onDelete={onDeleteConfirm}
            />
            <Card>
                <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
                    <>
                        <Flex alignItems="center" justifyContent="center" className="mb-1" mobileFlex={false}>
                            <>
                                <Form
                                    layout="vertical"  
                                    form={filterForm}
                                    onFinish={async (values) => await onFilter(values)}
                                >
                                    <Space>
                                        <div className="mr-md-6 mb-3">
                                            <Form.Item 
                                                name="name" 
                                                rules={rules.name}
                                                hasFeedback
                                            >
                                                <Input
                                                    placeholder="Pesquisar por nome"
                                                    prefix={<SearchOutlined />}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Space>
                                </Form>
                            </>
                        </Flex>
                        <Flex flexDirection='row-reverse' className='p-2'>
                            <>
                                <Button type="primary" className='mr-2' block onClick={async () => await filterForm.submit()}>
                                    <span>Buscar</span>
                                    <SearchOutlined />
                                </Button>
                                <Button type="default" block onClick={async () => await onFilter({} as TagTypes.Filters, true)}>Limpar filtro</Button>
                            </>
                        </Flex>
                    </>
                </Flex>

                <div className="table-responsive">
                    <Table
                        columns={[
                            {
                                title: 'ID',
                                dataIndex: 'id',
                            },
                            {
                                title: 'Nome',
                                dataIndex: 'name',
                            },
                            {
                                title: 'Data de criação',
                                dataIndex: 'createdAt',
                                render: (_, tag) => moment(tag.createdAt).format('DD/MM/YYYY HH:mm')
                            },
                            {
                                title: 'Última atualização',
                                dataIndex: 'updatedAt',
                                render: (_, tag) => moment(tag.updatedAt).format('DD/MM/YYYY HH:mm')
                            },
                            {
                                title: 'Ações',
                                dataIndex: 'actions',
                                render: (_, tag) => (
                                    <div className="text-left">
                                        <EllipsisDropdown
                                            menu={
                                                <Menu>
                                                    <Menu.Item key="1" onClick={() => onEditPress(tag)}>
                                                        <EditOutlined />
                                                        <span className="ml-2">Editar</span>
                                                    </Menu.Item>
                                                    <Menu.Divider />
                                                    <Menu.Item key="2" onClick={() => {
                                                        setSelectedTag(tag);
                                                        onDeletePress();
                                                    }}>
                                                        <DeleteOutlined />
                                                        <span className="ml-2">Excluir tag</span>
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                        />
                                    </div>
                                )
                            }
                        ]}
                        dataSource={tagPagination.items}
                        loading={loading.fetchMany}
                        rowKey={'id'}
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedRowKeys,
                            defaultSelectedRowKeys: defaultSelectedRowKeys,
                            onChange: (selectedRowKeys: React.Key[], selectedRows) => {
                                // onRowSelect && onRowSelect(selectedRows);
                                onSelectChange(selectedRowKeys);
                            },
                        }}
                        locale={{
                            emptyText: <Empty className='mt-5 mb-5' image={Empty.PRESENTED_IMAGE_DEFAULT} description={'Aparentemente, você ainda não cadastrou nenhuma tag.'} />
                        }}
                        pagination={{
                            pageSize: pagination.perPage,
                            total: tagPagination.total,
                            defaultCurrent: 1,
                            onChange: onChangePagination
                        }}
                    />
                </div>
            </Card>
        </>
    )
}