import { createModel } from "@rematch/core";
import _ from "lodash";

import type { RootModel } from '.';
import { AttendanceTypes } from "../types/attendance.types";
import { PaginationFactory } from "../factory";
import { ChatShortcutTypes } from "../types/chat-shortcut.types";

export interface ChatStoreProps {
    unreadCount: Record<number, number>,
    modal: {
        chat: boolean,
        settingsNotification: boolean,
        chatShortcut: boolean,
        shortcutForm: boolean,
        chatLeads: boolean,
        newAttendanceForm: boolean,
        leadDetails: boolean,
        transferAttendance: boolean,
    },
    loading: {
        fetchAttendances: boolean,
        fetchAttendanceMessages: boolean,
        sendMessage: boolean,
        fetchShortcuts: boolean,
        createShortcut: boolean,
        updateShortcut: boolean,
        deleteShortcut: boolean,
        changeAttendanceStatus: boolean,
        startNewAttendance: boolean,
        transferAttendance: boolean,
    },
    selectedLeadId: number | undefined,
    attendance: AttendanceTypes.Model,
    attendances: AttendanceTypes.Model[],
    selectedAttendanceTab: keyof AttendanceTypes.GetChatAttendancesByUser,
    userChatAttendances: AttendanceTypes.GetChatAttendancesByUser,
    shortcut: ChatShortcutTypes.Model,
    attendanceMessages: AttendanceTypes.Message[],
    selectedAttendanceId: number,
    settings: {
        notification: {
            sound: boolean,
            popup: boolean,
        },
    },
    chatShortcutPagination: PaginationFactory<ChatShortcutTypes.Model>,
    startNewAttendanceFormData: {
        leadName: string,
        leadContact: string,
    }
};

export const chat = createModel<RootModel>()({
    state: {
        unreadCount: {} as Record<number, number>,
        selectedLeadId: 0,
        shortcut: {} as ChatShortcutTypes.Model,
        modal: {
            chat: false,
            settingsNotification: false,
            chatShortcut: false,
            shortcutForm: false,
            chatLeads: false,
            newAttendanceForm: false,
            leadDetails: false,
            transferAttendance: false,
        },
        loading: {
            fetchAttendances: false,
            fetchAttendanceMessages: false,
            fetchShortcuts: false,
            sendMessage: false,
            createShortcut: false,
            updateShortcut: false,
            deleteShortcut: false,
            changeAttendanceStatus: false,
            startNewAttendance: false,
            transferAttendance: false,
        },
        attendance: {} as AttendanceTypes.Model,
        attendances: [] as AttendanceTypes.Model[],
        userChatAttendances: {
            [AttendanceTypes.Status.AWAITING]: [] as AttendanceTypes.Model[],
            [AttendanceTypes.Status.STARTED]: [] as AttendanceTypes.Model[],
            [AttendanceTypes.Status.POTENTIAL]: [] as AttendanceTypes.Model[],
        } as AttendanceTypes.GetChatAttendancesByUser,
        selectedAttendanceTab: AttendanceTypes.Status.STARTED,
        attendanceMessages: [] as AttendanceTypes.Message[],
        selectedAttendanceId: 0,
        settings: {
            notification: {
                sound: true,
                popup: true,
            },
        },
        chatShortcutPagination: {
            items: [] as ChatShortcutTypes.Model[],
        } as PaginationFactory<ChatShortcutTypes.Model>,
        startNewAttendanceFormData: {
            leadName: '',
            leadContact: '',
        }
    },
    reducers: {
        setUnread: (store, u: Record<number, number>) => {
            store.unreadCount = u;
        },
        addUnread: (store, id: number) => {
            if (store.unreadCount[id]) {
                store.unreadCount[id] += 1;
            } 
            else {
                store.unreadCount[id] = 1;
            }
        },
        removeUnread: (store, id: number) => {
            store.unreadCount[id] = 0;
        },
        setSelectedLeadId: (store, id: number) => {
            store.selectedLeadId = id;
        },
        setSelectedAttendanceTab: (store, v: ChatStoreProps['selectedAttendanceTab']) => {
            store.selectedAttendanceTab = v;
        },
        setShortcut: (store, shortcut: ChatStoreProps['shortcut']) => {
            store.shortcut = shortcut;
        },
        setChatShortcutPagination: (store, pagination: ChatStoreProps['chatShortcutPagination']) => {
            store.chatShortcutPagination = pagination;
        },
        addChatShortcutPaginationItem: (store, shortcut: ChatShortcutTypes.Model) => {
            store.chatShortcutPagination.items = [...store.chatShortcutPagination.items, shortcut];
        },
        removeChatShortcutPaginationItem: (store, id: number) => {
            store.chatShortcutPagination.items = store.chatShortcutPagination.items.filter(i => i.id !== id);
        },
        updateChatShortcutPaginationItem: (store, id: number, shortcut: ChatShortcutTypes.Model) => {
            store.chatShortcutPagination.items = store.chatShortcutPagination.items.map((i) => {
                if (i.id === id) {
                    return shortcut;
                }

                return i;
            });
        },
        setNotificationSettings: (store, notification: ChatStoreProps['settings']['notification']) => {
            store.settings.notification = notification;
        },
        setSelectedAttendanceId: (store, id: ChatStoreProps['selectedAttendanceId']) => {
            store.selectedAttendanceId = id;
        },
        toggleModal: (store, prop: keyof ChatStoreProps['modal'], v: boolean) => {
            store.modal[prop] = v;
        },
        toggleLoading: (store, prop: keyof ChatStoreProps['loading'], v: boolean) => {
            store.loading[prop] = v;
        },
        addAttendance: (store, attendance: AttendanceTypes.Model) => {
            store.userChatAttendances[
                attendance.status as keyof AttendanceTypes.GetChatAttendancesByUser
            ] = [attendance, ...store.userChatAttendances[attendance.status as keyof AttendanceTypes.GetChatAttendancesByUser]];
        },
        changeAttendanceStatus: (store, dto: { currentStatus: keyof AttendanceTypes.GetChatAttendancesByUser, attendanceId: number, newStatus: AttendanceTypes.Status }) => {
            const attendance = store.userChatAttendances[dto.currentStatus]?.find((att) => att.id === dto.attendanceId);

            if (attendance) {
                // let's remove the attendance from the current status...
                store.userChatAttendances[dto.currentStatus] = store.userChatAttendances[dto.currentStatus].filter((att) => att.id !== dto.attendanceId);

                if (dto.newStatus !== AttendanceTypes.Status.FINISHED) {
                    store.userChatAttendances[dto.newStatus] = [attendance, ...store.userChatAttendances[dto.newStatus]];
                }

                // if the new status === finished, we should remove the selected attendance 
                if (dto.newStatus === AttendanceTypes.Status.FINISHED) {
                    store.selectedAttendanceId = 0;
                }

            }
        },
        setAttendancesPagination: (store, values: AttendanceTypes.GetChatAttendancesByUser) => {
            store.userChatAttendances = values
        },
        setAttendanceMessages: (store, msgs: AttendanceTypes.Message[]) => {
            store.attendanceMessages = msgs;
        },
        addAttendanceMessage: (store, attdId: number, msg: AttendanceTypes.Message) => {
           const att = [...store.userChatAttendances.AWAITING, ...store.userChatAttendances.STARTED, ...store.userChatAttendances.POTENTIAL]?.find((a) => a.id === attdId)!;

            if (att) {
                const { status } = att;

                const finalStatus = (status != AttendanceTypes.Status.AWAITING && status !== AttendanceTypes.Status.STARTED) ? AttendanceTypes.Status.POTENTIAL : status;

                store.userChatAttendances[finalStatus]?.forEach(attendance => {
                    if (attendance.id === attdId) {
                        const messageAlreadyIn = attendance?.messages?.find(am => am.identificator === msg.identificator);
    
                        if (!messageAlreadyIn) {
                            attendance.messages = attendance.messages?.concat([msg]);
                        }
                    }
                });
            }
        },
        setAttendance: (store, att: AttendanceTypes.Model) => {
            store.attendance = att;
        },
        startNewAttendanceFormData: (store, leadName: string, leadContact: string) => {
            store.startNewAttendanceFormData.leadName = leadName;
            store.startNewAttendanceFormData.leadContact = leadContact;
        },
    }
});