import { AxiosResponse } from 'axios';
import qs from 'qs-stringify';
import { PaginationFactory, PaginatorFactory } from '../factory';

import { api, sanitalizeApiException } from "../http/http";
import { store } from '../store';
import { DefaultCallback, DefaultRes, LeadTypes } from '../types';
import { AttendanceTypes } from '../types/attendance.types';
import queryStringify from 'qs-stringify';

export const leadService = {
    dispatch: store.dispatch.lead,

    fetchAttendancesByLead: async (pagination: PaginatorFactory<AttendanceTypes.Filters>, leadId: number, callback: DefaultCallback) => {
        try {
            leadService.dispatch.toggleLoading('fetchAttendancesByLead', true);

            const qsfy = queryStringify({
                perPage: pagination.perPage,
                page: pagination?.page,
                filter: {
                    ...pagination?.filter,
                },
            });

            const { 
                data: {
                    isValid,
                    message,
                    result,
                }
            } = await api.get<
                DefaultRes<PaginationFactory<AttendanceTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<AttendanceTypes.Model>>>
            >(`/Attendances/ListAttendancesByLead/${leadId}?${qsfy}`);

            leadService.dispatch.toggleLoading('fetchAttendancesByLead', false);

            if (!isValid) {
                return callback(isValid, message[0]);
            }

            leadService.dispatch.setLeadAttendancesPagination(result);

            return callback(isValid, message);

        } catch (error) {
            leadService.dispatch.toggleLoading('fetchAttendancesByLead', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    getById: async (leadId: number, callback: DefaultCallback) => {
        try {
            leadService.dispatch.toggleLoading('getById', true);
            const { 
                data,
            } = await api.get<
                DefaultRes<LeadTypes.Model>,
                AxiosResponse<DefaultRes<LeadTypes.Model>>
            >(`/Leads/${leadId}`);

            leadService.dispatch.toggleLoading('destroy', false);

            if (!data.isValid) {
                return callback(false, data?.message[0]);
            }

            leadService.dispatch.setLead(data.result);

            return callback(true, 'Informações sobre o lead obtidas com sucesso.');
        } catch (error) {
            leadService.dispatch.toggleLoading('getById', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    destroy: async (leadId: number, callback: DefaultCallback) => {
        try {
            leadService.dispatch.toggleLoading('destroy', true);

            const { 
                data,
            } = await api.delete<
                DefaultRes<boolean>,
                AxiosResponse<DefaultRes<boolean>>
            >(`/Leads/${leadId}`);

            if (!data.isValid) {
                leadService.dispatch.toggleLoading('destroy', false);
                return callback(false, data?.message[0]);
            }

            await leadService.getMany({ page: 1, perPage: 50 });
            leadService.dispatch.toggleLoading('destroy', false);

            return callback(true, 'Departamento excluído com sucesso!');

        } catch (error) {
            leadService.dispatch.toggleLoading('destroy', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    getMany: async (pagination: PaginatorFactory<LeadTypes.Filters>, callback?: DefaultCallback) => {
        try {
            leadService.dispatch.toggleLoading('fetchMany', true);

            const qsfy = qs({
                perPage: pagination.perPage,
                page: pagination?.page,
                filter: {
                    ...pagination?.filter,
                },
            });

            const { 
                data: { 
                    result: { 
                        items,
                        page,
                        perPage,
                        total,
                    }, 
                isValid, 
                message 
            }} = await api.get<
                DefaultRes<PaginationFactory<LeadTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<LeadTypes.Model>>>
            >(`/Leads?${qsfy}`);
            
            if (!isValid) {
                leadService.dispatch.toggleLoading('fetchMany', false);
                return callback && callback(false, message[0]);
            }
            
            leadService.dispatch.setPagination({ items, page, perPage, total });

            leadService.dispatch.toggleLoading('fetchMany', false);
            return callback && callback(true, message[0]);
        } catch (error) {
            leadService.dispatch.toggleLoading('fetchMany', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    create: async (
        name: string,
        contact: string,
        callback: DefaultCallback,
        tags?: number[],
    ) => {
        try {
            leadService.dispatch.toggleLoading('form', true);

            const response = await api.post<
                DefaultRes<LeadTypes.Model>,
                AxiosResponse<DefaultRes<LeadTypes.Model>>
            >('/Leads', {
                name,
                contact,
                args: {},
                channel: 'whatsapp',
                tags,
            });
            
            if (!response.data.isValid) {
                leadService.dispatch.toggleLoading('form', false);
                return callback(false, response.data?.message[0]);
            }

            leadService.dispatch.addPaginationItem(response.data.result);
            leadService.dispatch.toggleLoading('form', false);

            return callback(true, 'Lead criado com sucesso!');

        } catch (error) {
            leadService.dispatch.toggleLoading('form', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    import: async (
        leads: { name: string, contact: string, args: object, channel: string }[],
        callback: DefaultCallback,
        tags?: number[],
    ) => {
        try {
            leadService.dispatch.toggleLoading('importLeads', true);

            const response = await api.post<
                DefaultRes<LeadTypes.Model>,
                AxiosResponse<DefaultRes<LeadTypes.Model>>
            >('/Leads/Import', {
                leads,
                tags,
            });
            
            if (!response.data.isValid) {
                leadService.dispatch.toggleLoading('importLeads', false);
                return callback(false, response.data?.message[0]);
            }

            leadService.dispatch.addPaginationItem(response.data.result);
            leadService.dispatch.toggleLoading('importLeads', false);

            return callback(true, 'Leads importados com sucesso!');

        } catch (error) {
            leadService.dispatch.toggleLoading('importLeads', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    update: async (
        id: number, 
        name: string, 
        contact: string,
        callback: DefaultCallback,
        tags?: number[]
    ) => {
        try {
            leadService.dispatch.toggleLoading('form', true);

            const response = await api.put<
                DefaultRes<LeadTypes.Model>,
                AxiosResponse<DefaultRes<LeadTypes.Model>>
            >(`/Leads/${id}`, {
                name,
                contact,
                args: {},
                channel: 'whatsapp',
                tags,
            });
            
            if (!response.data.isValid) {
                leadService.dispatch.toggleLoading('form', false);
                return callback(false, response.data?.message[0]);
            }

            leadService.dispatch.updatePaginationItem(id, response.data.result);
            leadService.dispatch.toggleLoading('form', false);
            return callback(true, 'Lead alterado com sucesso!');

        } catch (error) {
            leadService.dispatch.toggleLoading('form', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },
}