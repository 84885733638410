import React from 'react';

import {
  Button,
  Modal, Result,
} from 'antd';
import { ResultStatusType } from 'antd/lib/result';

interface Props {
  visible: boolean,
  loading: boolean,
  contentSubtitle?: string,
  title: string,
  status?: ResultStatusType,
  onDelete(): Promise<void>,
  onCancel(): void,
}

const ConfirmActionModal: React.FC<Props> = ({
  visible,
  loading,
  title,
  contentSubtitle,
  status = "warning",
  onDelete,
  onCancel,
}) => (
  <Modal
    title={title}
    open={visible}
    onCancel={onCancel}
    footer={null}
  >
    <Result
      status={status}
      title="Deseja realizar essa ação?"
      style={{ marginTop: -60, marginBottom: -30 }}
      subTitle={contentSubtitle || "Esse registro será excluído permanentemente da base de dados e não será possível recuperá-lo."}
      extra={[
        <Button onClick={onCancel} disabled={loading}>Cancelar</Button>,
        <Button loading={loading} type="primary" onClick={onDelete}>
          Confirmar
        </Button>,
      ]}
    >
    </Result>
  </Modal>
);

export default ConfirmActionModal;
