import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from '../store';
import { DefaultRes } from '../types';
import { exceptions } from '../constants/exceptions.constants';
import { message } from 'antd';

export const ENVS = {
    dev: {
        BASE_URL: 'http://localhost:5001/api/v1.0/',
        SOCKET_BASE_URL: 'http://localhost:5001',
    },
    stg: {
        BASE_URL: 'http://localhost:3333/api/v1.0/',
        SOCKET_BASE_URL: 'http://localhost:9000',
    },
    prd: {
        BASE_URL: 'https://api.tigerdesk.me/api/v1.0/',
        SOCKET_BASE_URL: 'https://api.tigerdesk.me',
    },
};
export const api = axios.create({
    baseURL: ENVS.prd.BASE_URL,
});

export const sanitalizeApiException = (error: any) => {
    if (error?.response) {
        const errorCode = error.response?.data?.message;

        return exceptions[errorCode!] ? exceptions[errorCode!] : 'Ocorreu um erro desconhecido, contate um administrador do sistema.'
    }

    return exceptions['UNKNOWN'];
}


const onRequest = (axiosConfig: AxiosRequestConfig): AxiosRequestConfig => {
    return {
        ...axiosConfig,
        headers: {
            'authorization': `${store.getState().user.token}`,
        }
    };
};
  
const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponseError = (error: AxiosError<DefaultRes>): Promise<AxiosError> => {
    const messageError = JSON.parse(error.request.response).message;
    if (String(messageError) === 'E_EXPIRED_TOKEN') {
        store.dispatch.user.destroySession();
        localStorage.removeItem('persist:tiger-core-persistor');
        message.error('Sua sessão expirou, realize login novamente para continuar.');
    }
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse<DefaultRes>) => {
    return response;
};
// @ts-ignore
api.interceptors.request.use(onRequest, onRequestError);
// @ts-ignore
api.interceptors.response.use(onResponse, onResponseError);
  