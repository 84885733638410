import type { RootModel } from '.';
import { createModel } from "@rematch/core";

import { PaginationFactory } from '../factory';
import { UserTypes } from '../types';
import { MetricTypes } from '../types/metrics.types';

export interface OrganizationStoreProps {
    user: UserTypes.Model,
    userPagination: PaginationFactory<UserTypes.Model>,
    loading: {
        fetchUsers: boolean,
        userForm: boolean,
        setOrganization: boolean
    },
    modal: {
        userForm: boolean,
    },
    metrics: MetricTypes.Dashboard
};
export const organization = createModel<RootModel>()({
    state: {
        user: {} as UserTypes.Model,
        userPagination: {
            items: [] as UserTypes.Model[],
        } as PaginationFactory<UserTypes.Model>,
        loading: {
            fetchUsers: false,
            userForm: false,
            setOrganization: false,
        },
        modal: {
            userForm: false,
        },
        metrics: {} as MetricTypes.Dashboard,
    },
    reducers: {
        setMetrics: (store, metrics: MetricTypes.Dashboard) => {
            store.metrics = metrics;
        },
        setUser: (store, user: UserTypes.Model) => {
            store.user = user;
        },
        toggleLoading: (
            store, prop: keyof OrganizationStoreProps['loading'], v: boolean) => {
                store.loading[prop] = v;
            },
        setUserPagination: 
            (store, pagination: OrganizationStoreProps['userPagination']) => {
                store.userPagination = pagination;
            },
        addUserPaginationItem: (store, user: UserTypes.Model) => {
            store.userPagination.items = [...store.userPagination.items, user];
        },
        removeUserPaginationItem: (store, id: number) => {
            store.userPagination.items = store.userPagination.items.filter(i => i.id !== id);
        },
        updateUserPaginationItem: (store, id: number, user: UserTypes.Model) => {
            store.userPagination.items = store.userPagination.items.map((i) => {
                if (i.id === id) {
                    return user;
                }

                return i;
            });
        },
        eraseFormModal: (store) => {
            store.modal.userForm = false;
            store.user = {} as UserTypes.Model;
            store.loading.userForm = false;
        },
        toggleModal: (store, prop: keyof OrganizationStoreProps['modal'], v: boolean) => {
            store.modal[prop] = v;
        },
    },
});
