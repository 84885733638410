import { AxiosResponse } from 'axios';
import { DefaultCallback, DefaultRes, UserTypes } from '../types';
import { api, sanitalizeApiException } from '../http/http';
import { store } from '../store';
import { browserInfo } from '../helpers/device';

export class AuthService {
    public static readonly dispatch = store.dispatch.user;

    public static destroySession() {
        const { dispatch: { destroySession } } = AuthService;

    }

    public static async login(
        email: string,
        password: string,
        callback?: (isValid: boolean, message: string, user?: UserTypes.Model) => void,
    ) {
        const { dispatch: { toggleLoading, auth } } = AuthService;

        try {
            toggleLoading('login', true);

            const device = browserInfo().os.name || 'unknown';
            const clientInfo = browserInfo().ua;

            const { data: { isValid, message, result }} = await api.post<
                DefaultRes<UserTypes.Model>,
                AxiosResponse<DefaultRes<{ user: UserTypes.Model, token: string }>>
            >(`/Auth/Login`, { email, password, clientInfo, device });

            toggleLoading('login', false);

            if (isValid) {
                auth(result?.user, result?.token);
            }
            
            return callback && callback(isValid, message, result?.user);
        } catch (error: any) {
            toggleLoading('login', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    public static async forgetPassword(email: string, callback?: DefaultCallback) {
        const { dispatch: { toggleLoading } } = AuthService;

        try {
            toggleLoading('forgetPassword', true);

            const { data: { isValid, message }} = await api.post<DefaultRes, AxiosResponse<DefaultRes>>('/Auth/RecoverPassword', { email });

            toggleLoading('forgetPassword', false);

            return callback && callback(isValid, message);
        } catch(error) {
            toggleLoading('forgetPassword', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    public static async resetPassword(password: string, token: string, callback?: DefaultCallback) {
        const { dispatch: { toggleLoading } } = AuthService;
        
        try {
            toggleLoading('resetPassword', true);

            const { data: { isValid, message }} = await api.post<DefaultRes, AxiosResponse<DefaultRes>>('/Auth/ResetPassword', { password, token });

            toggleLoading('resetPassword', false);
            
            return callback && callback(isValid, message);
        } catch(error) {
            toggleLoading('resetPassword', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    public static async register(
        dto: UserTypes.Register,
        callback?: DefaultCallback,
    ) {
        const { dispatch: { toggleLoading, auth } } = AuthService;

        try {
            toggleLoading('register', true);

            const device = browserInfo().os.name || 'unknown';
            const clientInfo = browserInfo().ua;

            const { data: { isValid, message, result }} = await api.post<
                DefaultRes<UserTypes.Model>,
                AxiosResponse<DefaultRes<{ user: UserTypes.Model, token: string }>>
            >('/Auth/Register', { ...dto, device, clientInfo });

            toggleLoading('register', false);

            if (isValid) {
                auth(result?.user, result?.token);
            }
            
            return callback && callback(isValid, message);
        } catch (error: any) {
            toggleLoading('register', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }
}