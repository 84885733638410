import { AxiosResponse } from 'axios';
import qs from 'qs-stringify';

import { PaginationFactory, PaginatorFactory } from '../factory';
import { api, sanitalizeApiException } from "../http/http";
import { store } from '../store';
import { DefaultCallback, DefaultRes, OrganizationTypes, UserTypes } from '../types';
import { UserService } from './user.service';

export const organizationService = {
    dispatch: store.dispatch.organization,

    updateUser: async (
        dto: OrganizationTypes.UpdateUserParams,
        callback: DefaultCallback, 
    ) => {
        try {
            organizationService.dispatch.toggleLoading('userForm', true);

            const { 
                data: {
                    isValid, 
                    message 
                }
            } = await api.put<
                DefaultRes<UserTypes.Model>,
                AxiosResponse<DefaultRes<UserTypes.Model>>
            >(`/Organizations/UpdateUser/${dto.id}`, {
                ...dto,
                flags: dto.flags?.includes('ADMIN') ? ['ADMIN'] : dto.flags,
            });

            if (!isValid) {
                organizationService.dispatch.toggleLoading('userForm', false);
                return callback(false, message[0]);
            }

            await organizationService.getManyUsers({ page: 1, perPage: 50 });
            organizationService.dispatch.toggleLoading('userForm', false);
            
            return callback && callback(true, 'Usuário alterado com sucesso!');
        } catch (error) {
            organizationService.dispatch.toggleLoading('userForm', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    addUser: async (
        dto: OrganizationTypes.AddUserParams,
        callback: DefaultCallback, 
    ) => {
        try {
            organizationService.dispatch.toggleLoading('userForm', true);

            const { 
                data: { 
                    isValid, 
                    message 
                }
            } = await api.post<
                DefaultRes<UserTypes.Model>,
                AxiosResponse<DefaultRes<UserTypes.Model>>
            >(`/Organizations/AddUser`, {
                ...dto, 
                flags: dto.flags?.includes('ADMIN') ? ['ADMIN'] : dto.flags,
            });

            if (!isValid) {
                organizationService.dispatch.toggleLoading('userForm', false);
                return callback(false, message[0]);
            }

            await organizationService.getManyUsers({ page: 1, perPage: 50 });

            organizationService.dispatch.toggleLoading('userForm', false);

            return callback && callback(true, 'Usuário criado com sucesso!');
        } catch (error) {
            organizationService.dispatch.toggleLoading('userForm', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    getManyUsers: async (pagination: PaginatorFactory<UserTypes.Filters>, callback?: DefaultCallback) => {
        try {
            const qsfy = qs({
                perPage: pagination.perPage,
                page: pagination?.page,
                filter: {
                    ...pagination?.filter,
                },
            });

            const { 
                data: { 
                    result: { 
                        items,
                        page,
                        perPage,
                        total,
                    }, 
                isValid, 
                message 
            }} = await api.get<
                DefaultRes<PaginationFactory<UserTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<UserTypes.Model>>>
            >(`/Organizations/GetUsers?${qsfy}`);
            
            if (!isValid) {
                return callback && callback(false, message[0]);
            }
            
            organizationService.dispatch.setUserPagination({ items, page, perPage, total });

            return callback && callback(true, 'USERS_RETRIEVED');
        } catch (error) {
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    setOrganizationInfo: async (
        dto: OrganizationTypes.SetOrganizationParams,
        user: UserTypes.Model,
        callback: DefaultCallback, 
    ) => {
        try {
            organizationService.dispatch.toggleLoading('setOrganization', true);

            const { 
                data: { 
                    isValid, 
                    message 
                }
            } = await api.post<
                DefaultRes<OrganizationTypes.Model>,
                AxiosResponse<DefaultRes<OrganizationTypes.Model>>
            >(`/Organizations/SetOrganizationInfo`, {
                ...dto,
            });

            if (!isValid) {
                organizationService.dispatch.toggleLoading('setOrganization', false);
                return callback(false, message[0]);
            }

            const org = {...user.organizations, ...dto, status: 'complete'};
            
            UserService.dispatch.setAuthUserProps({...user, organizations: org});

            organizationService.dispatch.toggleLoading('setOrganization', false);

            return callback && callback(true, 'Organização salva com sucesso!');
        } catch (error) {
            organizationService.dispatch.toggleLoading('setOrganization', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

};