import React, { useState } from 'react';
import { Button, Typography, Breadcrumb } from 'antd';
import { PlusOutlined, HomeOutlined, SendOutlined } from '@ant-design/icons';

import Flex from '../../../../components/flex';
import { PageHeaderAlt } from '../../../../components/page-header-alt';
import { campaignService } from '../../../../services/campaigns.service';
import { CampaignList } from '../../../../components/lists/campaign-list';

const CampaignListScreen = () => {

    const onAddNew = () => {
        campaignService.dispatch.toggleModal('form', true);
    }

    return (
        <>
            <PageHeaderAlt
                className="border-bottom"
                breadcrumb={
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">
                            <HomeOutlined />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <SendOutlined />
                            <a href="/departments">Envios em massa</a>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            >
                <div className="container-fluid">
                    <Flex justifyContent="between" alignItems="center" className="py-4">
                        <>
                            <div>
                                <Typography.Title level={2}>Campanhas  <span> </span> </Typography.Title>

                                <Typography.Paragraph type="secondary">
                                    Uma campanha é uma estratégia de marketing ou publicidade para promover um produto, serviço, causa ou ideia, é possível enviar mensagens para diversos leads através dessa funcionalidade.
                                </Typography.Paragraph>
                            </div>

                            <Button size='small' type="primary" className="ml-2" onClick={onAddNew}>
                                <span>Nova campanha</span>
                                
                                <PlusOutlined />
                            </Button>
                        </>

                    </Flex>
                </div>
            </PageHeaderAlt>
            <div className={`my-4 container-fluid`}>
                <CampaignList />
            </div>
        </>
    )
}

export default CampaignListScreen;