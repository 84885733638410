
import { useEffect, useState } from "react";
import { Modal, Form, Input, message, Alert } from "antd";
import { useSelector } from "react-redux";
import { If } from 'react-if';
import { motion } from "framer-motion";
import { Rule } from "antd/lib/form";

import { RootState } from "../../../store";
import { TagTypes } from "../../../types/tag.types";
import { tagService } from "../../../services/tag.service";

export const TagForm = () => {
    const [form] = Form.useForm<TagTypes.Form>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { modal, tag, loading } = useSelector((state: RootState) => state.tag);

    const isFromEdit = modal.form && tag?.id;

    const onClose = () => {
        tagService.dispatch.eraseFormModal();
    }

    const rules: Record<string, Rule[]> = {
        name: [
            { 
                required: true,
                message: 'Informe o nome completo'
            },
        ],
    }

    const onSubmit = async (
        name: string,
    ) => {
        setErrorMessage(null);
        if (isFromEdit) {
            await tagService.update(tag?.id, name, (isValid, msg) => {
                if (!isValid) {
                    setErrorMessage(msg);
                    return;
                }

                form.resetFields();
                message.success(msg);
                onClose();
            });
            return;
        }

        await tagService.create(name, (isValid, msg) => {
            if (!isValid) {
                setErrorMessage(msg);
                return;
            }
            
            form.resetFields();
            message.success(msg);
            onClose();
        });
    }

    useEffect(() => {
        if (isFromEdit) {
            form.setFieldsValue({
                name: tag.name!,
            });
        }

        return () => {
            setErrorMessage(null);
        }

    }, [modal.form]);

    if (!modal.form) return null

    return (
        <Modal
            title={isFromEdit ? 'Editar Tag' : 'Nova Tag'}
            onCancel={onClose}
            open={modal.form}
            okText={'Salvar alterações'}
            cancelText={'Cancelar'}
            onOk={() => {
                form.submit();
            }}
            confirmLoading={loading.form}
        >
            <If condition={errorMessage !== null && !loading.form}>
                <motion.div 
                    initial={{ opacity: 0, marginBottom: 0 }} 
                    animate={{ 
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0 
                    }}
                > 
                    <Alert 
                        onClose={() => setErrorMessage(null)} 
                        type="error" 
                        showIcon
                        message={errorMessage}
                        closable
                    />
                </motion.div>
            </If>
            <Form
                layout="vertical"  
                form={form}
                onFinish={async (values) => onSubmit(values.name)}
            >
                <Form.Item 
                    name="name" 
                    label="Nome" 
                    rules={rules.name}
                    hasFeedback
                >
                    <Input placeholder="Informe o nome da Tag" />
                </Form.Item>
            </Form>
        </Modal>
    )
}