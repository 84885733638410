import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Modal, 
  Descriptions,
  Row,
  Tag,
  Table,
  Timeline,
  Col,
} from 'antd';
import { useSelector } from 'react-redux';
import { Then, When } from 'react-if';

import { RootState } from '../../store';
import { campaignService } from '../../services/campaigns.service';
import { PaginationFactory } from '../../factory';
import { InstaceEventTypes } from '../../types/instanceEvent.types';
import { InstanceEventService } from '../../services/instanceEvent.service';
import { RegexFactory } from '../../factory/regex.factory';

interface Props {
    visible: boolean,
    uuid: string,
    onClose(): void,
}

export const MessageEventHistoryModal: React.FC<Props> = ({
    uuid,
    visible,
    onClose,
}) => {
    const [loadingHistory, setLoadingHistory] = useState(false);
    const [history, setHistory] = useState<InstaceEventTypes.Model[]>();

    useEffect(() => {
        if (visible) {
            (async () => {
                setLoadingHistory(true);
    
                const res = await InstanceEventService.getEventHistory(uuid);
    
                setHistory(res);
    
                setLoadingHistory(false);
            })();
        } 
    }, [visible, uuid]);   

    const renderStep = (history: InstaceEventTypes.Model) => {
        if (history?.key === InstaceEventTypes.Key.CAMPAIGN_MESSAGE_SENT) {
            return 'Enviada para o lead'
        }

        if (history?.key === InstaceEventTypes.Key.CAMPAIGN_MESSAGE_UPDATE) {
            const status = (history?.rawWebhook as any)?.data[0]?.update?.status;

            if (status === 3) {
                return 'Mensagem confirmada (recebida)';
            }

            if (status === 4) {
                return 'Mensagem lida pelo lead';
            }

        }
    }

    return (
        <Modal
            title={'Histórico da mensagem'}
            open={visible}
            onCancel={onClose}
            footer={null}
            width={450}
        >
            <Col md={24} >
                <Timeline style={{ width: '100%'}} mode={'alternate'}>
                    {history?.map((item) => <Timeline.Item key={`${item?.key}-${item?.uuid}`} label={moment(item.createdAt).format('DD/MM/YYYY HH:mm')}>
                        {renderStep(item)}
                    </Timeline.Item>)}
                </Timeline>
            </Col>
        </Modal>
    )
}