import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import moment from 'moment';
import {
  Modal, 
  Descriptions,
  Row,
  Tag,
  Table,
  Tooltip,
  Divider,
  Drawer,
} from 'antd';
import { useSelector } from 'react-redux';
import { Then, When } from 'react-if';
import { FieldTimeOutlined } from '@ant-design/icons';

import { RootState } from '../../store';
import { campaignService } from '../../services/campaigns.service';
import { PaginationFactory } from '../../factory';
import { InstaceEventTypes } from '../../types/instanceEvent.types';
import { InstanceEventService } from '../../services/instanceEvent.service';
import { RegexFactory } from '../../factory/regex.factory';
import { MessageEventHistoryModal } from '../message-event-history-modal';

interface Props{}

export const CampaignDetailsModal: React.FC<Props> = () => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const [loadingEvents, setLoadingEvents] = useState(false);
    const [events, setEvents] = useState<PaginationFactory<InstaceEventTypes.Model>>();
    const [selectedEvent, setSelectedEvent] = useState<InstaceEventTypes.Model>();
    const [historyModal, setHistoryModal] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 5,
    });

    const { modal, campaign } = useSelector((state: RootState) => state.campaign);

    const onChangePagination = (page: number, perPage: number) => {
        setPagination({ page, perPage });
    }

    useEffect(() => {
        (async () => {
            setLoadingEvents(true);

            const res = await InstanceEventService.getMany({ 
                ...pagination, 
                filter: { identity: 'campaigns', identityId: campaign.id, excludeKey: 'CAMPAIGN_MESSAGE_UPDATE' } as InstaceEventTypes.Filters
            });

            setEvents(res);

            setLoadingEvents(false);
        })();
    }, [pagination]);   

    return (
        <>
            <Drawer
                placement='right'
                title={'Detalhes da campanha'}
                open={modal.details}
                onClose={() => campaignService.dispatch.toggleModal('details', false)}
                footer={null}
                width={isSmallScreen ? '90vw' : 850}
            >
                <Row>
                    <Descriptions title="" size='default' layout='vertical'>
                        <Descriptions.Item label="Nome" labelStyle={{ fontWeight: 600 }}>{campaign?.name}</Descriptions.Item>
                        <Descriptions.Item label="Protocolo" labelStyle={{ fontWeight: 600 }}>{campaign?.protocol}</Descriptions.Item>
                        <Descriptions.Item label="Status" labelStyle={{ fontWeight: 600 }}>
                            <When condition={campaign.status === 'done'}>
                                <Then>
                                    <Tag color='green'>Conclúido</Tag>
                                </Then>
                            </When>
                            <When condition={campaign.status === 'scheduled'}>
                                <Then>
                                    <Tag color='yellow'>Agendado</Tag>
                                </Then>
                            </When>
                            <When condition={campaign.status === 'executing'}>
                                <Then>
                                    <Tag color='orange'>Executando...</Tag>
                                </Then>
                            </When>
                        </Descriptions.Item>
                        <Descriptions.Item label="Data do agendamento" labelStyle={{ fontWeight: 600 }}>
                            {campaign.scheduled ? moment(campaign.scheduled).format('DD/MM/YYYY HH:mm') : 'Sem agendamento'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Criado em" labelStyle={{ fontWeight: 600 }}>
                            {moment(campaign?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Última atualização em" labelStyle={{ fontWeight: 600 }}>
                            {moment(campaign?.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
                        </Descriptions.Item>
                    </Descriptions>
                </Row>

                <Divider />
                
                <Descriptions title="Envios da campanha" style={{ marginTop: 15 }}></Descriptions>

                <div className="table-responsive">
                    <Table 
                        columns={[
                            {
                                title: 'Nome',
                                render: (_, event) => <>{(event?.content as any)?.lead?.name}</> 
                            },
                            {
                                title: 'Contato',
                                render: (_, event) => <>{RegexFactory.phoneNumber((event?.content as any)?.lead?.contact)}</> 
                            },
                            {
                                title: 'Status',
                                dataIndex: 'status',
                                render: (_, event) => (
                                    <>
                                        <When condition={event.key === InstaceEventTypes.Key.CAMPAIGN_MESSAGE_SENT}>
                                            <Then>
                                                <Tag color='green'>Envio realizado</Tag>
                                            </Then>
                                        </When>
                                        <When condition={event.key === InstaceEventTypes.Key.CAMPAIGN_MESSAGE_AWAITING}>
                                            <Then>
                                                <Tag color='yellow'>Em espera</Tag>
                                            </Then>
                                        </When>
                                        <When condition={event.key === InstaceEventTypes.Key.CAMPAIGN_MESSAGE_ERRORED}>
                                            <Then>
                                                <Tag color='danger'>Falha no envio</Tag>
                                            </Then>
                                        </When>
                                    </>
                                )
                            },
                            {
                                title: 'Bot',
                                render: (_, event) => <>{event?.bot?.name}</> 
                            },
                            {
                                title: 'Data de criação',
                                dataIndex: 'createdAt',
                                render: (_, event) => moment(event.createdAt).format('DD/MM/YYYY HH:mm')
                            },
                            {
                                title: 'Ações',
                                dataIndex: 'actions',
                                render: (_, event) => (
                                    <div className="text-right"> 
                                        <Tooltip placement="topLeft" title="Histórico">
                                            <FieldTimeOutlined 
                                                onClick={() => {
                                                    setSelectedEvent(event);
                                                    setHistoryModal(true);
                                                }} 
                                            />
                                        </Tooltip>
                                    </div>
                                )
                            }
                        ]} 
                        loading={loadingEvents}
                        dataSource={events?.items} 
                        pagination={{
                            pageSize: pagination.perPage,
                            total: events?.total,
                            defaultCurrent: 1,
                            onChange: onChangePagination
                        }}
                    />
                </div>
            </Drawer>

            <When condition={historyModal}>
                <MessageEventHistoryModal 
                    uuid={selectedEvent?.uuid!}
                    visible={historyModal}
                    onClose={() => setHistoryModal(false)}
                />
            </When>
        </>
    )
}