import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, message, Alert, Select } from "antd";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { If } from 'react-if';
import { Rule } from 'antd/lib/form';

import { organizationService } from '../../../services/organization.service';
import { RootState } from '../../../store';
import { ChatService } from '../../../services/chat.service';
import { botService } from '../../../services/bot.service';

export const NewAttendanceForm = () => {
    const [form] = Form.useForm<{
        leadName: string,
        userId: number,
        departmentId: number,
        contact: string,
        botId: number,
    }>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { userPagination } = useSelector((state: RootState) => state.organization);
    const { modal, loading, startNewAttendanceFormData } = useSelector((state: RootState) => state.chat);
    const { pagination: botPagination } = useSelector((state: RootState) => state.bot);
    const [selectedUserId, setSelectedUserId] = useState<number>();

    const onClose = () => {
        form.resetFields();
        ChatService.dispatch.toggleModal('newAttendanceForm', false);
        if (startNewAttendanceFormData?.leadName !== '' || startNewAttendanceFormData?.leadContact !== '') {
            ChatService.dispatch.toggleModal('chatLeads', true);
        }
        ChatService.dispatch.startNewAttendanceFormData('', '');
    }

    const selectedUser = userPagination?.items?.find(u => u.id === selectedUserId);

    const onSubmitNewAttendance = async (
        leadName: string, 
        contact: string, 
        userId: number, 
        departmentId: number,
        botId: number,
    ) => {
        ChatService.startLeadAttendance(
            leadName,
            contact,
            userId,
            departmentId,
            botId,
            (isValid, msg) => {
                if (!isValid) {
                    message.error(msg);

                    setErrorMessage(msg);
                    return;
                }

                message.success(msg);
                form.resetFields();
                ChatService.dispatch.toggleModal('newAttendanceForm', false);

                if (startNewAttendanceFormData?.leadName !== '' || startNewAttendanceFormData?.leadContact !== '') {
                    ChatService.dispatch.toggleModal('chatLeads', false);
                    ChatService.dispatch.startNewAttendanceFormData('', '');
                }
            }
        );
    }

    const rules: Record<string, Rule[]> = {
        leadName: [
            { 
                required: true,
                message: 'Informe o nome do lead'
            },
        ],
        contact: [
            { 
                required: true,
                message: 'Por favor, informe o número do whatsapp',
            },
        ],
        userId: [
            { 
                required: true,
                message: 'Por favor, selecione o atendente',
            },
        ],
        departmentId: [
            { 
                required: true,
                message: 'Por favor, selecione o departamento',
            },
        ],
        botId: [
            { 
                required: true,
                message: 'Por favor, selecione o bot responsável pelo atendimento',
            },
        ],
    }

    useEffect(() => {
        organizationService.getManyUsers({ page: 1, perPage: 100 });
        botService.getMany({ page: 1, perPage: 100 });
    }, []);

    useEffect(() => {
        form.setFieldValue('departmentId', undefined);
    }, [selectedUserId]);

    return (
        <Modal
            title={'Novo atendimento'}
            open={modal.newAttendanceForm}
            onCancel={onClose}
            okText={'Iniciar atendimento'}
            cancelText={'Cancelar'}
            confirmLoading={loading.startNewAttendance}
            onOk={() => form.submit()}
        >
            <If condition={errorMessage !== null && !loading.startNewAttendance}>
                <motion.div 
                    initial={{ opacity: 0, marginBottom: 0 }} 
                    animate={{ 
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0 
                    }}
                > 
                    <Alert 
                        onClose={() => setErrorMessage(null)} 
                        type="error" 
                        showIcon
                        message={errorMessage}
                        closable
                    />
                </motion.div>
            </If>

            <Form
                layout="vertical"
                form={form}
                onFinish={async values => await onSubmitNewAttendance(
                    values.leadName, 
                    values.contact, 
                    values.userId, 
                    values.departmentId,
                    values.botId,
                )}
            >
                <Form.Item 
                    name="leadName" 
                    label="Nome" 
                    hasFeedback
                    required
                    rules={rules.leadName}
                    hidden={startNewAttendanceFormData?.leadName !== ''}
                    initialValue={startNewAttendanceFormData?.leadName}
                >
                    <Input placeholder="Digite o nome do lead..." />
                </Form.Item>
                <Form.Item
                    name="contact" 
                    label="WhatsApp" 
                    hasFeedback
                    rules={rules.contact}
                    required
                    hidden={startNewAttendanceFormData?.leadContact !== ''}
                    initialValue={startNewAttendanceFormData?.leadContact}
                >
                    <Input placeholder="Digite o número de telefone do lead..." />
                </Form.Item>
                
                <Form.Item
                    name="botId"
                    label="Robô responsável"
                    rules={rules.botId}
                    required
                >   
                    <Select 
                        placeholder="Selecione um robô"
                        options={botPagination?.items?.map((bot) => ({
                            label: bot.name,
                            value: bot.id,
                        }))}
                    />
                </Form.Item>

                <Form.Item
                    name="userId"
                    label="Atendente"
                    rules={rules.userId}
                    required
                >   
                    <Select 
                        placeholder="Selecione um atendente"
                        onChange={(v) => {
                            setSelectedUserId(v);
                            form.setFieldValue('userId', v);
                        }}
                        options={userPagination?.items?.map((user) => ({
                            label: user.name,
                            value: user.id,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    name="departmentId"
                    rules={rules.departmentId}
                    label="Departamento"
                    required
                >   
                    <Select 
                        disabled={!selectedUser}
                        placeholder="Selecione um departamento"
                        options={selectedUser?.usersxdepartments?.map((ud) => ({
                            label: ud.department.name,
                            value: ud.department.id,
                        }))}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
};