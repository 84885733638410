import type { RootModel } from '.';
import { createModel } from "@rematch/core";

import { PaginationFactory } from '../factory';
import { BotTypes } from '../types';
import { AttendanceTypes } from '../types/attendance.types';

export interface BotStoreProps {
    bot: BotTypes.Model,
    pagination: PaginationFactory<BotTypes.Model>,
    botAttendancesPagination: PaginationFactory<AttendanceTypes.Model>,
    loading: {
        fetchMany: boolean,
        form: boolean,
        destroy: boolean,
        fetchAttendancesByBot: boolean,
    },
    modal: {
        form: boolean,
        connect: boolean,
        details: boolean,
    },
};

export const bot = createModel<RootModel>()({
    state: {
        bot: {} as BotTypes.Model,
        pagination: {
            items: [] as BotTypes.Model[],
        } as PaginationFactory<BotTypes.Model>,
        botAttendancesPagination: {
            items: [] as AttendanceTypes.Model[],
        } as PaginationFactory<AttendanceTypes.Model>,
        loading: {
            fetchMany: false,
            form: false,
            destroy: false,
            fetchAttendancesByBot: false,
        },
        modal: {
            form: false,
            connect: false,
            details: false,
        }
    },
    reducers: {
        setBotAttendancesPagination: (store, pagination: BotStoreProps['botAttendancesPagination']) => {
            store.botAttendancesPagination = pagination;
        },
        setBot: (store, bot: BotTypes.Model) => {
            store.bot = bot;
        },
        toggleLoading: (
            store, prop: keyof BotStoreProps['loading'], v: boolean) => {
                store.loading[prop] = v;
            },
        setPagination: 
            (store, pagination: BotStoreProps['pagination']) => {
                store.pagination = pagination;
            },
        addPaginationItem: (store, bot: BotTypes.Model) => {
            store.pagination.items = [...store.pagination.items, bot];
        },
        removePaginationItem: (store, id: number) => {
            store.pagination.items = store.pagination.items.filter(i => i.id !== id);
        },
        updatePaginationItem: (store, id: number, bot: BotTypes.Model) => {
            store.pagination.items = store.pagination.items.map((i) => {
                if (i.id === id) {
                    return bot;
                }

                return i;
            });
        },
        eraseFormModal: (store) => {
            store.modal.form = false;
            store.bot = {} as BotTypes.Model;
            store.loading.form = false;
        },
        toggleModal: (store, prop: keyof BotStoreProps['modal'], v: boolean) => {
            store.modal[prop] = v;
        },
    },
});
