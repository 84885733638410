import type { RootModel } from '.';
import { createModel } from "@rematch/core";

import { PaginationFactory } from '../factory';
import { TagTypes } from '../types/tag.types';

export interface TagStoreProps {
    tag: TagTypes.Model,
    pagination: PaginationFactory<TagTypes.Model>,
    loading: {
        fetchMany: boolean,
        form: boolean,
        destroy: boolean,
    },
    modal: {
        form: boolean,
    },
};
export const tag = createModel<RootModel>()({
    state: {
        tag: {} as TagTypes.Model,
        pagination: {
            items: [] as TagTypes.Model[],
        } as PaginationFactory<TagTypes.Model>,
        loading: {
            fetchMany: false,
            form: false,
            destroy: false,
        },
        modal: {
            form: false,
        },
    },
    reducers: {
        setTag: (store, tag: TagTypes.Model) => {
            store.tag = tag;
        },
        toggleLoading: (
            store, prop: keyof TagStoreProps['loading'], v: boolean) => {
                store.loading[prop] = v;
            },
        setPagination: 
            (store, pagination: TagStoreProps['pagination']) => {
                store.pagination = pagination;
            },
        addTagPaginationItem: (store, tag: TagTypes.Model) => {
            store.pagination.items = [...store.pagination.items, tag];
        },
        removeTagPaginationItem: (store, id: number) => {
            store.pagination.items = store.pagination.items.filter(i => i.id !== id);
        },
        updateTagPaginationItem: (store, id: number, tag: TagTypes.Model) => {
            store.pagination.items = store.pagination.items.map((i) => {
                if (i.id === id) {
                    return tag;
                }

                return i;
            });
        },
        eraseFormModal: (store) => {
            store.modal.form = false;
            store.tag = {} as TagTypes.Model;
            store.loading.form = false;
        },
        toggleModal: (store, prop: keyof TagStoreProps['modal'], v: boolean) => {
            store.modal[prop] = v;
        },
    },
});
