import React from 'react';
import { Menu } from 'antd';
import {
  RobotOutlined,
  DashboardOutlined,
  TeamOutlined,
  SolutionOutlined,
  SendOutlined,
  PushpinOutlined,
  ContactsOutlined,
  WechatOutlined,
  FolderOpenOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { useCheckPriv } from '../../../hooks/useCheckPriv';
import { modulePermissions } from '../../../constants/module.permissions';

interface MenuItemProps {
  label: string,
  route: string,
  icon?: JSX.Element,
}

const MenuItem: React.FC<MenuItemProps> = ({
  label,
  route,
  icon,
  ...props
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => navigate(route);

  return (
    <Menu.Item onClick={handleNavigate} className="mr-1" {...props}>
      {icon}
      <span>
        {label}
      </span>
    </Menu.Item>
  );
};

const MenuContent: React.FC = () => {
  const { authUser, user } = useSelector((state: RootState) => state.user);
  const { checkPriv } = useCheckPriv();
  
  return (
    <Menu
      theme="light"
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultOpenKeys={['/']}
    >
      {checkPriv('ADMIN') &&
        <Menu.ItemGroup title={authUser?.organizations?.name}>
          <MenuItem
            key="/"
            route="/"
            icon={<DashboardOutlined />}
            label="Dashboard"
          />
        </Menu.ItemGroup>
      }

      {(checkPriv(Object.values(modulePermissions.bots))) && (
        <MenuItem
          key="/bots"
          route="/bots"
          icon={<RobotOutlined />}
          label="Bots"
        />
      )}

      {(checkPriv(Object.values(modulePermissions.departments))) && (
        <MenuItem
          key="/departments"
          route="/departments"
          icon={<SolutionOutlined />}
          label="Departamentos"
        />
      )}

      {checkPriv('ADMIN') && (
        <MenuItem
          key="/users"
          route="/users"
          icon={<TeamOutlined />}
          label="Atendentes"
        />
      )}

      <MenuItem
        key="/tags"
        route="/tags"
        icon={<TagsOutlined />}
        label="Tags"
      />
      
      {(checkPriv(Object.values(modulePermissions.leads))) && (
        <MenuItem
          key="/leads"
          route="/leads"
          icon={<ContactsOutlined />}
          label="Leads"
        />
      )}

      <MenuItem
        key="/shortcuts"
        route="/shortcuts"
        icon={<PushpinOutlined />}
        label="Atalhos"
      />
      
      {checkPriv('ADMIN') && (
        <MenuItem
          key="/campaigns"
          route="/campaigns"
          icon={<SendOutlined />}
          label="Campanhas"
        />
      )}
      
      {checkPriv('ADMIN') && (
        <MenuItem
          key="/attendances"
          route="/attendances"
          icon={<FolderOpenOutlined />}
          label="Atendimentos"
        />
      )}

      {(checkPriv('CHAT')) && (
        <MenuItem
          key="/chat"
          route="/chat"
          icon={<WechatOutlined />}
          label="Chat"
        />
      )}
    </Menu>
  );
};

export default MenuContent;
