import React, { useState } from 'react';
import { Layout, Grid } from 'antd';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import utils from '../../utils';
import HeaderNav from './components/HeaderNav';
import SideBar from './components/SideBar';
import Footer from './components/Footer';
import { RootState } from '../../store';
import { BotForm } from '../../components/forms/bot-form';
import { ModalConnectBot } from '../../components/modal-connect-bot';
import { DepartmentForm } from '../../components/forms/department-form';
import { CampaignForm } from '../../components/forms/campaign-form';
import { UserForm } from '../../components/forms/user-form'
import { CampaignDetailsModal } from '../../components/campaign-details-modal';
import { LeadDetailsDrawer } from '../../components/lead-details-drawer';
import { DepartmentDetailsDrawer } from '../../components/department-details-drawer';
import { UserDetailsDrawer } from '../../components/user-details-drawer';
import { UpdateProfileForm } from '../../components/forms/update-profile-form';
import { BotDetailsDrawer } from '../../components/bot-details-drawer';
import { TutorialVideoModal } from '../../components/tutorial-video-modal';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const Main = () => {
  const { modal: botModal } = useSelector((state: RootState) => state.bot);
  const { modal: departmentModal } = useSelector((state: RootState) => state.department);
  const { modal: chatModal } = useSelector((state: RootState) => state.chat);
  const { modal: campaignModal } = useSelector((state: RootState) => state.campaign);
  const { modal: organizationModal } = useSelector((state: RootState) => state.organization);
  const { modal: userModal } = useSelector((state: RootState) => state.user);

  const [isVisible, setIsVisible] = useState(true);
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');

  const handleToggle = () => {
    setIsVisible((i) => !i);
  };

  const handleClose = () => setIsVisible(false);

  const getLayoutGutter = () => {
    if (isMobile) {
      return 0;
    }
    return isVisible ? 70 : 200;
  };

  const getLayoutDirectionGutter = () => ({ paddingLeft: getLayoutGutter() });

  return (
    <Layout>
      {botModal?.form && <BotForm />}
      {botModal?.connect && <ModalConnectBot />}
      {departmentModal?.form && <DepartmentForm />}
      {campaignModal?.form && <CampaignForm />}
      {userModal?.updateProfile && <UpdateProfileForm />}
      {departmentModal?.details && <DepartmentDetailsDrawer />}
      {organizationModal.userForm && <UserForm />}
      {campaignModal?.details && <CampaignDetailsModal />}
      {chatModal?.leadDetails && <LeadDetailsDrawer />}
      {userModal?.details && <UserDetailsDrawer />}
      {botModal?.details && <BotDetailsDrawer />}
      <TutorialVideoModal />
      <HeaderNav toggleSideBar={handleToggle} sidebarCollapsed={isVisible} />
      <SideBar visible={isVisible} handleClose={handleClose} isMobile={isMobile} />
      <Layout className="app-container" style={getLayoutDirectionGutter()}>
        <Layout className="app-layout">
          <div className="app-content dir-ltr">
            <Content>
              <Outlet />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Main;
