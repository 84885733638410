
import React, { useState } from "react";
import { Form, Drawer, Alert, Space, Button, Steps, message, Col, Typography, Input, Row, Select, Tabs } from "antd";
import { useSelector } from "react-redux";
import { If } from 'react-if';
import { motion } from "framer-motion";
import { PhoneOutlined, UserOutlined, HomeOutlined, NumberOutlined, EnvironmentOutlined, PushpinOutlined, HeatMapOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'usehooks-ts';

import { UserService } from "../../../services/user.service"
import { RootState } from "../../../store";
import Flex from "../../flex";
import { UserTypes } from "../../../types";
import { Rule } from "antd/lib/form";
import { api } from "../../../http/http";
import { states } from "../../../constants/states.constants";
import { cpf } from 'cpf-cnpj-validator'
import { cepMask, cpfMask, phoneMask } from "../../../helpers/masks";

export const UpdateProfileForm = () => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const [form] = Form.useForm<UserTypes.UpdateProfile>();
    const [formPassword] = Form.useForm<UserTypes.UpdatePassword>();

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { modal } = useSelector((state: RootState) => state.user);
    const { authUser, loading } = useSelector((state: RootState) => state.user);
    const [currentTab, setCurrentTab] = useState(0)

    const onClose = () => {
        UserService.dispatch.eraseFormModal();
    }

    const onSubmit = async (values: any, form = 'profile') => {
        if (form === 'profile') {
            if (!cpf.isValid(values.taxpayer)) {
                message.error('O CPF informado é Inválido')
                return
            }

            values.taxpayer = values.taxpayer.replace(/[^\d]+/g, '');
            values.zip = values.zip.replace(/[^\d]+/g, '');

            await UserService.updateProfile(values, (isValid, msg) => {
                if (isValid) {
                    message.success('Perfil Alterado com sucesso!')
                } else {
                    message.error(msg)
                }
            })
        } else {
            await UserService.updatePassword(values.oldPassword, values.newPassword, authUser, (isValid, msg) => {
                if (isValid) {
                    message.success('Senha alterada com sucesso!')
                } else {
                    message.error(msg)
                }
            })
        }
    };

    const changeTab = (key: string) => {
        if (key === 'edit-profile') {
            setCurrentTab(0)
        } else if (key === 'edit-password') {
            setCurrentTab(1)
        }
    }

    const rules: Record<string, Rule[]> = {
        name: [
            {
                required: true,
                min: 3,
                message: 'Informe o nome'
            },
        ],
        taxpayer: [
            {
                required: true,
                min: 3,
                message: 'Informe o cpf'
            },
        ],
    }

    const passwordRules: Record<string, Rule[]> = {
        oldPassword: [
            {
                required: true,
                message: 'Informe a senha atual'
            },
        ],
        newPassword: [
            {
                required: true,
                min: 8,
                max: 30,
                message: 'A senha deve conter pelo menos 8 digitos'
            },
        ],
        confirmPassword: [
            {
                required: true,
                min: 8,
                max: 30,
                message: 'A senha deve conter pelo menos 8 digitos'
            },
            ({ getFieldValue }) => ({
                validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject('As senhas informadas não são iguais');
                },
            })
        ],
    }

    const getAddressByZipCode = async (zip: string) => {
        zip = zip.replace(/[^\d]+/g, '');

        const response = await api.get(`/Helpers/GetAddress/${zip}`);

        if (response.data.isValid) {
            const { result } = response.data
            form.setFieldValue('city', result.localidade)
            form.setFieldsValue({
                city: result.localidade,
                address: result.logradouro,
                neighborhood: result.bairro,
                state: result.uf
            })
        }
    }

    return (
        <Drawer
            title="Editar Informações"
            width={isSmallScreen ? '90vw' : 600}
            onClose={onClose}
            open={modal.updateProfile}
            placement="right"
            footer={
                <Flex flexDirection="row-reverse">
                    <Space>
                        <Button disabled={false} onClick={onClose}>Cancelar</Button>
                        <Button loading={loading.updateProfile || loading.updatePassword} type='primary' onClick={() => currentTab === 0 ? form.submit() : formPassword.submit()}>
                            {
                                currentTab === 0 ? 'Alterar Perfil' : 'Alterar Senha'
                            }
                        </Button>
                    </Space>
                </Flex>
            }
        >
            <If condition={false}>
                <motion.div
                    initial={{ opacity: 0, marginBottom: 0 }}
                    animate={{
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0
                    }}
                >
                    <Alert
                        onClose={() => setErrorMessage(null)}
                        type="error"
                        showIcon
                        message={errorMessage}
                        closable
                    />
                </motion.div>
            </If>
            <Tabs
                defaultActiveKey="1"
                type="card"
                onChange={(a) => changeTab(a)}
            >
                <Tabs.TabPane tab="Editar Perfil" key="edit-profile">
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={async (values) => {
                            onSubmit(values, 'profile')
                        }}
                    >
                        <Form.Item
                            name="name"
                            label="Nome"
                            rules={rules.name}
                            hasFeedback
                            style={{ marginBottom: 10 }}
                            initialValue={authUser.name}
                        >
                            <Input
                                prefix={<UserOutlined className="text-primary" />}
                                placeholder="Informe o seu nome"
                            />
                        </Form.Item>
                        <Form.Item
                            name="taxpayer"
                            label="CPF"
                            required
                            rules={rules.taxpayer}
                            hasFeedback
                            style={{ marginBottom: 10 }}
                            initialValue={authUser.taxpayer ? cpfMask(authUser.taxpayer) : ''}
                        >
                            <Input
                                prefix={<UserOutlined className="text-primary" />}
                                placeholder="Informe o seu CPF"
                                onChange={(e) => form.setFieldValue('taxpayer', cpfMask(e.target.value))}
                            />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            label="Celular"
                            hasFeedback
                            style={{ marginBottom: 10 }}
                            initialValue={authUser?.phone ? phoneMask(authUser.phone) : ''}
                        >
                            <Input
                                prefix={<PhoneOutlined className="text-primary" />}
                                placeholder="Altere o seu número de telefone"
                                onChange={(e) => form.setFieldValue('phone', phoneMask(e.target.value))}

                            />
                        </Form.Item>
                        <Form.Item
                            name="address"
                            label="Endereço"
                            hasFeedback
                            style={{ marginBottom: 10 }}
                            initialValue={authUser?.address}
                        >
                            <Input
                                prefix={<HomeOutlined className="text-primary" />}
                                placeholder="Altere o seu número de telefone"
                            />
                        </Form.Item>
                        <Row>
                            <Col span={14}>
                                <Form.Item
                                    name="zip"
                                    label="CEP"
                                    hasFeedback
                                    style={{ marginBottom: 10 }}
                                    initialValue={authUser?.zip ? cepMask(authUser?.zip) : ''}
                                >
                                    <Input
                                        prefix={<EnvironmentOutlined className="text-primary" />}
                                        placeholder="CEP"
                                        onChange={async (e) => {
                                            form.setFieldValue('zip', cepMask(e.target.value))

                                            if (e.target.value.length >= 8 && e.target.value.length <= 10) {
                                                await getAddressByZipCode(e.target.value)
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={9} style={{ marginLeft: 10 }}>
                                <Form.Item
                                    name="address_number"
                                    label="Número da residência"
                                    hasFeedback
                                    style={{ marginBottom: 10 }}
                                    initialValue={authUser?.address_number}
                                >
                                    <Input
                                        prefix={<NumberOutlined className="text-primary" />}
                                        placeholder="Número da residência"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name="neighborhood"
                            label="Bairro"
                            hasFeedback
                            style={{ marginBottom: 10 }}
                            initialValue={authUser?.neighborhood}
                        >
                            <Input
                                prefix={<HeatMapOutlined className="text-primary" />}
                                placeholder="Bairro"
                            />
                        </Form.Item>
                        <Form.Item
                            name="city"
                            label="Cidade"
                            hasFeedback
                            style={{ marginBottom: 10 }}
                            initialValue={authUser?.city}
                        >
                            <Input
                                prefix={<PushpinOutlined className="text-primary" />}
                                placeholder="Cidade"
                            />
                        </Form.Item>
                        <Form.Item
                            name="address_complement"
                            label="Complemento"
                            hasFeedback
                            style={{ marginBottom: 10 }}
                            initialValue={authUser?.address_complement}
                        >
                            <Input
                                prefix={<PushpinOutlined className="text-primary" />}
                                placeholder="Complemento"
                            />
                        </Form.Item>
                        <Form.Item
                            name="state"
                            label="Estado"
                            initialValue={authUser?.state}
                        >
                            <Select
                                placeholder="Selecioe o Estado"
                                defaultValue={authUser?.state}
                                options={states?.map((state) => ({
                                    label: state.nome,
                                    value: state.sigla,
                                }))}
                            />
                        </Form.Item>
                    </Form>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Alterar Senha" key="edit-password">
                    <Form
                        layout="vertical"
                        form={formPassword}
                        onFinish={async (values) => {
                            onSubmit(values, 'password')
                        }}
                    >
                        <Form.Item
                            name="oldPassword"
                            label="Senha atual"
                            rules={passwordRules.oldPassword}
                            required
                        >
                            <Input.Password
                                placeholder="Senha"
                            />
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            label="Nova Senha"
                            rules={passwordRules.newPassword}
                            required
                        >
                            <Input.Password
                                placeholder="Senha"
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            label="Confirmar Senha"
                            rules={passwordRules.confirmPassword}
                            required
                        >
                            <Input.Password
                                placeholder="Senha"
                            />
                        </Form.Item>
                    </Form>
                </Tabs.TabPane>
            </Tabs>



        </Drawer>
    )
}