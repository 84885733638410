import { Avatar, Empty, Image, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import Scrollbars from "react-custom-scrollbars-2";
import { When } from "react-if";

import { AttendanceTypes } from "../../types/attendance.types";
import './styles.css';

interface Props {
    selectedAttendance: AttendanceTypes.Model,
    scrollbars?: React.MutableRefObject<Scrollbars | undefined>
}

export const ChatContent: React.FC<Props> = ({ selectedAttendance, scrollbars }) => {

    const renderMessage = (message: AttendanceTypes.Message) => {
        const content = message?.content?.text || (message?.content)?.message?.conversation || message?.content?.message?.extendedTextMessage?.text;

        const needsToRemoveAllBreaklines = content?.includes(`*${selectedAttendance?.user?.name}:*`);

        return `${needsToRemoveAllBreaklines ? 
            content?.replaceAll(`*${selectedAttendance?.user?.name}:*`, '')?.replaceAll("*", "")?.replaceAll('\n', "") : 
            content?.replaceAll(`*${selectedAttendance?.user?.name}:*`, '')?.replaceAll("*", "")}`;
    }

    return (
        <Content className="chat-content" style={{ background: '#EBEBEB', height: '80vh' }}>
            <div className="chat-content-body">
               
                <Scrollbars ref={scrollbars as any} autoHide>
                    <When condition={!selectedAttendance}>
                        <Empty 
                            style={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyItems: 'center',
                                height: '65vh'
                            }}
                            image={Empty.PRESENTED_IMAGE_DEFAULT} 
                            description={'Aparentemente você não selecionou nenhum atendimento. Selecione um atendimento para começar a conversar.'} 
                        />
                    </When>
                    {selectedAttendance?.messages?.map((message) => 
                        <>
                            <div 
                                key={`msg-${message?.identificator}`} 
                                className={`msg ${message.fromMe ? 'opposite' : 'msg-recipient'} ${message.fromMe && 'msg-sent'}`}
                            >   
                                <When condition={!message?.fromMe}>
                                    <Avatar 
                                        src={message?.fromMe ? '' : selectedAttendance?.photo} 
                                        style={{
                                            backgroundColor: '#21B573',
                                            verticalAlign: 'middle',
                                        }}
                                        size={'large'}
                                    >
                                        {selectedAttendance?.pushName ? selectedAttendance?.pushName[0] : ''}
                                    </Avatar>
                                </When>
                                <div className={`bubble ml-2`}>
                                    <div className="bubble-wrapper">
                                        <Row justify={'space-between'} style={{ marginBottom: 10 }}>
                                            <Typography.Text
                                                style={{ 
                                                    color: message?.fromMe ? '#F5F5F5' : '#000',
                                                    fontSize: 12,
                                                    marginRight: 20,
                                                    fontWeight: 600
                                                }}
                                            >
                                                {message?.fromMe ? message?.user?.name || `Mensagem automática` : selectedAttendance?.pushName}
                                            </Typography.Text>
                                            <Typography.Text 
                                                style={{ 
                                                    color: message?.fromMe ? '#F5F5F5' : '#000',
                                                    fontSize: 12,
                                                }}
                                            >
                                                {moment(message?.createdAt).format('DD/MM/YY HH:mm')}
                                            </Typography.Text>
                                        </Row>
                                        <When condition={message?.content?.text || (message?.content)?.message?.conversation || message?.content?.message?.extendedTextMessage?.text}>
                                            {renderMessage(message)}
                                        </When>
                                        <When condition={message?.content?.message?.imageMessage?.url || message?.content?.message?.stickerMessage?.url}>
                                            <Image style={{ width: 250, height: 250 }} src={message?.content?.message?.imageMessage?.url || message?.content?.message?.stickerMessage?.url} />
                                        </When>
                                        <When condition={message?.content?.message?.videoMessage?.url}>
                                            <video width="320" height="300" controls>
                                                <source src={message?.content?.message?.videoMessage?.url?.includes('https') ? message?.content?.message?.videoMessage?.url : `https://${message?.content?.message?.videoMessage?.url}`} type="video/mp4" />
                                            </video>
                                        </When>
                                        <When condition={message?.content?.message?.audioMessage?.url}>
                                            <audio controls>
                                                <source src={message?.content?.message?.audioMessage?.url} type="audio/ogg" />
                                            </audio>
                                        </When>
                                        <When condition={message?.content?.message?.documentMessage?.url}>
                                            <embed src={message?.content?.message?.documentMessage?.url} width={500} height={560} />
                                        </When>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Scrollbars>
            </div>
        </Content>
    )
}