import React from 'react'
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

interface Props {
    trigger?: string,
    placement?: "bottomRight" | "topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter" | "top" | "bottom" | undefined,
    menu: JSX.Element
}

export const EllipsisDropdown: React.FC<Props> = ({
    trigger = 'click',
    placement = 'bottomRight',
    menu = <Menu />
}) => {
	return (
		<Dropdown overlay={menu} placement={placement} trigger={['click']}>
			<div className="ellipsis-dropdown">
				<EllipsisOutlined />
			</div>
		</Dropdown>
	)
}
