import { Button, Form, Typography } from "antd";
import React, { useEffect } from "react";
import { TeamOutlined } from '@ant-design/icons';
import { Rule } from "antd/lib/form";

import { leadService } from "../../../../services/lead.service";
import { LeadTypes } from "../../../../types";
import { LeadList } from "../../../lists/lead-list";
import Flex from "../../../flex";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface Props {
    selectedLeads: Array<LeadTypes.Model>,
    onLeadsSelect: (lead: LeadTypes.Model[]) => void,
}

export const Step2: React.FC<Props> = ({ selectedLeads, onLeadsSelect }) => {
    const rules: Record<string, Rule[]> = {
        leads: [
            {
                required: true,
                message: 'Selecione ao menos um lead para a campanha'
            },
        ],
    }

    const { campaign, duplicated } = useSelector((state: RootState) => state.campaign);

    useEffect(() => {
        let leads: LeadTypes.Model[] = []
        if (duplicated) {
            campaign.campaignsxleads.map(el => {
                leads.push(el.lead)
            })

            onLeadsSelect(leads)
        }
    }, [])

    return (
        <>

            <Flex flexDirection="row" justifyContent="between">
                <>
                    <Typography.Text className="text-muted">
                        Para realizar o disparo em massa é necessário selecionar ao menos um lead.
                    </Typography.Text>
                    <Button onClick={() => leadService.dispatch.toggleModal('form', true)} type="primary" style={{ marginBottom: 16 }}>
                        Adicionar Lead
                        <TeamOutlined />
                    </Button>
                </>
            </Flex>

            <LeadList
                onRowSelect={onLeadsSelect}
                defaultSelectedRowKeys={!duplicated ? selectedLeads?.map((lead) => lead.id) : campaign.campaignsxleads.map((cxl) => cxl.lead.id)}
            />

            <Form.Item
                hasFeedback
                rules={rules.leads}
                name="leads"
            />

        </>
    )
}