import { useSelector } from "react-redux"
import React, { useEffect, useRef, useState } from "react";
import moment from 'moment';
import { InputRef } from "antd";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Layout } from 'antd';
import { message } from 'antd';
import socketIOClient, { Socket } from "socket.io-client";
import _ from "lodash";

import { RootState } from "../../store";
import { ChatService } from "../../services/chat.service";
import { AttendanceTypes } from '../../types/attendance.types';
import useInterval from "../../hooks/useInterval";
import useAudio from "../../hooks/useAudio";
import { ChatSidebar } from "./chat-sidebar";
import { ChatNavbar } from "./chat-navbar";
import { ChatFooter } from "./chat-footer";
import { ChatContent } from "./chat-content";
import { NewAttendanceForm } from "../forms/new-attendance-form";
import { ENVS } from "../../http/http";
import { TransferAttendanceForm } from "../forms/transfer-attendance-form";

const { Sider } = Layout;

moment.locale('pt-br'); 

export const Chat = () => {
    const [socket, setSocket] = useState<Socket>();
    const scrollbars = useRef<Scrollbars>();
    const inputRef = useRef<InputRef>();
    const { toggle: toggleNotificationSound } = useAudio('https://zapfy-bucket.sfo3.cdn.digitaloceanspaces.com/swiftly-610.mp3');

    const { authUser } = useSelector((state: RootState) => state.user);
    const { unreadCount, userChatAttendances, settings, selectedAttendanceId } = useSelector((state: RootState) => state.chat);

    const attendances = [...userChatAttendances?.STARTED, ...userChatAttendances?.AWAITING, ...userChatAttendances?.POTENTIAL];

    const selectedAttendance = attendances?.length > 0 ?
        attendances[attendances?.findIndex(a => a?.id === selectedAttendanceId)] : 
        {} as AttendanceTypes.Model;
    
    const handleIncomingMessagesFromSocket = (m: AttendanceTypes.Message & { attendanceId: number }) => {
        if (!m?.fromMe) {
            if (settings?.notification?.sound) {
                toggleNotificationSound();
            }

            if (m?.attendanceId !== selectedAttendanceId && settings?.notification?.popup) {
                message.info(`Você recebeu uma nova mensagem de ${m?.attendance?.pushName}.`);
            }

            if (m?.attendanceId !== selectedAttendanceId) {
                ChatService.dispatch.addUnread(m.attendanceId);
            }
        }

        ChatService.dispatch.addAttendanceMessage(m?.attendanceId, m);
        
        if (scrollbars.current) {
            scrollbars?.current!.scrollToBottom();
        }
    }

    useEffect(() => {
        if (!unreadCount) {
            ChatService.dispatch.setUnread({});
        }

        if (!socket) {
            setSocket(
                socketIOClient(
                    ENVS.prd.SOCKET_BASE_URL, 
                    { autoConnect: true, transports: ['websocket', 'polling'], forceNew: true, reconnectionAttempts: 3 }
                )
            );
        }

        window.addEventListener('keypress', () => {
            inputRef.current!.focus();
        });

        return () => {
            socket?.disconnect();
        }
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on(`user-${authUser?.id}-attendance-new-message`, (m: AttendanceTypes.Message & { attendanceId: number }) => {
                console.log('userr new attendance message', m);
                handleIncomingMessagesFromSocket(m);
            });
        
            socket.on(`organization-${authUser?.organizationId}-attendance-new-message`, (m: AttendanceTypes.Message & { attendanceId: number }) => {
                handleIncomingMessagesFromSocket(m);
            });

            socket.on(`user-${authUser?.id}-new-attendance`, async (a: AttendanceTypes.Model) => {
                const attendanceAlreadyExists = [
                    ...userChatAttendances?.AWAITING, 
                    ...userChatAttendances?.STARTED, 
                    ...userChatAttendances?.POTENTIAL,
                ]?.find((at) => at.id === a.id)!;

                if (!attendanceAlreadyExists) {
                    ChatService.dispatch.addAttendance({ ...a, status: a?.status === AttendanceTypes.Status.STARTED ? AttendanceTypes.Status.STARTED : AttendanceTypes.Status.POTENTIAL });
                    return
                }

                ChatService.dispatch.changeAttendanceStatus({ currentStatus: attendanceAlreadyExists.status, newStatus: a.status, attendanceId: a.id });

                if (a?.status === AttendanceTypes.Status.AWAITING) {
                    if (settings?.notification?.sound) {
                        toggleNotificationSound();
                    }
        
                    if (settings?.notification?.popup) {
                        message.success(`Você recebeu um novo atendimento (${a?.pushName})`);
                    }
                }            
            });

            socket.on(`organization-${authUser?.organizationId}-new-attendance`, (a: AttendanceTypes.Model) => {
                ChatService.dispatch.addAttendance({ ..._.omit(a, 'status'), status: AttendanceTypes.Status.POTENTIAL });
            });

            socket.on('connect', () => {
                message.success('Conectado ao chat com sucesso!');
            });
        }
    }, [socket]);

    useInterval(() => {
        if (socket && socket?.disconnected) {
            message.error('Perdemos a conexão... Estamos reconectando ao chat...');
            socket?.open();
        }
    }, 15000);

    useEffect(() => {
        if (scrollbars.current) {
            scrollbars?.current!.scrollToBottom();
        }
        ChatService.dispatch.removeUnread(selectedAttendanceId);
    }, [selectedAttendanceId]);

    return (
        <Layout className="chat" style={{ flex: 1, display: 'flex', height: '100vh', overflow: 'hidden' }}>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                width={400}
                onBreakpoint={(broken) => {
                }}
                onCollapse={(collapsed, type) => {
                
                }}
                collapsible 
                theme="light"
            >
                <ChatSidebar />
            </Sider>
            <Layout>
                <ChatNavbar selectedAttendance={selectedAttendance} />
               
                <ChatContent scrollbars={scrollbars} selectedAttendance={selectedAttendance} />

                <ChatFooter selectedAttendance={selectedAttendance} inputRef={inputRef} scrollbars={scrollbars} />
                
            </Layout>

            <NewAttendanceForm />
            <TransferAttendanceForm />
        </Layout>
    )
}