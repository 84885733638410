
import React, { useCallback, useEffect, useState } from "react";
import { Modal, Form, Input, message, Alert, AutoComplete, Typography, Upload, Button, Table, Checkbox } from "antd";
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { If } from 'react-if';
import { motion } from "framer-motion";
import { Rule } from "antd/lib/form";
import Papa from 'papaparse';

import { leadService } from "../../../services/lead.service";
import { RootState } from "../../../store";
import { LeadTypes } from "../../../types";
import { Space, Tag } from 'antd';
import { tagService } from "../../../services/tag.service";

export const ImportLeadsForm = () => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { modal, lead, loading } = useSelector((state: RootState) => state.lead);

    const [fileList, setFileList] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [loadingLeads, setLoadingLeads] = useState(false);

    const onClose = () => {
        leadService.dispatch.toggleModal('importLeads', false);
    }

    const { pagination: tagPagination, loading: loadTags } = useSelector((state: RootState) => state.tag);
    const [addTags, setAddTags] = useState<boolean>(false);
    const [options, setOptions] = useState<{ value: string, key: number }[]>([]);
    const [selecteds, setSelecteds] = useState<{ value: string, key: number }[]>([]);
    const [onSelectTag, setOnSelectTag] = useState<boolean>(false);

    const onSelect = useCallback((data: string, key: any) => {
        if(selecteds.length >= 10) {
            setErrorMessage('Você atingiu o limite máximo de tags')
            return;
        }
        
        setOnSelectTag(true);
        console.log(selecteds.map(e => e.key).indexOf(key.key));
        if(selecteds.map(e => e.key).indexOf(key.key) === -1) {
            setSelecteds([...selecteds, { value: data, key: key.key }]);
        }
        setOptions([]);
        const interval = setTimeout(() => {
            setOnSelectTag(false);
          }, 500);
          return () => clearTimeout(interval);
    }, [selecteds, setSelecteds, setOnSelectTag, setOptions]);


    const removeTag = useCallback((e: number) => {
        const toRemove = selecteds.findIndex(objeto => objeto.key === e); 
        if (toRemove !== -1) {
             selecteds.splice(toRemove, 1);
            setSelecteds(selecteds);
          }
    }, [selecteds, setSelecteds]);

    const onSearch = async (searchText: string) => {
        console.log('OnSearhc', searchText);
        await tagService.getMany({
            page: 1,
            perPage: 100, filter: { name : searchText ?? '' } });
    };

    useEffect(() => {
        let data: {key: number, value: string }[] = [];
 
        tagPagination.items.forEach(e => {
            data.push({
                key: e.id,
                value: e.name,
            })
        });
        setOptions(data);
    }, [tagPagination])

    const fileVCardToJson = (file: any) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const text = e.target.result;
            const lines = text.split('\n');
            let json: any = [];
            let obj: any = {};
            for (let i = 0; i < lines.length; i++) {
                const line = lines[i];
                if (line.startsWith('BEGIN:VCARD')) {
                    obj = {};
                } else if (line.startsWith('END:VCARD')) {
                    json.push(obj);
                } else if (line.startsWith('FN:')) {
                    obj.name = line.split(':')[1];
                } else if (line.startsWith('TEL;')) {
                    obj.contact = line.split(':')[1].replace(/[^0-9]/g, '');
                }
            }

            // remove contact duplicates and invalid phone numbers
            json = json.filter((d: any, index: number, self: any) => {
                return index === self.findIndex((t: any) => (
                    t.contact === d.contact && String(t.contact).length === String(d.contact).length
                ));
            });

            setCsvData(json);
        }
        reader.readAsText(file);
    }

    const onSubmit = async () => {
        setErrorMessage(null);
        setLoadingLeads(true);

        await leadService.import(
            csvData.map((e: any) => ({ name: String(e.name).trim(), contact: String(e.contact).trim(), args: {}, channel: 'whatsapp' })),
            (isValid, msg) => {
            if (!isValid) {
                setErrorMessage(msg);
                return;
            }

            message.success(msg);
            onClose();
        }, selecteds.map(e => e.key));

        setLoadingLeads(false);
    }

    useEffect(() => {
        return () => {
            setLoadingLeads(false);
            setErrorMessage(null);
            setFileList([]);
            setCsvData([]);
        }
    }, [modal.importLeads]);

    if (!modal.importLeads) return null;
  
    const handleFileChange = (info: any) => {
        const newFileList: any = [...info.fileList];
        setFileList(newFileList);

        if (newFileList.length > 0) {
            const file = newFileList[0].originFileObj;
            parseCSV(file);
        }
    };
  
    const parseCSV = (file: any) => {
        console.log(file.type )
        if (String(file.type).includes('vcard')) {
            fileVCardToJson(file);
        } else {
            Papa.parse(file, {
                header: true,
                complete: (result) => {
                    let data: any = result.data;
                    data = data.map((d: any) => ({ name: d.name, contact: String(d.contact).replace(/[^0-9]/g, '') }));

                    // remove contact duplicates and invalid phone numbers
                    data = data.filter((d: any, index: number, self: any) => {
                        return index === self.findIndex((t: any) => (
                            t.contact === d.contact && t.contact.length === d.contact.length
                        ));
                    });

                    if (data.length > 0) {
                    setCsvData(data);
                    }
                },
                error: (error) => {
                    message.error('Erro ao importar arquivo');
                    // clean fileList
                    setFileList([]);
                    setCsvData([]);

                    console.error('CSV parsing error:', error);
                },
            });
        }
    };
  
    const columns = [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Telefone',
        dataIndex: 'contact',
        key: 'contact',
      },
    ];

    return (
        <Modal
            title={'Import Leads'}
            onCancel={onClose}
            open={modal.importLeads}
            okText={'Importar'}
            cancelText={'Cancelar'}
            onOk={() => {
                onSubmit();
            }}
            confirmLoading={loadingLeads}
        >
            <If condition={errorMessage !== null && !loading.form}>
                <motion.div 
                    initial={{ opacity: 0, marginBottom: 0 }} 
                    animate={{ 
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0 
                    }}
                > 
                    <Alert 
                        onClose={() => setErrorMessage(null)} 
                        type="error" 
                        showIcon
                        message={errorMessage}
                        closable
                    />
                </motion.div>
            </If>
            <div>
                <Button
                    onClick={() => {
                        // download template
                        const csv = Papa.unparse([{
                            name: 'Nome',
                            contact: '551199887766'
                        }]);

                        const element = document.createElement("a");
                        const file = new Blob([csv], {type: 'text/csv'});
                        element.href = URL.createObjectURL(file);
                        element.download = "template.csv";
                        document.body.appendChild(element);
                        element.click();
                    }}
                    size='small'
                    type='primary'
                >
                    Baixar Template de CSV/TXT
                </Button>
                <br/><br/>
                <Upload.Dragger
                    maxCount={1}
                    customRequest={() => false}
                    onChange={handleFileChange}
                    fileList={fileList}
                    accept=".csv,.txt,.vcf"
                >
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer upload</p>
                </Upload.Dragger>
                <br/>
                <p>ou</p>
                <Upload
                    customRequest={() => false}
                    onChange={handleFileChange}
                    fileList={fileList}
                    accept=".csv,.txt,.vcf"
                >
                    <Button>
                    <UploadOutlined /> Selecione o Arquivo
                    </Button>
                </Upload>
                <hr/>
                <Table columns={columns} dataSource={csvData} pagination={{ pageSize: 5 }}/>
                <p>Total de leads: {csvData.length}</p>
                <Checkbox checked={addTags} onChange={() => setAddTags(!addTags)} >Adicionar Tags?</Checkbox>
                {addTags && (
                    <div>
                        {/* <Typography.Text> Selecione as Tags </Typography.Text> */}
                        <AutoComplete
                            options={options}
                            style={{ width: '100%', marginBottom: 20, marginTop: 10,}}
                            onSelect={(value, key) => onSelect(value, key)}
                            onSearch={onSearch}
                            value={onSelectTag ? '' : undefined}
                            placeholder="Procurar tags"
                        />
                        {
                            selecteds && 
                            <Space size={[0, 10]} wrap>
                            {
                                selecteds?.map(e => (
                                    <Tag closable onClose={() => removeTag(e.key)}>
                                        {e.value}
                                    </Tag> 
                                ))
                            }
                            </Space>
                        }
                    
                    </div>
                )}
            </div>
        </Modal>
    )
}

