
import React, { useEffect, useState } from "react";
import { Form, Drawer, Alert, Space, Button, Steps, message } from "antd";
import { useSelector } from "react-redux";
import { If, When } from 'react-if';
import { motion } from "framer-motion";
import { FormOutlined, SendOutlined, EyeOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'usehooks-ts';
import { campaignService } from "../../../services/campaigns.service";
import { RootState } from "../../../store";
import { Step1 } from "./step1";
import { Step2 } from "./step2";
import { Step3 } from "./step3";
import Flex from "../../flex";
import { CampaignTypes } from "../../../types";
import { getBase64 } from "../../../helpers/base64";

export const CampaignForm = () => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    const [form] = Form.useForm<{ name: string, message: string, buttons: string[] }>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [step, setStep] = useState(1);
    const [values, setValues] = useState<CampaignTypes.Form>({} as CampaignTypes.Form);
    const [skip, setSkip] = useState(false);
    const [shadowStep, setShadowStep] = useState(step);
    const { modal, campaign, duplicated, loading } = useSelector((state: RootState) => state.campaign);
    const isOnLastStep = step === 3;

    const isDuplicated = modal.form && campaign?.id && duplicated;

    const onClose = () => {
        campaignService.dispatch.eraseFormModal();
    }

    const onChangeStep = (s: number) => {
        setValues((v: any) => ({ ...v, ...form.getFieldsValue() }));
        setStep(s);
    }

    const onSubmit = async () => {
        let file = ''

        if (values.fileList?.map(a => a)[0]) {
            file = await getBase64(values.fileList?.map(a => a)[0].originFileObj)
        }

        await campaignService.create({
            bots: values?.bots,
            leads: values?.leads?.map((l) => l.id),
            buttons: values?.buttons,
            message: values?.message,
            name: values?.name,
            fileUrl: file,
            scheduled: values?.scheduled,
            resend: values?.resend,
        }, (isValid, msg) => {
            if (!isValid) {
                setErrorMessage(msg);
                return;
            }

            message.success(msg);
            onClose();
        });
    };

    useEffect(() => {
        if (isDuplicated) {
            form.setFieldsValue({
                name: campaign.name,
                message: campaign.message,
                buttons: campaign.args.buttons,
            });
        }

        return () => {
            setErrorMessage(null);
        }

    }, [modal.form]);

    return (
        <Drawer
            title={!duplicated ? "Nova campanha" : "Duplicar Campanha"}
            width={isSmallScreen ? '90vw' : 900}
            onClose={onClose}
            open={modal.form}
            closable={false}
            placement="right"
            footer={
                <Flex flexDirection="row-reverse">
                    <Space>
                        <Button disabled={loading.form} onClick={() => step === 1 ? onClose() : onChangeStep(step - 1)}>{step === 1 ? 'Cancelar' : 'Voltar'}</Button>
                        <Button onClick={() => {
                            setSkip(true);
                            form.submit();
                        }} type='primary' loading={loading.form}>
                            {
                                isOnLastStep ? 'Salvar alterações' : 'Próxima etapa'
                            }
                        </Button>
                    </Space>
                </Flex>
            }
        >
            <If condition={errorMessage !== null && !loading.form}>
                <motion.div
                    initial={{ opacity: 0, marginBottom: 0 }}
                    animate={{
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0
                    }}
                >
                    <Alert
                        onClose={() => setErrorMessage(null)}
                        type="error"
                        showIcon
                        message={errorMessage}
                        closable
                    />
                </motion.div>
            </If>

            <Steps
                items={[
                    {
                        title: 'Mensagem',
                        status: 'finish',
                        icon: <FormOutlined />,
                    },
                    {
                        title: 'Selecionar leads',
                        status: step >= 2 ? 'finish' : 'wait',
                        icon: <SendOutlined />,
                    },
                    {
                        title: 'Revisar',
                        status: step === 3 ? 'finish' : 'wait',
                        icon: <EyeOutlined />,
                    },
                ]}
            />

            <div style={{ marginTop: 20 }}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={async (values) => {
                        if (skip && isOnLastStep) {
                            await onSubmit();
                            return;
                        }
                        onChangeStep(skip ? step + 1 : shadowStep);
                    }}
                >
                    <When condition={step === 1}>
                        <Step1
                            selectedButtons={values?.buttons || []}
                            onButtonsChange={(b) => setValues((v) => ({ ...v, buttons: b }))}
                            fileList={values?.fileList || []}
                            onFileListChange={(fl) => setValues((v) => ({ ...v, fileList: fl }))}
                        />
                    </When>
                    <When condition={step === 2}>
                        <Step2
                            selectedLeads={values?.leads || []}
                            onLeadsSelect={(leads) => {
                                form.setFieldValue('leads', leads);
                                setValues((v) => ({ ...v, leads }));
                            }}
                        />
                    </When>
                    <When condition={step === 3}>
                        <Step3
                            reviewCampaign={values}
                            scheduled={values?.scheduled || ''}
                            setScheduleDate={(date) => {
                                form.setFieldValue('scheduled', date);
                                setValues((v) => ({ ...v, scheduled: date }));
                            }}
                            selectedBots={values?.bots || []}
                            setSelectedBots={(bots) => {
                                form.setFieldValue('bots', bots);
                                setValues((v) => ({ ...v, bots }));
                            }}
                            resend={values?.resend || null}
                            setResend={(resend) => {
                                form.setFieldValue('resend', resend);
                                setValues((v) => ({ ...v, resend }));
                            }}
                        />
                    </When>
                </Form>
            </div>
        </Drawer>
    )
}