import React, { Suspense, useEffect } from 'react';
import { Route, Routes, redirect, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Main from '../layouts/main/index';
import { RootState } from '../store';
import AuthLayout from '../layouts/auth';
import Loading from '../components/loading';
import CampaignListScreen from '../screen/dashboard/campaigns/list/campaigns-list.screen';
import { ChatScreen } from '../screen/dashboard/chat/chat.screen';
import ChangePasswordScreen from '../screen/subscription/change-password';
import BusinessInfoScreen from '../screen/subscription/business-info';
import TagListScreen from '../screen/dashboard/tags/list/tag-list.screen';
import { useCheckPriv } from '../hooks/useCheckPriv';
import { modulePermissions } from '../constants/module.permissions';

const LoginScreen = React.lazy(() => import("../screen/auth/login.screen"));
const ForgetPasswordScreen = React.lazy(() => import("../screen/auth/forget-password.screen"));
const ResetPasswordScreen = React.lazy(() => import("../screen/auth/reset-password.screen"));
const AccountScreen = React.lazy(() => import('../screen/dashboard/account/account.screen'));

const AttendanceRatingsScreen = React.lazy(() => import("../screen/attendance-ratings/attendance-ratingsscreen"));

const DashboardScreen = React.lazy(() => import("../screen/dashboard/index"));
const BotListScreen = React.lazy(() => import("../screen/dashboard/bots/list/bots-list.screen"));
const DepartmentListScreen = React.lazy(() => import("../screen/dashboard/departments/lists/departments-list.screen"));
const LeadListScreen = React.lazy(() => import("../screen/dashboard/leads/lists/leads-list.screen"));
const OrganizationListScreen = React.lazy(() => import("../screen/dashboard/users/lists/users-list.screen"));
const ShortcutListScreen = React.lazy(() => import("../screen/dashboard/shorcuts/list/shortcuts-list.screen"));
const AttendancesListScreen = React.lazy(() => import("../screen/dashboard/attendances/list/attendances-list.screen"));

export const AppRoutes = () => {
    const { authUser, token } = useSelector((state: RootState) => state.user);
    const { checkPriv } = useCheckPriv();
    const navigate = useNavigate();

    const IS_AUTHENTICATED = !!authUser && !!token;

    useEffect(() => {
        if (IS_AUTHENTICATED && !Object.values(authUser.permissions.flag).includes('ADMIN')) {
            navigate('/my-account');
        }
    }, [authUser.permissions, IS_AUTHENTICATED]);

    if (!IS_AUTHENTICATED) {
        return (
            <Suspense fallback={<Loading cover="content" />}>
                <Routes>
                    <Route path="/" element={<AuthLayout />}>
                        <Route path="/login" element={<LoginScreen />} />
                        <Route path="/" element={<LoginScreen />} />
                        <Route path="/forget-password" element={<ForgetPasswordScreen />} />
                        <Route path="/reset-password" element={<ResetPasswordScreen />} />
                        <Route path="/attendance-rating/:id" element={<AttendanceRatingsScreen />} />
                    </Route>
                </Routes>
            </Suspense>
        )
    }

    if (IS_AUTHENTICATED && authUser.isFirstTime || authUser.organizations.status === 'pending') {
        return (
            <Suspense fallback={<Loading cover="content" />}>
                <Routes>
                    <Route path="/" element={<AuthLayout />}>
                        <Route path="/change-password" element={<ChangePasswordScreen />} />
                        <Route path="/business-info" element={<BusinessInfoScreen />} />
                    </Route>
                </Routes>
            </Suspense>
        )
    }

    return (
        <Suspense fallback={<Loading cover="content" />}>
            <Routes>
                <Route path="/" element={<Main />}>
                    {checkPriv('ADMIN') && 
                        <Route path="/" element={<DashboardScreen />} />
                    }
                    {(checkPriv(Object.values(modulePermissions.bots))) &&
                        <Route path="/bots" element={<BotListScreen />} />
                    }
                    {(checkPriv(Object.values(modulePermissions.leads))) && 
                        <Route path="/leads" element={<LeadListScreen />} />
                    }
                     {(checkPriv(Object.values(modulePermissions.leads))) && 
                        <Route path='/tags' element={<TagListScreen />} />
                    }

                    {(checkPriv(Object.values(modulePermissions.departments))) && 
                        <Route path="/departments" element={<DepartmentListScreen />} />
                    }

                    {checkPriv('ADMIN') &&
                        <Route path="/users" element={<OrganizationListScreen />} />
                    }

                    {checkPriv('ADMIN') && 
                        <Route path="/attendances" element={<AttendancesListScreen />} />
                    }

                    {checkPriv('ADMIN') && 
                        <Route path="/campaigns" element={<CampaignListScreen />} />
                    }
                    
                    <Route path="/shortcuts" element={<ShortcutListScreen />} />
              
                    <Route path="/my-account" element={<AccountScreen />} />
                </Route>
                <Route path="/chat" element={<ChatScreen />} />
            </Routes>
        </Suspense>
    )
}