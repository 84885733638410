import React, { useEffect } from "react";

import { Chat } from "../../../components/chat";
import { SettingsNotificationModal } from "../../../components/chat/settings-notification-modal";
import { ChatShortcutListModal } from "../../../components/chat-shortcut-list-modal";
import { ChatShortcutForm } from "../../../components/forms/chat-shortcut-form";
import { LeadDetailsDrawer } from "../../../components/lead-details-drawer";
import { ChatLeadsModal } from "../../../components/chat-leads-modal";

export const ChatScreen = () => {
  return (
    <>
      <Chat />
      <SettingsNotificationModal />
      <ChatShortcutListModal />
      <ChatShortcutForm />
      <LeadDetailsDrawer />
      <ChatLeadsModal />
    </>
  )
}