import React, { useEffect, useState } from "react";
import { Modal, Form, Input, message, Alert, Tag, Space, Typography, Tooltip, Select, TimePicker, Button, Row, Col, Divider, Drawer, Checkbox } from "antd";
import { useSelector } from "react-redux";
import { If } from 'react-if';
import { motion } from "framer-motion";
import { Rule } from "antd/lib/form";
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useMediaQuery } from 'usehooks-ts';

import { botService } from "../../../services/bot.service";
import { RootState } from "../../../store";
import { BotTypes } from "../../../types";
import Flex from "../../flex";
import TextArea from "antd/lib/input/TextArea";
import { AddBotMessageModal } from "../add-bot-message-modal";

const { Text } = Typography;
const { Option } = Select;

export const BotForm = () => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    const [botMessageModal, setBotMessageModal] = useState(false);
    const [selectedMessageIndex, setSelectedMessageIndex] = useState<number | null>(null);
    const [form] = Form.useForm<BotTypes.Form>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { modal, bot, loading } = useSelector((state: RootState) => state.bot);

    const skipDepartments = Form.useWatch('skipDepartments', form);

    const isFromEdit = modal.form && bot?.id;

    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    
    const organizeDayWeek = (daysOfWeekOrg: any) => {
        const orderedSchedule: any = {};
        daysOfWeek.forEach(day => {
            if (Object.keys(daysOfWeekOrg).includes(day)) {
                orderedSchedule[day] = daysOfWeekOrg[day];
            }
        });

        return orderedSchedule;
    }

    const [businessHours, setBusinessHours] = useState(bot.info?.daysOfWeek ? Object.keys(organizeDayWeek(bot.info?.daysOfWeek)).map((day: any) => {
        return {
            dayOfWeek: day,
            openingHour: dayjs().format('YYYY-MM-DD ' + bot.info?.daysOfWeek[day].openingHour),
            closingHour: dayjs().format('YYYY-MM-DD ' + bot.info?.daysOfWeek[day].closingHour)
        }
    }) : [
        {
            dayOfWeek: 'monday',
            openingHour: dayjs().format('YYYY-MM-DD 08:00'),
            closingHour: dayjs().format('YYYY-MM-DD 18:00')
        },
        {
            dayOfWeek: 'tuesday',
            openingHour: dayjs().format('YYYY-MM-DD 08:00'),
            closingHour: dayjs().format('YYYY-MM-DD 18:00')
        },
        {
            dayOfWeek: 'wednesday',
            openingHour: dayjs().format('YYYY-MM-DD 08:00'),
            closingHour: dayjs().format('YYYY-MM-DD 18:00')
        },
        {
            dayOfWeek: 'thursday',
            openingHour: dayjs().format('YYYY-MM-DD 08:00'),
            closingHour: dayjs().format('YYYY-MM-DD 18:00')
        },
        {
            dayOfWeek: 'friday',
            openingHour: dayjs().format('YYYY-MM-DD 08:00'),
            closingHour: dayjs().format('YYYY-MM-DD 18:00')
        },
        {
            dayOfWeek: 'saturday',
            openingHour: dayjs().format('YYYY-MM-DD 08:00'),
            closingHour: dayjs().format('YYYY-MM-DD 12:00')
        }
    ] as any);
    const [automaticMessages, setAutomaticMessages] = useState<BotTypes.AutomaticMessage[]>([]);

    const onClose = () => {
        botService.dispatch.eraseFormModal();
    }

    const rules: Record<string, Rule[]> = {
        name: [
            { 
                required: true,
                message: 'Insira o nome do robô'
            },
        ],
        welcomeMessage: [
            {
                required: true,
                message: 'Insira uma mensagem de boas-vindas'
            }
        ],
        daysOfWeek: [
            {
                required: true,
                message: 'Insira pelo menos um horário de funcionamento'
            }
        ]
    }

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            message.success('Texto copiado para a área de transferência');
        });
    };

    const addMessage = (automaticMessage: BotTypes.AutomaticMessage) => {
        // let's edit the message
        if (selectedMessageIndex !== null) {
            setAutomaticMessages((a) => a.map((item, i) => {
                if (i === selectedMessageIndex) {
                    return { ...automaticMessage, shouldRedirectToAttendance: automaticMessage?.shouldRedirectToAttendance };
                }

                return item;
            }));

            setSelectedMessageIndex(null);
            return;
        }
        setAutomaticMessages((a) => [...a, { ...automaticMessage }]);

        setSelectedMessageIndex(null);
    };

    const removeMessage = (index: number) => {
        setAutomaticMessages((a) => a.filter((_, i) => i !== index));
    }

    const addDay = (target: any) => {
        if (Object.keys(target).length === 0 && businessHours.length === 7) {
            message.error('Você já adicionou todos os dias da semana');
            return;
        }

        if (Object.keys(target).length === 0) {
            setBusinessHours([
                ...businessHours,
                {
                    dayOfWeek: businessHours.length === 0 ? daysOfWeek[0] : daysOfWeek[daysOfWeek.indexOf(businessHours.at(-1).dayOfWeek) + 1],
                    openingHour: dayjs().format('YYYY-MM-DD 08:00'),
                    closingHour: dayjs().format('YYYY-MM-DD 18:00')
                }
            ]);
        }
    };

    const handleDeleteDay = (index: string) => {
        // remove index from businessHours
        setBusinessHours(businessHours.filter((item: any, i: any) => i !== index));
    }

    const onSubmit = async (name: string, welcomeMessage: string, values: any) => {
        if (businessHours.length === 0) {
            message.error('Insira pelo menos um horário de funcionamento');
            return;
        }

        // dias de funcionamento da empresa
        const daysOfWeek: any = {}
        for (const [k, v] of Object.entries(values) as any) {
            if (k.startsWith('openingHours_')) {
                daysOfWeek[v.dayOfWeek] = { openingHour: dayjs(v.openingHour).format('HH:mm'), closingHour: dayjs(v.closingHour).format('HH:mm') };
            }
        }

        setErrorMessage(null);
        if (isFromEdit) {
            await botService.update(bot?.id!, name, skipDepartments, { welcomeMessage, daysOfWeek: organizeDayWeek(daysOfWeek) }, automaticMessages.map((am, i) => ({ ...am, shouldRedirectToAttendance: am?.shouldRedirectToAttendance || false, key: i + 1 })), (isValid, msg) => {
                if (!isValid) {
                    setErrorMessage(msg);
                    return;
                }

                message.success(msg);
                onClose();
            });
            return;
        }

        await botService.create(name, skipDepartments, { welcomeMessage, daysOfWeek: organizeDayWeek(daysOfWeek) }, automaticMessages.map((am, i) => ({ ...am, shouldRedirectToAttendance: am?.shouldRedirectToAttendance || false, key: i + 1 })), (isValid, msg) => {
            if (!isValid) {
                setErrorMessage(msg);
                return;
            }

            message.success(msg);
            onClose();
        });
    }

    useEffect(() => {
        if (isFromEdit) {
            form.setFieldsValue({
                name: bot.name!,
                welcomeMessage: bot.info?.welcomeMessage || `Olá, seja bem vindo(a) a {{nome_empresa}}`,
                daysOfWeek: bot.info?.daysOfWeek,
                skipDepartments: bot?.skipDepartments || false,
            } as any);
        }

        setAutomaticMessages(bot?.automaticMessages ? bot.automaticMessages : []);

        return () => {
            setErrorMessage(null);
        }

    }, [modal.form]);

    return (
        <>
            <Drawer
                title={isFromEdit ? 'Editar robô' : 'Criar um novo robô'}
                onClose={onClose}
                placement="right"
                open={modal.form}
                width={isSmallScreen ? '90vw' : 800}
                footer={
                    <Flex flexDirection="row-reverse">
                        <Space>
                            <Button onClick={() => onClose()}>
                                Cancelar
                            </Button>
                            <Button onClick={() => {
                                form.submit();
                            }} type='primary' loading={loading.form}>
                                Salvar alterações
                            </Button>
                        </Space>
                    </Flex>
                }
            >
                <If condition={errorMessage !== null && !loading.form}>
                    <motion.div 
                        initial={{ opacity: 0, marginBottom: 0 }} 
                        animate={{ 
                            opacity: errorMessage ? 1 : 0,
                            marginBottom: errorMessage ? 20 : 0 
                        }}
                    > 
                        <Alert 
                            onClose={() => setErrorMessage(null)} 
                            type="error" 
                            showIcon
                            message={errorMessage}
                            closable
                        />
                    </motion.div>
                </If>
                <Form
                    layout="vertical"  
                    form={form}
                    onFinish={async (values) => await onSubmit(values.name, values.welcomeMessage, values)}
                >
                    <Form.Item 
                        name="name" 
                        label="Nome" 
                        rules={rules.name}
                        hasFeedback
                    >
                        <Input placeholder="Digite um apelido para o robô, exemplo: Robô de teste" />
                    </Form.Item>
                    <Form.Item 
                        name="welcomeMessage" 
                        label="Mensagem de boas-vindas"
                        rules={rules.welcomeMessage}
                        initialValue={`Olá, seja bem vindo(a) a {{nome_empresa}}`}
                        hasFeedback
                        style={{ marginBottom: 20 }}
                    >
                        <TextArea rows={5} />
                    </Form.Item>
                    <Form.Item
                        name={'skipDepartments'}
                        style={{ marginBottom: 5, marginTop: 20 }}
                    >
                        <Checkbox defaultChecked={skipDepartments} checked={skipDepartments} onChange={(e) => form.setFieldValue('skipDepartments', e.target.checked)}>
                            Ignorar departamentos
                        </Checkbox>
                    </Form.Item>
                    <Typography.Text className="text-muted">
                        Ao selecionar essa opção o robô não exibirá o menu com os departamentos. O atendimento será redirecionado automaticamente para os atendentes do sistema.
                    </Typography.Text>
                    <Space size={[0, 8]} style={{ marginTop: 20, marginBottom: 20 }} wrap>
                        <Text style={{ marginLeft: 0, marginTop: 5, marginRight: 5 }}>Variáveis disponíveis:</Text>
                        <Tag>
                            {'{{nome_empresa}}'}
                            <Tooltip title="Copiar">
                                <span onClick={() => handleCopyClick('{{nome_empresa}}')} style={{ marginLeft: '8px', cursor: 'pointer' }}>
                                    <CopyOutlined />
                                </span>
                            </Tooltip>
                        </Tag>
                    </Space>

                    <Typography.Title level={4} className="mt-3">
                        Horário de funcionamento
                    </Typography.Title>
                    <Typography.Text className="text-muted">
                        Preencha os dias com os horários que o robô irá funcionar e os atendentes estarão online.
                    </Typography.Text>

                    <Space direction="vertical" className="mt-4">
                        {businessHours.map((value: any, index: any) => {
                            return (
                                <Space key={'openingHours_'+index}>
                                    <Form.Item name={['openingHours_'+index, 'dayOfWeek']} label={index === 0 && "Dia da Semana"} initialValue={value?.dayOfWeek} style={{ marginBottom: 0, marginTop: 0, width: isSmallScreen ? '100%' : 450 }}>
                                        <Select
                                            onChange={(value) => {
                                                if (businessHours.filter((item: any) => item.dayOfWeek === value).length > 0) {
                                                    message.error('Você já adicionou esse dia da semana');
                                                    return;
                                                }

                                                businessHours[index]['dayOfWeek'] = value
                                            }}
                                            value={businessHours[index]?.dayOfWeek}
                                        >
                                            <Option value="monday" disabled={businessHours.filter((item: any) => item.dayOfWeek === 'monday').length > 0}>Segunda-feira</Option>
                                            <Option value="tuesday" disabled={businessHours.filter((item: any) => item.dayOfWeek === 'tuesday').length > 0}>Terça-feira</Option>
                                            <Option value="wednesday" disabled={businessHours.filter((item: any) => item.dayOfWeek === 'wednesday').length > 0}>Quarta-feira</Option>
                                            <Option value="thursday" disabled={businessHours.filter((item: any) => item.dayOfWeek === 'thursday').length > 0}>Quinta-feira</Option>
                                            <Option value="friday" disabled={businessHours.filter((item: any) => item.dayOfWeek === 'friday').length > 0}>Sexta-feira</Option>
                                            <Option value="saturday" disabled={businessHours.filter((item: any) => item.dayOfWeek === 'saturday').length > 0}>Sábado</Option>
                                            <Option value="sunday" disabled={businessHours.filter((item: any) => item.dayOfWeek === 'sunday').length > 0}>Domingo</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name={['openingHours_'+index, 'openingHour']} label={index === 0 && "Abertura"} initialValue={dayjs(value?.openingHour, 'HH:mm')} style={{ marginBottom: 0, marginTop: 0 }}>
                                        <TimePicker onChange={(value) => businessHours[index]['openingHour'] = value} format="HH:mm" value={value?.openingHour}/>
                                    </Form.Item>

                                    <Form.Item name={['openingHours_'+index, 'closingHour']} label={index === 0 && "Fechamento"} initialValue={dayjs(value?.closingHour, 'HH:mm')} style={{ marginBottom: 0, marginTop: 0 }}>
                                        <TimePicker onChange={(value) => businessHours[index]['closingHour'] = value} format="HH:mm" value={value?.closingHour}/>
                                    </Form.Item>
                                    <Form.Item label={index === 0 && 'Ação'} style={{ marginBottom: 0, marginTop: 0 }}>
                                        <Button
                                            type="ghost"
                                            icon={<DeleteOutlined />}
                                            onClick={() => handleDeleteDay(index)}
                                        />
                                    </Form.Item>
                                </Space>
                            )
                        })}

                        <Button type="ghost" onClick={() => addDay({})}>
                            Adicionar Dia
                        </Button>
                    </Space>

                    <Typography.Title level={4} className="mt-5">
                        Mensagens automáticas (opcional)
                    </Typography.Title>
                    <Typography.Text className="text-muted">
                        Adicione mensagens automáticas do robô, caso nenhuma seja adicionada, o robô responderá com a mensagem de boas vindas e as opções de departamentos para atendimento.
                    </Typography.Text>

                    <Col className="mt-4">
                        {automaticMessages?.map((automaticMessage, i) => (
                            <Row className="border rounded p-3   mb-4" justify={'center'} align={'middle'}>
                                <Col md={21} className="mt-2">
                                    <Typography.Text strong>{i + 1}. {automaticMessage.text}</Typography.Text>
                                    <Typography.Paragraph className="mt-2">
                                        Resposta: {automaticMessage.reply}
                                    </Typography.Paragraph>
                                    <Typography.Paragraph>
                                        Transfere para o atendimento? {automaticMessage.shouldRedirectToAttendance ? 'Sim' : 'Não'}
                                    </Typography.Paragraph>
                                </Col>
                                <Col md={3} style={{ marginBottom: -10}}>
                                    <Row>
                                        <Space>
                                            <Button
                                                type="ghost"
                                                icon={<EditOutlined />}
                                                onClick={() => {
                                                    setSelectedMessageIndex(i);
                                                    setBotMessageModal(true);
                                                }}
                                            />
                                            <Button
                                                type="ghost"
                                                icon={<DeleteOutlined />}
                                                onClick={() => removeMessage(i)}
                                            />
                                        </Space>
                                    </Row>
                                </Col>
                            </Row>
                        ))}

                        <Button type="ghost" onClick={() => setBotMessageModal(true)}>
                            Adicionar mensagem
                        </Button>
                    </Col>
                </Form>
            </Drawer>

            <AddBotMessageModal
                visible={botMessageModal}
                setVisible={setBotMessageModal}
                editMessage={selectedMessageIndex !== null ? automaticMessages[selectedMessageIndex] : null}
                onSubmit={(message) => {
                    addMessage(message);
                    setBotMessageModal(false);
                }}
            />
        </>
    )
}