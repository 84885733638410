import { AxiosError } from 'axios';
import { MutationCache, QueryCache, QueryClient } from 'react-query';
import { exceptions } from '../constants/exceptions.constants';
export * from 'react-query';

export const handleApiError = (e: any) => {
    if (e instanceof AxiosError) {
        if (e.response?.data?.message) {
            return exceptions[e.response?.data?.message];
        }
    }

    return 'NO_MAP_ERROR';
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
            retry: false,
        },
    },
    queryCache: new QueryCache({
        onError: async (error: any) => {
            return handleApiError(error);
        },
    }),
    mutationCache: new MutationCache({
        onError: async (error: any) => {
            return handleApiError(error);
        },
    }),
});
