import React, { useEffect, useState } from "react";
import { message, Table, Menu, Empty } from "antd";
import { useSelector } from "react-redux";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { RootState } from "../../../store";
import { ChatService } from "../../../services/chat.service";
import { EllipsisDropdown } from "../../ellipsis-dropdown";
import { ChatShortcutTypes } from "../../../types/chat-shortcut.types";
import ConfirmActionModal from "../../confirm-action-modal";

export const ChatShortcutList = () => {
    const { loading, chatShortcutPagination } = useSelector((state: RootState) => state.chat);
    const [selectedShortcut, setSelectedShortcut] = useState<ChatShortcutTypes.Model>({} as ChatShortcutTypes.Model);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 50,
    });

    const onEditPress = (shortcut: ChatShortcutTypes.Model) => {
        ChatService.dispatch.setShortcut(shortcut);
        ChatService.dispatch.toggleModal('shortcutForm', true);
    }

    const onChangePagination = (page: number, perPage: number) => {
        setPagination({ page, perPage });
    }
    
    const onDeletePress = () => {
        setDeleteModal(true);
    }

    const onDeleteCancel = () => {
        setDeleteModal(false);
    }

    const onDeleteConfirm = async () => {
        await ChatService.destroyShortcut(selectedShortcut?.id, (isValid, msg) => {
            if (!isValid) {
                message.error(msg);
                return;
            }

            message.success(msg);
            onDeleteCancel();
        });
    }

    useEffect(() => {
        (async () => {
            await ChatService.fetchChatShortcuts(
                { 
                    ...pagination, 
                },
                (isValid, msg) => {}
            );
        })();
    }, [pagination]);   

    return (
        <>
            <div className="table-responsive">
                <Table 
                    columns={[
                        {
                            title: 'Comando',
                            render: (_, shortcut) => <>{shortcut?.command}</> 
                        },
                        {
                            title: 'Mensagem',
                            render: (_, shortcut) => <>{shortcut?.content}</> 
                        },
                        {
                            title: 'Criado por',
                            dataIndex: 'createdAt',
                            render: (_, shortcut) => shortcut?.user?.name,
                        },
                        {
                            title: 'Ações',
                            dataIndex: 'actions',
                            render: (_, shortcut) => (
                                <div className="text-left"> 
                                    <EllipsisDropdown
                                        menu={
                                            <Menu>
                                                <Menu.Item key="0" onClick={() => onEditPress(shortcut)}>
                                                    <EditOutlined />
                                                    <span className="ml-2">Editar</span>
                                                </Menu.Item>
                                                <Menu.Divider />
                                                <Menu.Item key="1" onClick={() => {
                                                    setSelectedShortcut(shortcut);
                                                    onDeletePress();
                                                }}>
                                                    <DeleteOutlined />
                                                    <span className="ml-2">Excluir atalho</span>
                                                </Menu.Item>
                                            </Menu>
                                        }
                                    />
                                </div>
                            )
                        }
                    ]} 
                    loading={loading.fetchShortcuts}
                    dataSource={chatShortcutPagination?.items} 
                    pagination={{
                        pageSize: pagination.perPage,
                        total: chatShortcutPagination?.total,
                        defaultCurrent: 1,
                        onChange: onChangePagination
                    }}
                    locale={{
                        emptyText: <Empty className='mt-5 mb-5' image={Empty.PRESENTED_IMAGE_DEFAULT} description={'Aparentemente, você ainda não criou nenhum atalho.'} />
                    }}
                />
            </div>
            <ConfirmActionModal
                title={'Excluir atalho'}
                loading={loading.deleteShortcut}
                visible={deleteModal}
                onCancel={onDeleteCancel}
                onDelete={onDeleteConfirm}
            />
        </>
    )
}