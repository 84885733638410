import { Badge, Card, Col, DatePicker, Descriptions, Radio, RadioChangeEvent, Row, Select, Tag, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import moment from 'moment';
import type { RangePickerProps } from 'antd/es/date-picker';
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import 'antd/dist/antd.css';

import { RootState } from "../../../../store";
import { botService } from "../../../../services/bot.service";
import { BotTypes } from "../../../../types";

const options = [
    { label: 'Envio Imediato', value: 'imediate' },
    { label: 'Agendar', value: 'scheduled' },
];

interface Props {
    reviewCampaign: any,
    scheduled: string,
    selectedBots: number[],
    resend: string | null,
    setSelectedBots: (bots: number[]) => void,
    setScheduleDate: (date: string | null) => void,
    setResend: (resend: string | null) => void,
}

const { Option } = Select;

export const Step3: React.FC<Props> = ({ scheduled, selectedBots, reviewCampaign, setScheduleDate, setSelectedBots, resend, setResend }) => {
    const [scheduledType, setScheduledType] = useState('imediate');

    const onScheduleTypeChange = ({ target: { value } }: RadioChangeEvent) => {
        if (value === 'imediate') {
            setScheduleDate('');
            setResend('');
        }
        setScheduledType(value);
    };

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current && current < moment().subtract(1, 'day').endOf('day');
    };

    const { pagination: botPagination } = useSelector((state: RootState) => state.bot);
    const { campaign, duplicated } = useSelector((state: RootState) => state.campaign);

    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 50,
    });


    useEffect(() => {
        botService.getMany({
            ...pagination,
            filter: {
                status: 'connected',
            }
        });
    }, [pagination]);

    useEffect(() => {
        if (duplicated) {
            let bots: BotTypes.Model[] = []
            campaign.campaignsxbots.map(cxb => {
                bots.push(cxb.bot)
            })

            setSelectedBots(bots.map(b => b.id))
            campaign.scheduled && setScheduledType('scheduled')
        }
    }, [])

    const [isChecked, setChecked] = useState(false);
  
    const handleCheckboxChange = () => {
      setChecked(!isChecked);
    };
  
    const handleSelectChange = (value: any) => {
      setResend(value);
    };

    return (
        <div>
            <Card style={{ width: '100%' }}>
                <Row>
                    <Col span={10}>
                        <Radio.Group options={options} onChange={onScheduleTypeChange} value={scheduledType} optionType="button" />
                        <div style={{ marginTop: 10 }}>
                            {scheduledType === 'scheduled' ?
                                <>
                                    <DatePicker
                                        placeholder="Selecione a data"
                                        format="DD/MM/YYYY HH:mm:ss"
                                        disabledDate={disabledDate}
                                        showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                        onChange={date => {
                                            setScheduleDate(date?.add(3, 'hours').format('YYYY-MM-DD HH:mm:ss').toString()!);
                                        }}
                                    />
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    >
                                        Repetir envio:
                                    </Checkbox>
                                    {isChecked && (
                                        <Select
                                            style={{ width: 200, marginTop: 10 }}
                                            placeholder="Selecione uma opção"
                                            onChange={handleSelectChange}
                                            value={resend || null}
                                        >
                                            <Option value="every_day">Todo dia</Option>
                                            <Option value="every_week">Toda semana</Option>
                                            <Option value="every_month">Todo mês</Option>
                                        </Select>
                                    )}
                                </>
                                : null
                            }
                        </div>
                    </Col>
                    <Col span={14}>
                        <div>
                            <b> Selecionar bots online </b>
                            <Select
                                mode="multiple"
                                style={{ width: '100%', marginTop: 10 }}
                                placeholder="Selecione os bots"
                                value={selectedBots}
                                onChange={(bots) => {
                                    setSelectedBots(bots);
                                }}
                                optionLabelProp="label"
                                options={botPagination?.items?.map((bot) => ({ label: bot?.name, value: bot?.id }))}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>
            <Card style={{ width: '100%' }} title="Revisar informações">
                <Descriptions>
                    <Descriptions.Item label="Status" span={3}>
                        <Badge status="processing" text="A enviar" />
                    </Descriptions.Item>
                    <Descriptions.Item label="Campanha" span={3}>
                        {reviewCampaign?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Mensagem" span={3}>
                        {reviewCampaign?.message}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="Botões" span={1}>
                        {reviewCampaign?.buttons?.map((button: any) => <Tag color="default">{button}</Tag>)}
                    </Descriptions.Item> */}
                    <Descriptions.Item label="Arquivo selecionado" span={3}>
                        {reviewCampaign?.fileList?.map((file: any) => <Tag color="cyan">{file?.originFileObj?.name}</Tag>)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Agendado?" span={3}> <Tag color="success">{scheduledType === 'imediate' ? 'Não' : 'Sim'}</Tag> </Descriptions.Item>
                    <Descriptions.Item label="Data do agendamento">{scheduledType === 'imediate' ? 'Imediato' : dayjs(scheduled).format('DD-MM-YYYY HH:mm:ss')}</Descriptions.Item>
                </Descriptions>
            </Card>
        </div>
    )
}