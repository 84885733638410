import type { RootModel } from '.';
import { createModel } from "@rematch/core";

import { PaginationFactory } from '../factory';
import { DepartmentTypes } from '../types';
import { AttendanceTypes } from '../types/attendance.types';

export interface DepartmentStoreProps {
    department: DepartmentTypes.Model,
    pagination: PaginationFactory<DepartmentTypes.Model>,
    departmentAttendancesPagination: PaginationFactory<AttendanceTypes.Model>,
    loading: {
        fetchMany: boolean,
        form: boolean,
        destroy: boolean,
        fetchAttendancesByDepartment: boolean,
    },
    modal: {
        details: boolean,
        form: boolean,
    },
};
export const department = createModel<RootModel>()({
    state: {
        department: {} as DepartmentTypes.Model,
        departmentAttendancesPagination: {
            items: [] as AttendanceTypes.Model[],
        } as PaginationFactory<AttendanceTypes.Model>,
        pagination: {
            items: [] as DepartmentTypes.Model[],
        } as PaginationFactory<DepartmentTypes.Model>,
        loading: {
            fetchMany: false,
            form: false,
            destroy: false,
            fetchAttendancesByDepartment: false,
        },
        modal: {
            details: false,
            form: false,
        }
    },
    reducers: {
        setDepartmentAttendancesPagination: (store, pagination: DepartmentStoreProps['departmentAttendancesPagination']) => {
            store.departmentAttendancesPagination = pagination;
        },
        setDepartment: (store, department: DepartmentTypes.Model) => {
            store.department = department;
        },
        toggleLoading: (
            store, prop: keyof DepartmentStoreProps['loading'], v: boolean) => {
                store.loading[prop] = v;
            },
        setPagination: 
            (store, pagination: DepartmentStoreProps['pagination']) => {
                store.pagination = pagination;
            },
        addPaginationItem: (store, department: DepartmentTypes.Model) => {
            store.pagination.items = [...store.pagination.items, department];
        },
        removePaginationItem: (store, id: number) => {
            store.pagination.items = store.pagination.items.filter(i => i.id !== id);
        },
        updatePaginationItem: (store, id: number, department: DepartmentTypes.Model) => {
            store.pagination.items = store.pagination.items.map((i) => {
                if (i.id === id) {
                    return department;
                }

                return i;
            });
        },
        eraseFormModal: (store) => {
            store.modal.form = false;
            store.department = {} as DepartmentTypes.Model;
            store.loading.form = false;
        },
        toggleModal: (store, prop: keyof DepartmentStoreProps['modal'], v: boolean) => {
            store.modal[prop] = v;
        },
    },
});
