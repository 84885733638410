
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, message, Alert, Select } from "antd";
import { useSelector } from "react-redux";
import { If } from 'react-if';
import { motion } from "framer-motion";
import { Rule } from "antd/lib/form";

import { departmentService } from "../../../services/department.service";
import { RootState } from "../../../store";
import { DepartmentTypes } from "../../../types";
import { botService } from "../../../services/bot.service";

export const DepartmentForm = () => {
    const [form] = Form.useForm<DepartmentTypes.Form>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 50,
    });

    const { modal, department, loading } = useSelector((state: RootState) => state.department);
    const { pagination: botPagination } = useSelector((state: RootState) => state.bot);

    const isFromEdit = modal.form && department?.id;

    const onClose = () => {
        departmentService.dispatch.eraseFormModal();
    }

    const rules: Record<string, Rule[]> = {
        name: [
            { 
                required: true,
                message: 'Informe o nome do departamento'
            },
        ],
        botId: [
            {
                required: true,
                message: 'Por favor, selecione o bot que será responsável pelos atendimentos'
            }
        ]
    }

    const onSubmit = async (botId: number, name: string) => {
        setErrorMessage(null);
        if (isFromEdit) {
            await departmentService.update(department?.id!, botId, name, (isValid, msg) => {
                if (!isValid) {
                    setErrorMessage(msg);
                    return;
                }

                message.success(msg);
                onClose();
            });
            return;
        }

        await departmentService.create(name, botId, (isValid, msg) => {
            if (!isValid) {
                setErrorMessage(msg);
                return;
            }

            message.success(msg);
            onClose();
        });
    }

    useEffect(() => {
        if (isFromEdit) {
            form.setFieldsValue({
                name: department.name!,
                botId: department.botId!,
            });
        }

        return () => {
            setErrorMessage(null);
        }

    }, [modal.form]);

    useEffect(() => {
        botService.getMany({
            ...pagination,
            filter: {}
        });
    }, [pagination]);

    return (
        <Modal
            title={isFromEdit ? 'Editar departamento' : 'Criar um novo departamento'}
            onCancel={onClose}
            open={modal.form}
            okText={'Salvar alterações'}
            cancelText={'Cancelar'}
            onOk={() => {
                form.submit();
            }}
            confirmLoading={loading.form}
        >
            <If condition={errorMessage !== null && !loading.form}>
                <motion.div 
                    initial={{ opacity: 0, marginBottom: 0 }} 
                    animate={{ 
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0 
                    }}
                > 
                    <Alert 
                        onClose={() => setErrorMessage(null)} 
                        type="error" 
                        showIcon
                        message={errorMessage}
                        closable
                    />
                </motion.div>
            </If>
            <Form
                layout="vertical"  
                form={form}
                onFinish={async (values) => await onSubmit(values.botId, values.name)}
            >
                <Form.Item 
                    name="name" 
                    label="Nome" 
                    rules={rules.name}
                    hasFeedback
                >
                    <Input placeholder="Digite um nome para o departamento" />
                </Form.Item>
                <Form.Item 
                    name="botId" 
                    label="Bot responsável" 
                    rules={rules.name}
                    
                >
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Selecione os bots"
                        value={form.getFieldValue('botId')}
                        onChange={(selectedBotId) => {
                            form.setFieldValue('botId', selectedBotId);
                        }}
                        optionLabelProp="label"
                        options={botPagination?.items?.map((bot) => ({ label: bot?.name, value: bot?.id }))}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}