import { Axios, AxiosResponse } from "axios";
import queryStringify from "qs-stringify";

import { api, sanitalizeApiException } from "../http/http";
import { store } from '../store';
import { DefaultCallback, DefaultRes } from "../types";
import { AttendanceTypes, } from "../types/attendance.types";
import { PaginationFactory, PaginatorFactory } from "../factory";
import { ChatShortcutTypes } from "../types/chat-shortcut.types";

export namespace ChatService {
    export const dispatch = store.dispatch.chat;
    const userDispatch = store.dispatch.user;

    export const getChatAttendancesByUser = async (callback: DefaultCallback) => {
        try {
            dispatch.toggleLoading('fetchAttendances', true);

            const { 
                data: {
                    isValid,
                    message,
                    result,
                }
            } = await api.get<
                DefaultRes<AttendanceTypes.GetChatAttendancesByUser>,
                AxiosResponse<DefaultRes<AttendanceTypes.GetChatAttendancesByUser>>
            >(`/Attendances/GetChatAttendancesByUser`);

            dispatch.toggleLoading('fetchAttendances', false);

            if (isValid) {
                dispatch.setAttendancesPagination(result);
            }
            
            return callback(isValid, message);

        } catch (error) {
            dispatch.toggleLoading('fetchAttendances', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    export const fetchAttendanceMessages = async (callback: DefaultCallback) => {
        try {
            dispatch.toggleLoading('fetchAttendanceMessages', true);
            dispatch.setAttendanceMessages([] as AttendanceTypes.Message[]);

            const { 
                data: {
                    isValid,
                    message,
                    result,
                }
            } = await api.get<
                DefaultRes<AttendanceTypes.Message[]>,
                AxiosResponse<DefaultRes<AttendanceTypes.Message[]>>
            >(`/Attendances/${store.getState().chat.attendance?.id}/GetAttendanceMessages`);

            dispatch.toggleLoading('fetchAttendanceMessages', false);

            if (isValid) {
                dispatch.setAttendanceMessages(result);
            }
            
            return callback(isValid, message);

        } catch (error) {
            dispatch.toggleLoading('fetchAttendanceMessages', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    export const sendMessage = async (
        text: string,
        attendanceId: number,
        type?: string,
        base64?: string,
        callback?: DefaultCallback
    ) => {
        try {
            dispatch.toggleLoading('sendMessage', true);

            const { 
                data: {
                    isValid,
                    result,
                    message,
                }
            } = await api.post<
                DefaultRes<AttendanceTypes.Message>,
                AxiosResponse<DefaultRes<AttendanceTypes.Message>>
            >(`/Attendances/SendMessage`, {
                text,
                botId: 1,
                attendanceId,
                type,
                base64,
            });

            dispatch.toggleLoading('sendMessage', false);

            if (isValid) {
                //dispatch.addAttendanceMessage(attendanceId, result);
            }

            return callback && callback(isValid, message);

        } catch (error) {
            dispatch.toggleLoading('sendMessage', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }
    
    export const toggleUserAttendanceStatus = async (callback: DefaultCallback) => {
        try {
            const { 
                data: {
                    isValid,
                    result,
                    message,
                }
            } = await api.post<
                DefaultRes<{ isOnFire: boolean }>,
                AxiosResponse<DefaultRes<{ isOnFire: boolean }>>
            >(`/Attendances/ToggleUserAttendanceStatus`);

            userDispatch.toggleUserAttendanceStatus(result.isOnFire);

            return callback(isValid, message);
        } catch (error) {
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    export const fetchChatShortcuts = async (pagination: PaginatorFactory<any>, callback: DefaultCallback) => {
        try {
            dispatch.toggleLoading('fetchShortcuts', true);

            const qsfy = queryStringify({
                perPage: pagination.perPage,
                page: pagination?.page,
            });

            const { 
                data: {
                    isValid,
                    result: { 
                        items,
                        page,
                        perPage,
                        total,
                    },
                    message,
                }
            } = await api.get<
                DefaultRes<PaginationFactory<ChatShortcutTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<ChatShortcutTypes.Model>>>
            >(`/ChatShortcuts?${qsfy}`);

            dispatch.setChatShortcutPagination({ items, page, perPage, total });

            dispatch.toggleLoading('fetchShortcuts', false);

            return callback(isValid, message);
        } catch (error) {
            dispatch.toggleLoading('fetchShortcuts', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    export const createShortcut = async (values: ChatShortcutTypes.Form, callback: DefaultCallback) => {
        try {
            dispatch.toggleLoading('createShortcut', true);
            
            const {
                data: {
                    isValid,
                    result,
                    message,
                },
            } = await api.post<
                DefaultRes<ChatShortcutTypes.Model>,
                AxiosResponse<DefaultRes<ChatShortcutTypes.Model>>
            >('/ChatShortcuts', { ...values });

            if (isValid) {
                dispatch.addChatShortcutPaginationItem(result);
            }

            dispatch.toggleLoading('createShortcut', false);

            return callback(isValid, message);
        } catch (error) {
            dispatch.toggleLoading('createShortcut', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    export const updateShortcut = async (id: number, values: ChatShortcutTypes.Form, callback: DefaultCallback) => {
        try {
            dispatch.toggleLoading('updateShortcut', true);

            const {
                data: {
                    isValid,
                    result,
                    message,
                },
            } = await api.put<
                DefaultRes<ChatShortcutTypes.Model>,
                AxiosResponse<DefaultRes<ChatShortcutTypes.Model>>
            >(`/ChatShortcuts/${id}`, { ...values });

            if (isValid) {
                dispatch.updateChatShortcutPaginationItem(id, result);
            }

            dispatch.toggleLoading('updateShortcut', false);

            return callback(isValid, message);
        } catch (error) {
            dispatch.toggleLoading('updateShortcut', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    export const destroyShortcut = async (shortcutId: number, callback: DefaultCallback) => {
        try {
            dispatch.toggleLoading('deleteShortcut', true);

            const { 
                data,
            } = await api.delete<
                DefaultRes<boolean>,
                AxiosResponse<DefaultRes<boolean>>
            >(`/ChatShortcuts/${shortcutId}`);

            if (!data.isValid) {
                dispatch.toggleLoading('deleteShortcut', false);
                return callback(false, data?.message[0]);
            }

            dispatch.removeChatShortcutPaginationItem(shortcutId);
            dispatch.toggleLoading('deleteShortcut', false);

            return callback(true, 'Atalho excluído com sucesso!');

        } catch (error) {
            dispatch.toggleLoading('deleteShortcut', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    export const changeAttendanceStatus = async (
        currentStatus: keyof AttendanceTypes.GetChatAttendancesByUser,
        attendanceId: number, 
        status: AttendanceTypes.Status, 
        callback: DefaultCallback
    ) => {
        try {
            dispatch.toggleLoading('changeAttendanceStatus', true);

            const {
                data: {
                    isValid,
                    message,
                    result,
                },
            } = await api.put<
                DefaultRes<{ status: AttendanceTypes.Status, message: AttendanceTypes.Message }>,
                AxiosResponse<DefaultRes<{ status: AttendanceTypes.Status, message: AttendanceTypes.Message }>>
            >(`/Attendances/ChangeStatus/${attendanceId}`, { status });

            dispatch.toggleLoading('changeAttendanceStatus', false);

            if (!isValid) {
                return callback(false, message[0]);
            }

            dispatch.addAttendanceMessage(attendanceId, result.message);
            dispatch.changeAttendanceStatus({ attendanceId, currentStatus, newStatus: result.status });
            
            const renderMessageResult: Record<AttendanceTypes.Status, string> = {
                [AttendanceTypes.Status.STARTED]: 'Atendimento iniciado.',
                [AttendanceTypes.Status.AWAITING]: '',
                [AttendanceTypes.Status.FINISHED]: 'Atendimento finalizado.',
                [AttendanceTypes.Status.POTENTIAL]: '',
            };

            return callback(true, renderMessageResult[status]);
        } catch (error) {
            dispatch.toggleLoading('changeAttendanceStatus', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    export const startLeadAttendance = async (
        leadName: string, 
        contact: string, 
        userId: number, 
        departmentId: number,
        botId: number,
        callback: DefaultCallback
    ) => {
        try {
            dispatch.toggleLoading('startNewAttendance', true);

            const { data: { isValid, message }} = await api.post<DefaultRes, AxiosResponse<DefaultRes>>('/Attendances/StartLeadAttendance', { botId, userId, contact, leadName, departmentId });

            dispatch.toggleLoading('startNewAttendance', false);

            if (!isValid) {
                return callback(false, message[0]);
            }

            return callback(true, 'Atendimento iniciado com sucesso.');

        } catch (error) {
            dispatch.toggleLoading('startNewAttendance', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }
}