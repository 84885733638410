import React from "react";
import { useSelector } from "react-redux";
import { ContactsOutlined } from '@ant-design/icons';
import { Button, Modal } from "antd";
import Scrollbars from "react-custom-scrollbars-2";

import { RootState } from "../../store";
import { ChatService } from "../../services/chat.service";
import { LeadList } from "../lists/lead-list";
import { leadService } from "../../services/lead.service";
import Flex from "../flex";

export const ChatLeadsModal = () => {
    const { modal } = useSelector((state: RootState) => state.chat);

    const onAddNew = () => {
        leadService.dispatch.toggleModal('form', true);
    }

    return (
        <Modal
            title={'Leads'}
            open={modal.chatLeads}
            onCancel={() => ChatService.dispatch.toggleModal('chatLeads', false)}
            footer={null}
            width={1024}
        >
            <Flex justifyContent="between" alignItems="center" className="mb-3">
                <>
                    <div></div>
                    <Button size='small' type="primary" className="ml-2" onClick={onAddNew}>
                        <span>Novo lead</span>
                        <ContactsOutlined />
                    </Button>
                </>
            </Flex>
            <Scrollbars style={{ height: 550 }}>
                <LeadList activeStartNewAttendance={true} />
            </Scrollbars>
        </Modal>
    )
}