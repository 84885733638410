import React, { useEffect, useState } from 'react';
import { Button, Table, Card, Input, Menu, message, Tag, Space, Empty, Row, Avatar, Typography, Col, Form, DatePicker } from 'antd';
import { DeleteOutlined, SearchOutlined, EditOutlined, PhoneOutlined, EyeOutlined, FileAddOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Rule } from 'antd/lib/form';
import _ from 'lodash';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

import { RootState } from '../../../store';
import Flex from '../../flex';
import { EllipsisDropdown } from '../../ellipsis-dropdown';
import { leadService } from '../../../services/lead.service';
import ConfirmActionModal from '../../confirm-action-modal';
import { LeadTypes } from '../../../types';
import { RegexFactory } from '../../../factory/regex.factory';
import { LeadForm } from '../../forms/lead-form';
import { ImportLeadsForm } from '../../forms/import-leads-form';
import { ChatService } from '../../../services/chat.service';
import { useCheckPriv } from '../../../hooks/useCheckPriv';
import { modulePermissions } from '../../../constants/module.permissions';

interface Props {
    defaultSelectedRowKeys?: number[] | string[],
    onRowSelect?(rows: LeadTypes.Model[]): void,
    activeStartNewAttendance?: boolean,
}

export const LeadList: React.FC<Props> = ({ defaultSelectedRowKeys, onRowSelect, activeStartNewAttendance = false }) => {
    const [filterForm] = Form.useForm<LeadTypes.Form>();
    const { checkPriv } = useCheckPriv();
    const { pagination: leadPagination, loading } = useSelector((state: RootState) => state.lead);
    const [selectedLead, setSelectedLead] = useState<LeadTypes.Model>({} as LeadTypes.Model);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 15,
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState([] as any);

    const onSelectChange = (selectedKeys: any) => {
        setSelectedRowKeys(selectedKeys);
    };

    const rules: Record<string, Rule[]> = {
        name: [
            { 
                required: false,
            },
        ],
        contact: [
            {
                required: false,
            },
        ],
        initialDate: [
            {
                required: false,
            },
        ],
        finalDate: [
            {
                required: false,
            },
        ],
    }

    const onEditPress = (lead: LeadTypes.Model) => {
        leadService.dispatch.setLead(lead);
        leadService.dispatch.toggleModal('form', true);
    }

    const onDeletePress = () => {
        setDeleteModal(true);
    }

    const onDeleteCancel = () => {
        setDeleteModal(false);
    }

    const onChangePagination = (page: number, perPage: number) => {
        setPagination(p => ({ ...p, page, perPage }));
    }

    const onDeleteConfirm = async () => {
        await leadService.destroy(selectedLead?.id, (isValid, msg) => {
            if (!isValid) {
                message.error(msg);
                return;
            }

            message.success('Seu lead foi excluído com sucesso 🧨');
            onDeleteCancel();
        });
    }

    const onFilter = async (filter: LeadTypes.Filters, clear = false) => {
        if (clear) {
            filterForm.resetFields();
            setDateRange([null, null]);
        }

        if (dateRange[0] && dateRange[1]) {
            filter.initialDate = moment(dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss')
            filter.finalDate = moment(dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        }

        await leadService.getMany({ ...pagination, filter: clear ? {} as LeadTypes.Filters : { ..._.omitBy(filter, _.isNil) } as LeadTypes.Filters });
    }

    useEffect(() => {
        leadService.getMany({ ...pagination });
    }, [pagination]);

    const [dateRange, setDateRange] = useState([null, null]);

    const handleDateChange = (dates: any) => {
        setDateRange(dates);
    };

    const exportToCSV = (isCopyToClipboard = false) => {
        const selectedLeads = leadPagination?.items.filter((lead) => selectedRowKeys.includes(lead.id)).map(lead => ({
            name: lead.name,
            contact: lead.contact,
            channel: lead.channel,
            createdAt: moment(lead.createdAt).format('DD/MM/YYYY HH:mm'),
            updatedAt: moment(lead.updatedAt).format('DD/MM/YYYY HH:mm')
        }))

        if (isCopyToClipboard) {
            const csvData = Papa.unparse(selectedLeads);
            navigator.clipboard.writeText(csvData);
            message.success('Itens copiados para a área de transferência');
            return;
        }

        const csvData = Papa.unparse(selectedLeads);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'tigerdesk-selected-leads.csv');
    };

    return (
        <>
            <LeadForm />
            <ImportLeadsForm />
            <ConfirmActionModal
                title={'Excluir lead'}
                loading={loading.destroy}
                visible={deleteModal}
                onCancel={onDeleteCancel}
                onDelete={onDeleteConfirm}
            />
            <Card>
                <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
                    <>
                        <Flex alignItems="center" justifyContent="center" className="mb-1" mobileFlex={false}>
                            <>
                                <Form
                                    layout="vertical"  
                                    form={filterForm}
                                    onFinish={async (values) => await onFilter(values)}
                                >
                                    <Space>
                                        <div className="mr-md-6 mb-3">
                                            <Form.Item 
                                                name="name" 
                                                rules={rules.name}
                                                hasFeedback
                                            >
                                                <Input
                                                    placeholder="Pesquisar por nome"
                                                    prefix={<SearchOutlined />}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="mr-md-6 mb-3 ml-2">
                                            <Form.Item 
                                                name="contact" 
                                                rules={rules.name}
                                                hasFeedback
                                            >
                                                <Input
                                                    placeholder="Pesquisar por telefone"
                                                    prefix={<PhoneOutlined />}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <Form.Item>
                                                <DatePicker.RangePicker
                                                    placeholder={['Data Inicial', 'Data Final']}
                                                    value={dateRange as any}
                                                    onChange={handleDateChange}
                                                ></DatePicker.RangePicker>
                                            </Form.Item>
                                        </div>
                                    </Space>
                                </Form>
                            </>
                        </Flex>
                        <Flex flexDirection='row-reverse' className='p-2'>
                            <>
                                <Button type="primary" className='mr-2' block onClick={async () => await filterForm.submit()}>
                                    <span>Buscar</span>
                                    <SearchOutlined />
                                </Button>
                                <Button type="default" block onClick={async () => await onFilter({} as LeadTypes.Filters, true)}>Limpar filtro</Button>
                            </>
                        </Flex>
                    </>
                </Flex>

                <Button onClick={() => exportToCSV()}>Exportar para CSV</Button>
                <Button onClick={() => exportToCSV(true)} style={{ marginLeft: 10 }}>Copiar Itens</Button>

                <div className="table-responsive">
                    <Table
                        columns={[
                            {
                                title: 'ID',
                                dataIndex: 'id',
                            },
                            {
                                title: 'Nome',
                                dataIndex: 'name',
                                render: (_, lead) => <Row>
                                    <Space size={'middle'}>
                                        <Avatar shape='circle' size={'default'} src={lead.photo} />
                                        <Col>
                                            <Typography>
                                                {lead.name}
                                            </Typography>
                                            <Typography>
                                                {RegexFactory.phoneNumber(lead.contact)}
                                            </Typography>
                                        </Col>
                                    </Space>
                                </Row>
                            },
                            {
                                title: 'Canal',
                                dataIndex: 'channel',
                                render: (_, lead) => <Tag color='green'>WhatsApp</Tag>
                            },
                            {
                                title: 'Data de criação',
                                dataIndex: 'createdAt',
                                render: (_, lead) => moment(lead.createdAt).format('DD/MM/YYYY HH:mm')
                            },
                            {
                                title: 'Última atualização',
                                dataIndex: 'updatedAt',
                                render: (_, lead) => moment(lead.updatedAt).format('DD/MM/YYYY HH:mm')
                            },
                            {
                                title: 'Ações',
                                dataIndex: 'actions',
                                render: (_, lead) => (
                                    <div className="text-left">
                                        <EllipsisDropdown
                                            menu={
                                                <Menu>
                                                    {activeStartNewAttendance && (
                                                        <Menu.Item key="0" onClick={() => {
                                                            ChatService.dispatch.toggleModal('newAttendanceForm', true);
                                                            ChatService.dispatch.startNewAttendanceFormData(String(lead.name), lead.contact);
                                                            ChatService.dispatch.toggleModal('chatLeads', false);
                                                        }}>
                                                            <FileAddOutlined />
                                                            <span className="ml-2">Iniciar Atendimento</span>
                                                        </Menu.Item>
                                                    )}
                                                    {checkPriv(modulePermissions.leads.GET_LEAD_BY_ID) && 
                                                        <Menu.Item key="1" onClick={() => {
                                                            ChatService.dispatch.setSelectedLeadId(lead.id!);
                                                            ChatService.dispatch.toggleModal('leadDetails', true);
                                                        }}>
                                                            <EyeOutlined />
                                                            <span className="ml-2">Visualizar</span>
                                                        </Menu.Item>
                                                    }
                                                    
                                                    {checkPriv(modulePermissions.leads.GET_LEAD_BY_ID) && 
                                                        <Menu.Item key="2" onClick={() => onEditPress(lead)}>
                                                            <EditOutlined />
                                                            <span className="ml-2">Editar</span>
                                                        </Menu.Item>
                                                    }
                                                    
                                                    <Menu.Divider />

                                                    {checkPriv(modulePermissions.leads.GET_LEAD_BY_ID) &&
                                                        <Menu.Item key="3" onClick={() => {
                                                            setSelectedLead(lead);
                                                            onDeletePress();
                                                        }}>
                                                            <DeleteOutlined />
                                                            <span className="ml-2">Excluir lead</span>
                                                        </Menu.Item>
                                                    }
                                                </Menu>
                                            }
                                        />
                                    </div>
                                )
                            }
                        ]}
                        dataSource={leadPagination.items}
                        loading={loading.fetchMany}
                        rowKey={'id'}
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedRowKeys,
                            defaultSelectedRowKeys: defaultSelectedRowKeys,
                            onChange: (selectedRowKeys: React.Key[], selectedRows) => {
                                onRowSelect && onRowSelect(selectedRows);
                                onSelectChange(selectedRowKeys);
                            },
                        }}
                        locale={{
                            emptyText: <Empty className='mt-5 mb-5' image={Empty.PRESENTED_IMAGE_DEFAULT} description={'Aparentemente, você ainda não cadastrou nenhum lead.'} />
                        }}
                        pagination={{
                            pageSize: pagination.perPage,
                            total: leadPagination.total,
                            defaultCurrent: 1,
                            onChange: onChangePagination
                        }}
                    />
                </div>
            </Card>
        </>
    )
}