import { AxiosResponse } from 'axios';
import qs from 'qs-stringify';
import { PaginationFactory, PaginatorFactory } from '../factory';

import { api, sanitalizeApiException } from "../http/http";
import { store } from '../store';import { DefaultCallback, DefaultRes } from '../types';
import { TagTypes } from '../types/tag.types';
 ;

export const tagService = {
    dispatch: store.dispatch.tag,


    getMany: async (pagination: PaginatorFactory<TagTypes.Filters>, callback?: DefaultCallback) => {
        try {
            tagService.dispatch.toggleLoading('fetchMany', true);

            const qsfy = qs({
                perPage: pagination.perPage,
                page: pagination?.page,
                filter: {
                    ...pagination?.filter,
                },
            });

            const { 
                data: { 
                    result: { 
                        items,
                        page,
                        perPage,
                        total,
                    }, 
                isValid, 
                message 
            }} = await api.get<
                DefaultRes<PaginationFactory<TagTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<TagTypes.Model>>>
            >(`/Tags?${qsfy}`);
            
            if (!isValid) {
                tagService.dispatch.toggleLoading('fetchMany', false);
                return callback && callback(false, message[0]);
            }
            
            tagService.dispatch.setPagination({ items, page, perPage, total });

            tagService.dispatch.toggleLoading('fetchMany', false);

            return callback && callback(true, message[0]);
        } catch (error) {
            tagService.dispatch.toggleLoading('fetchMany', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    create: async (
        name: string,
        callback: DefaultCallback
    ) => {
        try {
            tagService.dispatch.toggleLoading('form', true);

            const response = await api.post<
                DefaultRes<TagTypes.Model>,
                AxiosResponse<DefaultRes<TagTypes.Model>>
            >('/Tags', {
                name,
            });
            
            if (!response.data.isValid) {
                tagService.dispatch.toggleLoading('form', false);
                return callback(false, response.data?.message[0]);
            }

            tagService.dispatch.addTagPaginationItem(response.data.result);
            tagService.dispatch.toggleLoading('form', false);

            return callback(true, 'Tag criada com sucesso!');

        } catch (error) {
            tagService.dispatch.toggleLoading('form', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },


    update: async (
        id: number, 
        name: string,  
        callback: DefaultCallback
    ) => {
        try {
            tagService.dispatch.toggleLoading('form', true);

            const response = await api.put<
                DefaultRes<TagTypes.Model>,
                AxiosResponse<DefaultRes<TagTypes.Model>>
            >(`/Tags/${id}`, {
                name,
            });
            
            if (!response.data.isValid) {
                tagService.dispatch.toggleLoading('form', false);
                return callback(false, response.data?.message[0]);
            }

            tagService.dispatch.updateTagPaginationItem(id, response.data.result);
            tagService.dispatch.toggleLoading('form', false);
            return callback(true, 'Tag alterada com sucesso!');

        } catch (error) {
            tagService.dispatch.toggleLoading('form', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    destroy: async (tagId: number, callback: DefaultCallback) => {
        try {
            tagService.dispatch.toggleLoading('destroy', true);

            const { 
                data,
            } = await api.delete<
                DefaultRes<boolean>,
                AxiosResponse<DefaultRes<boolean>>
            >(`/Tags/${tagId}`);

            if (!data.isValid) {
                tagService.dispatch.toggleLoading('destroy', false);
                return callback(false, data?.message[0]);
            }

            await tagService.getMany({ page: 1, perPage: 50 });
            tagService.dispatch.toggleLoading('destroy', false);

            return callback(true, 'Departamento excluído com sucesso!');

        } catch (error) {
            tagService.dispatch.toggleLoading('destroy', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },
    
}