import React, { useEffect, useState } from "react";
import { Avatar, Button, List, Menu, Row, Switch, Tabs, Typography, message , Badge, Space, Tag, Skeleton } from "antd";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from "react-redux";
import { 
    NotificationOutlined,
    LinkOutlined,
    ContactsOutlined,
    FileAddOutlined,
    ArrowRightOutlined,
} from '@ant-design/icons';

import { EllipsisDropdown } from "../ellipsis-dropdown";
import { ChatService } from "../../services/chat.service";
import { RootState } from "../../store";
import { AttendanceTypes } from "../../types/attendance.types";
import { PaginatorFactory } from "../../factory";
import Flex from "../shared-components/flex";

export const ChatSidebar = () => {
    const { unreadCount, userChatAttendances, selectedAttendanceId, loading, selectedAttendanceTab } = useSelector((state: RootState) => state.chat);
    const { authUser } = useSelector((state: RootState) => state.user);

    const tabLabel: Record<string, string> = {
        [AttendanceTypes.Status.AWAITING]: 'Aguardando',
        [AttendanceTypes.Status.STARTED]: 'Em atendimento',
        [AttendanceTypes.Status.POTENTIAL]: 'Potencial',
    };

    const [pagination, setPagination] = useState<PaginatorFactory<AttendanceTypes.Filters>>({
        page: 1,
        perPage: 1,
        filter: {
            status: selectedAttendanceTab,
        }
    });

    const attendances = userChatAttendances[selectedAttendanceTab];

    const changeFilter = (status: string) => {
        ChatService.dispatch.setSelectedAttendanceTab(status as any);
        setPagination(p => ({ ...p, filter: { status } as any}));
    }

    const onToggleUserAttendanceStatus = async () => {
        await ChatService.toggleUserAttendanceStatus((isValid, msg) => {
            if (!isValid) {
                message.error(msg);
                return;
            }

            const msgContent = !authUser.isOnFire ? 
                'Você agora está online e começará a receber atendimentos.' :
                'Você agora está offline e não receberá mais atendimentos.'

            message.info(msgContent);
        });
    }

    useEffect(() => {
        (async () => {
            await ChatService.getChatAttendancesByUser((isValid, msg) => {});
        })();
    }, []);

    return (
        <>
            <List.Item> 
                <List.Item.Meta 
                    avatar={
                        <Avatar 
                            style={{
                                backgroundColor: '#21B573',
                                verticalAlign: 'middle',
                            }}
                        >
                            {authUser?.name[0]}
                        </Avatar>
                    }
                    title={authUser?.name}
                    description={authUser?.isOnFire ? 'Em atendimento' : 'Fora de atendimento'}
                />
                <Switch 
                    checked={authUser.isOnFire}
                    defaultChecked={authUser.isOnFire}
                    checkedChildren="ON" 
                    unCheckedChildren="OFF" 
                    style={{ marginRight: 10 }}
                    onChange={async () => {
                        await onToggleUserAttendanceStatus();
                    }}
                />
                <EllipsisDropdown menu={
                    <Menu>
                        <Menu.Item key="0" onClick={() => ChatService.dispatch.toggleModal('chatShortcut', true)}>
                            <LinkOutlined />
                            <span>Atalhos</span>
                        </Menu.Item>
                        <Menu.Item key="1" onClick={() => ChatService.dispatch.toggleModal('settingsNotification', true)}>
                            <NotificationOutlined />
                            <span>Notificações</span>
                        </Menu.Item>
                        <Menu.Item key="2" onClick={() => ChatService.dispatch.toggleModal('chatLeads', true)}>
                            <ContactsOutlined />
                            <span>Leads</span>
                        </Menu.Item>
                    </Menu>
                }/>
            </List.Item>
            <Row className="mb-3 ml-1" justify={'center'}>
                <Button icon={<FileAddOutlined />} className="mr-2" size="small" onClick={() => ChatService.dispatch.toggleModal('newAttendanceForm', true)}>
                    Novo atendimento
                </Button>
                <Button type="primary" icon={<ArrowRightOutlined />} size="small">
                    Próximo da fila
                </Button>
            </Row>
            <Tabs
                defaultActiveKey={selectedAttendanceTab}
                type="card"
                animated
                tabPosition="top"
                onChange={(k) => changeFilter(k)}
                size={'small'}
                activeKey={selectedAttendanceTab}
                items={[AttendanceTypes.Status.STARTED, AttendanceTypes.Status.AWAITING, AttendanceTypes.Status.POTENTIAL].map((_, i) => {
                    const id = String(_);
                    return {
                        label: (
                            <Space size="middle">
                                <Typography.Text>
                                    {tabLabel[_]}
                                </Typography.Text>
                            </Space>
                        ),
                        key: id,
                    };
                })}
            />

            <div
                id="scrollableDiv"
                style={{
                    height: '90vh',
                    overflow: 'auto',
                }}
            >
                <InfiniteScroll
                    scrollableTarget="scrollableDiv"
                    hasMore
                    next={() => {}}
                    dataLength={attendances?.length || 0}
                    loader={null}
                >
                    <List
                        style={{ maxHeight: '70vh' }}
                        dataSource={attendances}
                        locale={{ emptyText: 'Não há nenhuma atendimento nessa categoria.' }}
                        pagination={false}
                        loading={loading.fetchAttendances}
                        renderItem={(attendance) => (
                            <List.Item 
                                key={attendance.protocol} 
                                onClick={() => ChatService.dispatch.setSelectedAttendanceId(attendance?.id)} 
                                style={{ background: selectedAttendanceId === attendance?.id ? '#EBEBEB' : 'white' }}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Badge color="green" count={unreadCount[attendance.id]}>
                                            <Avatar 
                                                style={{
                                                    backgroundColor: '#21B573',
                                                    verticalAlign: 'middle',
                                                }}
                                                src={attendance?.photo} 
                                            >
                                                {attendance?.pushName ? attendance?.pushName![0] : ''}
                                            </Avatar>
                                        </Badge>
                                    }
                                    title={
                                        <Flex alignItems="start" flexDirection="column">
                                            <span>
                                                {attendance?.pushName}
                                            </span>
                                            <strong>
                                                Atendimento:  ({attendance?.protocol})
                                            </strong>
                                        </Flex>
                                    }
                                    description={
                                        <Typography.Paragraph ellipsis={{ rows: 2 }}>
                                            {
                                                attendance?.messages![attendance?.messages!?.length - 1]?.content?.text ||
                                                (attendance?.messages![attendance?.messages!?.length - 1]?.content)?.message?.conversation ||
                                                attendance?.messages![attendance?.messages!?.length - 1]?.content?.message?.extendedTextMessage?.text
                                            }
                                        </Typography.Paragraph>
                                    }
                                />
                                <div>
                                    {
                                        attendance?.messages!?.length > 0 ?
                                        moment(attendance?.messages![attendance?.messages!?.length - 1]?.createdAt).format('DD/MM/YYYY HH:mm') : ''
                                    }
                                </div>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </>
    )
}