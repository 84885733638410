import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Alert, Col, Form, Input, Modal, Typography, message } from "antd";
import { Rule } from "antd/lib/form";
import { If } from "react-if";
import { motion } from "framer-motion";
import TextArea from "antd/lib/input/TextArea";

import { RootState } from "../../../store";
import { ChatService } from "../../../services/chat.service";
import { ChatShortcutTypes } from "../../../types/chat-shortcut.types";

export const ChatShortcutForm = () => {
    const [form] = Form.useForm<ChatShortcutTypes.Form>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { modal, loading, shortcut } = useSelector((state: RootState) => state.chat);

    const rules: Record<string, Rule[]> = {
        command: [
            { 
                required: true,
                message: 'Insira o código do atalho'
            },
        ],
        content: [
            { 
                required: true,
                message: 'Insira a mensagem do atalho'
            },
        ],
    }

    const isFromEdit = modal.shortcutForm && shortcut?.id;

    const onClose = () => {
        form.resetFields();
        ChatService.dispatch.setShortcut({} as ChatShortcutTypes.Model);
        ChatService.dispatch.toggleModal('shortcutForm', false);
    }

    const onSubmit = async (values: ChatShortcutTypes.Form) => {
        if (!isFromEdit) {
            await ChatService.createShortcut(values, (isValid, msg) => {
                if (!isValid) {
                    message.error(msg);
                    return;
                }

                form.resetFields();
                message.success('Atalho criado com sucesso!');
                onClose();
            });
            return;
        } else {
            await ChatService.updateShortcut(shortcut.id, values, (isValid, msg) => {
                if (!isValid) {
                    message.error(msg);
                    return;
                }

                form.resetFields();
                message.success('Atalho alterado com sucesso!');
                onClose();
            });
        }
    }

    useEffect(() => {
        if (isFromEdit) {
            form.setFieldsValue({
                command: shortcut.command,
                content: shortcut.content,
            });
        }

        return () => {
            setErrorMessage(null);
        }

    }, [modal.shortcutForm]);

    return (
        <Modal
            title={isFromEdit ? 'Alterar atalho' : 'Criar atalho'}
            onCancel={onClose}
            open={modal.shortcutForm}
            okText={isFromEdit ? 'Salvar alterações' : 'Concluir'}
            cancelText={'Cancelar'}
            onOk={() => {
                form.submit();
            }}
            confirmLoading={loading.createShortcut || loading.updateShortcut}
            width={550}
            style={{ marginTop: 20 }}
        >
            <If condition={errorMessage !== null}>
                <motion.div 
                    initial={{ opacity: 0, marginBottom: 0 }} 
                    animate={{ 
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0 
                    }}
                > 
                    <Alert 
                        onClose={() => setErrorMessage(null)} 
                        type="error" 
                        showIcon
                        message={errorMessage}
                        closable
                    />
                </motion.div>
            </If>
            <Form
                layout="vertical"  
                form={form}
                onFinish={async (values) => {
                    await onSubmit(values);
                }}
            >
                <Col md={24} style={{ marginBottom: 20 }}>
                    <Form.Item 
                        name="command" 
                        label="Código" 
                        rules={rules.command}
                        hasFeedback
                        style={{ marginBottom: 5 }}
                    >
                        <Input addonBefore="/" placeholder="Código para o atalho" />
                    </Form.Item>
                    <Typography.Text className="text-muted">
                        Defina um código para o atalho, ele será usado para exibir a mensagem do atalho, como literalmente um atalho. Exemplo: /site
                    </Typography.Text>
                </Col>
                <Col md={24} style={{ marginBottom: 20 }}>
                    <Form.Item
                        name="content"
                        label="Mensagem"
                        rules={rules.content}
                        hasFeedback
                        style={{ marginBottom: 5 }}
                    >
                        <TextArea 
                            rows={6} 
                            placeholder="Digite a mensagem que será exibida no atalho"
                        />
                    </Form.Item>
                    <Typography.Text className="text-muted">
                        Digite a mensagem completa que será exibida ao digitar o atalho no chat.
                    </Typography.Text>
                </Col>
            </Form>
        </Modal>
    )
}