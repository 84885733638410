import { Button, Typography, Breadcrumb } from 'antd';
import { HomeOutlined, ContactsOutlined, TagFilled } from '@ant-design/icons';

import Flex from '../../../../components/flex';
import { PageHeaderAlt } from '../../../../components/page-header-alt';
import { TagList } from '../../../../components/lists/tags-list';
import { tagService } from '../../../../services/tag.service';

const TagListScreen = () => {

    const onAddNew = () => {
        tagService.dispatch.toggleModal('form', true);
    }

    return (
        <>
            <PageHeaderAlt 
                className="border-bottom"
                breadcrumb={
                    <Breadcrumb> 
                        <Breadcrumb.Item href="/">
                            <HomeOutlined />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <ContactsOutlined />
                            <a href="/tags">Tags</a>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            >
                <div className="container-fluid">
                    <Flex justifyContent="between" alignItems="center" className="py-4">
                        <>
                            <div>
                                <Typography.Title level={2}>Tags</Typography.Title>
                                <Typography.Paragraph type="secondary">
                                Aqui, você tem o poder de criar tags exclusivas e significativas que serão cuidadosamente atreladas aos seus leads, proporcionando uma visão mais profunda e organizada de suas interações.
                                </Typography.Paragraph>
                            </div>
                            
                            <Button size='small' type="primary" className="ml-2" onClick={onAddNew}>
                                <span> Nova tag </span>
                                <TagFilled />
                            </Button>
                        </>
                    </Flex>
                </div>
            </PageHeaderAlt>
            <div className={`my-4 container-fluid`}>
                <TagList />
            </div>
        </>
    )
}

export default TagListScreen;