import { init, Models, RematchDispatch, RematchRootState } from "@rematch/core"
import persistPlugin from '@rematch/persist';
import storage from "redux-persist/lib/storage";
import immerPlugin from "@rematch/immer";

// import_model
import { user } from './user.store';
import { bot } from './bot.store';
import { department } from './department.store';
import { lead } from './lead.store';
import { organization } from './organizaton.store';
import { campaign } from './campaign.store';
import { chat } from './chat.store';
import { attendance } from './attendance.store';
import { tag } from './tag.store';

const persistConfig = {
    key: 'tiger-core-persistor',
    storage,
    whitelist: ['user']
}

export const store = init<RootModel>({ 
    models: { 
        // use_model
        user,
        bot,
        department,
        lead,
        organization,
        campaign,
        chat,
        attendance,
        tag,
    }, 
    plugins: [persistPlugin(persistConfig), immerPlugin()],
});

export interface RootModel extends Models<RootModel> {
    // type_model
    user: typeof user,
    bot: typeof bot,
    department: typeof department,
    lead: typeof lead,
    organization: typeof organization,
    campaign: typeof campaign,
    chat: typeof chat,
    attendance: typeof attendance,
    tag: typeof tag,
};

export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>