import { RootModel, store } from '.';
import { createModel } from "@rematch/core";

import { PaginationFactory } from '../factory';
import { CampaignTypes } from '../types';

export interface CampaignStoreProps {
    campaign: CampaignTypes.Model,
    duplicated: boolean,
    pagination: PaginationFactory<CampaignTypes.Model>,
    loading: {
        fetchMany: boolean,
        form: boolean,
        destroy: boolean,
        resend: boolean,
        cancel: boolean,
        continue: boolean,
    },
    modal: {
        form: boolean,
        details: boolean,
    },
};

export const campaign = createModel<RootModel>()({
    state: {
        campaign: {} as CampaignTypes.Model,
        duplicated: false,
        pagination: {
            items: [] as CampaignTypes.Model[],
        } as PaginationFactory<CampaignTypes.Model>,
        loading: {
            fetchMany: false,
            form: false,
            destroy: false,
            resend: false,
            cancel: false,
            continue: false,
        },
        modal: {
            form: false,
            details: false,
        }
    },
    reducers: {
        setCampaign: (store, campaign: CampaignTypes.Model) => {
            store.campaign = campaign;
        },
        toggleLoading: (
            store, prop: keyof CampaignStoreProps['loading'], v: boolean) => {
                store.loading[prop] = v;
            },
        setPagination: 
            (store, pagination: CampaignStoreProps['pagination']) => {
                store.pagination = pagination;
            },
        addPaginationItem: (store, campaign: CampaignTypes.Model) => {
            store.pagination.items = [...store.pagination.items, campaign];
        },
        removePaginationItem: (store, id: number) => {
            store.pagination.items = store.pagination.items.filter(i => i.id !== id);
        },
        updatePaginationItem: (store, id: number, campaign: CampaignTypes.Model) => {
            store.pagination.items = store.pagination.items.map((i) => {
                if (i.id === id) {
                    return campaign;
                }

                return i;
            });
        },
        setDuplicated: (store) => {
            store.duplicated = true;
        },
        eraseFormModal: (store) => {
            store.modal.form = false;
            store.campaign = {} as CampaignTypes.Model;
            store.loading.form = false;
            store.duplicated = false;
        },
        toggleModal: (store, prop: keyof CampaignStoreProps['modal'], v: boolean) => {
            store.modal[prop] = v;
        },
    },
});
