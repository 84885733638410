import type { RootModel } from '.';
import { createModel } from "@rematch/core";

import { PaginationFactory } from '../factory';
import { LeadTypes } from '../types';
import { AttendanceTypes } from '../types/attendance.types';

export interface LeadStoreProps {
    lead: LeadTypes.Model,
    pagination: PaginationFactory<LeadTypes.Model>,
    loading: {
        fetchMany: boolean,
        form: boolean,
        destroy: boolean,
        getById: boolean,
        fetchAttendancesByLead: boolean,
        importLeads: boolean,
    },
    leadAttendancesPagination: PaginationFactory<AttendanceTypes.Model>,
    modal: {
        form: boolean,
        importLeads: boolean,
    },
};
export const lead = createModel<RootModel>()({
    state: {
        lead: {} as LeadTypes.Model,
        pagination: {
            items: [] as LeadTypes.Model[],
        } as PaginationFactory<LeadTypes.Model>,
        loading: {
            fetchMany: false,
            form: false,
            destroy: false,
            getById: false,
            fetchAttendancesByLead: false,
            importLeads: false,
        },
        modal: {
            form: false,
            importLeads: false,
        },
        leadAttendancesPagination: {
            items: [] as AttendanceTypes.Model[],
        } as PaginationFactory<AttendanceTypes.Model>
    },
    reducers: {
        setLeadAttendancesPagination: (store, pagination: LeadStoreProps['leadAttendancesPagination']) => {
            store.leadAttendancesPagination = pagination;
        },
        setLead: (store, lead: LeadTypes.Model) => {
            store.lead = lead;
        },
        toggleLoading: (
            store, prop: keyof LeadStoreProps['loading'], v: boolean) => {
                store.loading[prop] = v;
            },
        setPagination: 
            (store, pagination: LeadStoreProps['pagination']) => {
                store.pagination = pagination;
            },
        addPaginationItem: (store, lead: LeadTypes.Model) => {
            store.pagination.items = [...store.pagination.items, lead];
        },
        removePaginationItem: (store, id: number) => {
            store.pagination.items = store.pagination.items.filter(i => i.id !== id);
        },
        updatePaginationItem: (store, id: number, lead: LeadTypes.Model) => {
            store.pagination.items = store.pagination.items.map((i) => {
                if (i.id === id) {
                    return lead;
                }

                return i;
            });
        },
        eraseFormModal: (store) => {
            store.modal.form = false;
            store.lead = {} as LeadTypes.Model;
            store.loading.form = false;
        },
        toggleModal: (store, prop: keyof LeadStoreProps['modal'], v: boolean) => {
            store.modal[prop] = v;
        },
    },
});
