import { AxiosResponse } from "axios";
import { api, sanitalizeApiException } from "../http/http";
import { DefaultCallback, DefaultRes } from "../types";

export const permissionService = {

    list: async (callback?: DefaultCallback) => {
        try {
            const { data } = await api.get<
                DefaultRes<string[]>,
                AxiosResponse<DefaultRes<string[]>>
            >('/Permission/List');

            return data.result;
        } catch (error) {
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

};