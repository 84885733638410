import { AxiosResponse } from 'axios';
import qs from 'qs-stringify';
import { PaginationFactory, PaginatorFactory } from '../factory';
import queryStringify from 'qs-stringify';

import { api, sanitalizeApiException } from "../http/http";
import { store } from '../store';
import { DefaultCallback, DefaultRes, DepartmentTypes } from '../types';
import { AttendanceTypes } from '../types/attendance.types';

export const departmentService = {
    dispatch: store.dispatch.department,

    fetchAttendancesByDepartment: async (pagination: PaginatorFactory<AttendanceTypes.Filters>, departmentId: number, callback: DefaultCallback) => {
        try {
            departmentService.dispatch.toggleLoading('fetchAttendancesByDepartment', true);

            const qsfy = queryStringify({
                perPage: pagination.perPage,
                page: pagination?.page,
                filter: {
                    ...pagination?.filter,
                },
            });

            const { 
                data: {
                    isValid,
                    message,
                    result,
                }
            } = await api.get<
                DefaultRes<PaginationFactory<AttendanceTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<AttendanceTypes.Model>>>
            >(`/Attendances/ListAttendancesByDepartment/${departmentId}?${qsfy}`);

            departmentService.dispatch.toggleLoading('fetchAttendancesByDepartment', false);

            if (!isValid) {
                return callback(isValid, message[0]);
            }

            departmentService.dispatch.setDepartmentAttendancesPagination(result);

            return callback(isValid, message);

        } catch (error) {
            departmentService.dispatch.toggleLoading('fetchAttendancesByDepartment', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    destroy: async (departments: number[], callback: DefaultCallback) => {
        try {
            departmentService.dispatch.toggleLoading('destroy', true);

            const { 
                data,
            } = await api.post<
                DefaultRes<boolean>,
                AxiosResponse<DefaultRes<boolean>>
            >(`/Departments/Destroy`, { departments });

            if (!data.isValid) {
                departmentService.dispatch.toggleLoading('destroy', false);
                return callback(false, data?.message[0]);
            }

            await departmentService.getMany({ page: 1, perPage: 50 });
            departmentService.dispatch.toggleLoading('destroy', false);
            return callback(true, 'Departamento excluído com sucesso!');

        } catch (error) {
            departmentService.dispatch.toggleLoading('destroy', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    getMany: async (pagination: PaginatorFactory<DepartmentTypes.Filters>, callback?: DefaultCallback) => {
        try {
            departmentService.dispatch.toggleLoading('fetchMany', true);

            const qsfy = qs({
                perPage: pagination.perPage,
                page: pagination?.page,
                filter: {
                    ...pagination?.filter,
                },
            });

            const { 
                data: { 
                    result: { 
                        items,
                        page,
                        perPage,
                        total,
                    }, 
                isValid, 
                message 
            }} = await api.get<
                DefaultRes<PaginationFactory<DepartmentTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<DepartmentTypes.Model>>>
            >(`/Departments?${qsfy}`);
            
            if (!isValid) {
                departmentService.dispatch.toggleLoading('fetchMany', false);
                return callback && callback(false, message[0]);
            }
            
            departmentService.dispatch.setPagination({ items, page, perPage, total });

            departmentService.dispatch.toggleLoading('fetchMany', false);
            return callback && callback(true, message[0]);
        } catch (error) {
            departmentService.dispatch.toggleLoading('fetchMany', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    create: async (name: string, botId: number, callback: DefaultCallback) => {
        try {
            departmentService.dispatch.toggleLoading('form', true);

            const response = await api.post<
                DefaultRes<DepartmentTypes.Model>,
                AxiosResponse<DefaultRes<DepartmentTypes.Model>>
            >('/Departments', {
                name,
                botId,
            });
            
            if (!response.data.isValid) {
                departmentService.dispatch.toggleLoading('form', false);
                return callback(false, response.data?.message[0]);
            }

            departmentService.dispatch.addPaginationItem(response.data.result);
            departmentService.dispatch.toggleLoading('form', false);

            return callback(true, 'Departamento criado com sucesso!');

        } catch (error) {
            departmentService.dispatch.toggleLoading('form', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    update: async (id: number, botId: number, name: string, callback: DefaultCallback) => {
        try {
            departmentService.dispatch.toggleLoading('form', true);

            const response = await api.put<
                DefaultRes<DepartmentTypes.Model>,
                AxiosResponse<DefaultRes<DepartmentTypes.Model>>
            >(`/Departments/${id}`, {
                name,
                botId,
            });
            
            if (!response.data.isValid) {
                departmentService.dispatch.toggleLoading('form', false);
                return callback(false, response.data?.message[0]);
            }

            departmentService.dispatch.updatePaginationItem(id, response.data.result);
            departmentService.dispatch.toggleLoading('form', false);
            return callback(true, 'DEPARTMENT_UPDATED');

        } catch (error) {
            departmentService.dispatch.toggleLoading('form', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },
}