import React, { useEffect, useState } from 'react';
import { Button, Table, Card, Input, message, Tag, Space, Tooltip, Empty, TableProps } from 'antd';
import { SearchOutlined, RedoOutlined, CaretRightOutlined, InfoCircleOutlined, CopyOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Then, When } from 'react-if';
import { SorterResult } from 'antd/lib/table/interface';

import { RootState } from '../../../store';
import Flex from '../../flex';
import { campaignService } from '../../../services/campaigns.service';
import ConfirmActionModal from '../../confirm-action-modal';
import { CampaignTypes } from '../../../types';

export const CampaignList = () => {
    const { pagination: campaignPagination, loading } = useSelector((state: RootState) => state.campaign);
    const [selectedCampaign, setSelectedCampaign] = useState<CampaignTypes.Model>({} as CampaignTypes.Model);
    const [nameFilter, setNameFilter] = useState('');
    const [resendModal, setResendModal] = useState<boolean>(false);

    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });

    const [order, setOrder] = useState({
        orderBy: '',
        sortOrder: '',
    });

    const onResendPress = () => {
        setResendModal(true);
    }

    const onResendCancel = () => {
        setResendModal(false);
    }

    const onChangePagination = (page: number, perPage: number) => {
        setPagination({ page, perPage });
    }

    const onChange: TableProps<any>['onChange'] = async (pagination, filters, sorter, extra) => {
        const sort = sorter as SorterResult<any>
        setOrder({
            sortOrder: sort.order && sort.order === 'ascend' ? 'asc' : 'desc' || '',
            orderBy: String(sort.field) || 'name',
        });
    };

    const onResendConfirm = async () => {
        await campaignService.resend(selectedCampaign?.id, (isValid, msg) => {
            if (!isValid) {
                message.error(msg);
                return;
            }

            message.success(msg);
            onResendCancel();
        });
    }

    const onFilter = async (clear = false) => {
        if (clear) {
            setNameFilter('');
        }

        await campaignService.getMany({ ...pagination, filter: { name: clear ? '' : nameFilter, protocol: '' } });
    }

    useEffect(() => {
        campaignService.getMany({ ...pagination, sortOrder: order.sortOrder, orderBy: order.orderBy, filter: { name: nameFilter, protocol: '' } });
    }, [pagination, order]);

    return (
        <>
            <ConfirmActionModal
                title={'Reenviar campanha'}
                loading={loading.resend}
                visible={resendModal}
                contentSubtitle={'Caso reenvie, todos os leads dessa campanha receberão as mensagens novamente.'}
                onCancel={onResendCancel}
                onDelete={onResendConfirm}
            />
            <Card>
                <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
                    <>
                        <Flex className="mb-1" mobileFlex={false}>
                            <>
                                <div className="mr-md-6 mb-3">
                                    <Input
                                        value={nameFilter}
                                        onChange={e => setNameFilter(e.currentTarget.value)}
                                        placeholder="Pesquisar por nome"
                                        prefix={<SearchOutlined />}
                                    />
                                </div>
                            </>
                        </Flex>
                        <Flex flexDirection='row-reverse'>
                            <>
                                <Button type="primary" block onClick={async () => await onFilter(false)}>Filtrar</Button>
                                <Button type="default" className='mr-2' block onClick={async () => await onFilter(true)}>Limpar filtro</Button>
                            </>
                        </Flex>
                    </>
                </Flex>

                <div className="table-responsive">
                    <Table
                        columns={[
                            {
                                title: 'Campanha',
                                dataIndex: 'name',
                                defaultSortOrder: 'descend',
                                sortDirections: ['descend', 'ascend'],
                                sorter: true
                                // sorter: (a, b) => sorter(a.status, b.status),
                            },
                            {
                                title: 'Protocolo',
                                dataIndex: 'protocol',
                                sortDirections: ['descend', 'ascend'],
                                sorter: true
                            },
                            {
                                title: 'Status',
                                dataIndex: 'status',
                                sortDirections: ['descend', 'ascend'],
                                sorter: true,
                                render: (_, camp) => (
                                    <>
                                        <When condition={camp.status === 'done'}>
                                            <Then>
                                                <Tag color='green'>Conclúido</Tag>
                                            </Then>
                                        </When>
                                        <When condition={camp.status === 'scheduled'}>
                                            <Then>
                                                <Tag color='yellow'>Agendado</Tag>
                                            </Then>
                                        </When>
                                        <When condition={camp.status === 'executing'}>
                                            <Then>
                                                <Tag color='orange'>Executando...</Tag>
                                            </Then>
                                        </When>
                                        <When condition={camp.status === 'canceled'}>
                                            <Then>
                                                <Tag color='red'>Cancelado</Tag>
                                            </Then>
                                        </When>
                                    </>
                                )
                            },
                            {
                                title: 'Data do agendamento',
                                dataIndex: 'scheduled',
                                render: (_, camp) => camp.scheduled ? moment(camp.scheduled).format('DD/MM/YYYY HH:mm') : 'Sem agendamento',
                                sortDirections: ['descend', 'ascend'],
                                sorter: true
                            },
                            {
                                title: 'Data de criação',
                                dataIndex: 'createdAt',
                                render: (_, camp) => moment(camp.createdAt).format('DD/MM/YYYY HH:mm'),
                                sortDirections: ['descend', 'ascend'],
                                sorter: true
                            },
                            {
                                title: 'Última atualização',
                                dataIndex: 'updatedAt',
                                render: (_, camp) => moment(camp.updatedAt).format('DD/MM/YYYY HH:mm'),
                                sortDirections: ['descend', 'ascend'],
                                sorter: true
                            },
                            {
                                title: 'Ações',
                                dataIndex: 'actions',
                                render: (_, camp) => (
                                    <div className="text-center">
                                        <Space size="middle">
                                            <When condition={camp?.status === 'done' || camp?.status === 'canceled'}>
                                                <Tooltip placement="topLeft" title="Reenviar">
                                                    <RedoOutlined
                                                        onClick={() => {
                                                            setSelectedCampaign(camp);
                                                            onResendPress();
                                                        }}
                                                    />
                                                </Tooltip>
                                            </When>
                                            <When condition={camp?.status === 'executing'}>
                                                <Tooltip placement="topLeft" title={'Pausar'}>
                                                    <PauseCircleOutlined
                                                        onClick={() => {
                                                            campaignService.cancel(camp.id, (isValid, msg) => {
                                                                if (!isValid) {
                                                                    message.error(msg);
                                                                    return;
                                                                }

                                                                message.success(msg);

                                                                onFilter(false);
                                                            });
                                                        }}
                                                    />
                                                </Tooltip>
                                            </When>
                                            <When condition={camp?.status === 'canceled'}>
                                                <Tooltip placement="topLeft" title={'Pausar'}>
                                                    <CaretRightOutlined
                                                        onClick={() => {
                                                            campaignService.continue(camp.id, (isValid, msg) => {
                                                                if (!isValid) {
                                                                    message.error(msg);
                                                                    return;
                                                                }

                                                                message.success(msg);

                                                                onFilter(false);
                                                            });
                                                        }}
                                                    />
                                                </Tooltip>
                                            </When>
                                            <Tooltip placement="topLeft" title="Duplicar">
                                                <CopyOutlined onClick={() => {
                                                    campaignService.dispatch.setDuplicated();
                                                    campaignService.dispatch.setCampaign(camp);
                                                    campaignService.dispatch.toggleModal('form', true);

                                                }} />
                                            </Tooltip>
                                            <Tooltip placement="topLeft" title="Detalhes">
                                                <InfoCircleOutlined
                                                    onClick={() => {
                                                        campaignService.dispatch.setCampaign(camp);
                                                        campaignService.dispatch.toggleModal('details', true);
                                                    }}
                                                />
                                            </Tooltip>
                                        </Space>
                                    </div>
                                )
                            }
                        ]}
                        dataSource={campaignPagination.items}
                        loading={loading.fetchMany}
                        rowKey={'id'}
                        rowSelection={{
                            type: 'checkbox'
                        }}
                        locale={{
                            emptyText: <Empty className='mt-5 mb-5' image={Empty.PRESENTED_IMAGE_DEFAULT} description={'Aparentemente, você ainda não criou nenhuma campanha.'} />
                        }}
                        onChange={onChange}
                        showSorterTooltip={{ title: 'Clique para ordenar' }}
                        pagination={{
                            pageSize: pagination.perPage,
                            total: campaignPagination.total,
                            defaultCurrent: 1,
                            onChange: onChangePagination
                        }}
                    />
                </div>
            </Card>
        </>
    )
}