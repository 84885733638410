import { AxiosResponse } from 'axios';
import qs from 'qs-stringify';

import { api, sanitalizeApiException } from "../http/http";
import { PaginatorFactory, PaginationFactory } from "../factory";
import { DefaultCallback, DefaultRes } from "../types";
import { InstaceEventTypes } from "../types/instanceEvent.types";

export namespace InstanceEventService {
    export const getMany = async (
        pagination: PaginatorFactory<InstaceEventTypes.Filters>,
        callback?: DefaultCallback,
    ) => {
        try {
            const qsfy = qs({
                perPage: pagination.perPage,
                page: pagination?.page,
                filter: {
                    ...pagination?.filter,
                },
            });

            const { 
                data: { 
                    result: { 
                        items,
                        page,
                        perPage,
                        total,
                    }, 
                isValid, 
                message 
            }} = await api.get<
                DefaultRes<PaginationFactory<InstaceEventTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<InstaceEventTypes.Model>>>
            >(`/InstanceEvents?${qsfy}`);

            callback && callback(isValid, message[0]);

            return {
                items,
                page,
                perPage,
                total,
            }
        } catch (error) {
            callback && callback(false, sanitalizeApiException(error));
        }
    }   

    export const getEventHistory = async (
        uuid: string,
        callback?: DefaultCallback,
    ) => {
        try {
            const { 
                data: { 
                    result,
                    isValid,
                    message,
                },
            } = await api.get<
                DefaultRes<InstaceEventTypes.Model[]>,
                AxiosResponse<DefaultRes<InstaceEventTypes.Model[]>>
            >(`/InstanceEvents/getEventHistory/${uuid}`);

            callback && callback(isValid, message[0]);

            return result;
        } catch (error) {
            callback && callback(false, sanitalizeApiException(error));
        }
    }
}