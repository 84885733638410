import { AxiosResponse } from 'axios';
import { DefaultCallback, DefaultRes, UserTypes } from '../types';
import { api, sanitalizeApiException } from '../http/http';
import { RootState, store } from '../store';
import { PaginationFactory, PaginatorFactory } from '../factory';
import { AttendanceTypes } from '../types/attendance.types';
import queryStringify from 'qs-stringify';
import { AuthService } from './auth.service';
import { useSelector } from 'react-redux';

export namespace UserService {
    export const dispatch = store.dispatch.user;

    export const fetchAttendancesByUserId = async (pagination: PaginatorFactory<AttendanceTypes.Filters>, userId: number, callback: DefaultCallback) => {
        try {
            UserService.dispatch.toggleLoading('fetchUserAttendances', true);

            const qsfy = queryStringify({
                perPage: pagination.perPage,
                page: pagination?.page,
                filter: {
                    ...pagination?.filter,
                },
            });

            const { 
                data: {
                    isValid,
                    message,
                    result,
                }
            } = await api.get<
                DefaultRes<PaginationFactory<AttendanceTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<AttendanceTypes.Model>>>
            >(`/Attendances/ListAttendancesByUserId/${userId}?${qsfy}`);

            UserService.dispatch.toggleLoading('fetchUserAttendances', false);

            if (!isValid) {
                return callback(isValid, message[0]);
            }

            UserService.dispatch.setUserAttendancesPagination(result);

            return callback(isValid, message);

        } catch (error) {
            UserService.dispatch.toggleLoading('fetchUserAttendances', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    export const fetchSessionsByUserId = async (pagination: PaginatorFactory<{}>, userId: number, callback: DefaultCallback) => {
        try {
            UserService.dispatch.toggleLoading('fetchSessions', true);

            const qsfy = queryStringify({
                perPage: pagination.perPage,
                page: pagination?.page,
                filter: {
                    ...pagination?.filter,
                },
            });

            const { 
                data: {
                    isValid,
                    message,
                    result,
                }
            } = await api.get<
                DefaultRes<PaginationFactory<UserTypes.Session>>,
                AxiosResponse<DefaultRes<PaginationFactory<UserTypes.Session>>>
            >(`/Users/GetUserSessions/${userId}?${qsfy}`);

            UserService.dispatch.toggleLoading('fetchSessions', false);

            if (!isValid) {
                return callback(isValid, message[0]);
            }

            UserService.dispatch.setUserSessionsPagination(result);

            return callback(isValid, message);
        } catch(error) {
            UserService.dispatch.toggleLoading('fetchSessions', false);

            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    export const updatePassword = async (oldPassword: string, newPassword: string, user: UserTypes.Model, callback: DefaultCallback) => {
        try {
            UserService.dispatch.toggleLoading('updatePassword', true);

            const { 
                data: {
                    isValid,
                    message,
                }
            } = await api.put(`/Users/UpdatePassword`, {
                oldPassword,
                newPassword
            });

            UserService.dispatch.toggleLoading('updatePassword', false);
            

            if (!isValid) {
                return callback(isValid, message[0]);
            }

            const { dispatch } = AuthService; 

            dispatch.setAuthUserProps({...user, isFirstTime: false })
            
            return callback(isValid, 'Ok');

        } catch(error) {
            UserService.dispatch.toggleLoading('updatePassword', false);

            return callback && callback(false, sanitalizeApiException(error));
        }
    }

    export const updateProfile = async (data: UserTypes.UpdateProfile, callback: DefaultCallback) => {
        try {
            UserService.dispatch.toggleLoading('updateProfile', true);

            const { 
                data: {
                    isValid,
                    message,
                }
            } = await api.put(`/Users/UpdateProfile`, data);

            if (!isValid) {
                return callback(isValid, message[0]);
            }
            
            UserService.dispatch.toggleLoading('updateProfile', false);

            UserService.dispatch.setAuthUser(data)

            return callback(isValid, 'Ok');

        } catch(error) {
            UserService.dispatch.toggleLoading('updateProfile', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    }
    
}