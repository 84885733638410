import React, { useEffect, useState } from "react";
import { Modal, Alert, Col, Row, Typography, message } from "antd";
import { useSelector } from "react-redux";
import { If } from 'react-if';
import { motion } from "framer-motion";

import { botService } from "../../services/bot.service";
import { RootState } from "../../store";
import { ZapfyTypes } from '../../types';
import useInterval from "../../hooks/useInterval";

export const ModalConnectBot = () => {
    const [status, setStatus] = useState<null | ZapfyTypes.ConnectInstanceResult['result']>(null);
    const [fetchingStatus, setFetchingStatus] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { modal, bot, loading } = useSelector((state: RootState) => state.bot);
    
    const onClose = () => {
        botService.dispatch.toggleModal('connect', false);
        botService.dispatch.eraseFormModal();
    }

    const fetchAndSetStatus = async () => {
        setFetchingStatus(true);

        const result = await botService.connect(bot.id);

        setStatus(result!);

        setFetchingStatus(false);
    }

    useInterval(() => {
        fetchAndSetStatus();
    }, 10000);

    useEffect(() => {
        fetchAndSetStatus();
    }, [modal.connect]);

    useEffect(() => {
        if (status?.state === 'connected') {
            message.success('Seu celular foi conectado com sucesso ao robô 🤖');
            onClose();
        }
    }, [status]);

    return (
        <Modal
            width={800}
            title={'Conectar ao robô'}
            onCancel={onClose}
            open={modal.connect}
            okText={'Recarregar QR Code'}
            cancelText={'Cancelar'}
            onOk={async () => {
                await fetchAndSetStatus();
            }}
            confirmLoading={fetchingStatus}
        >
            <If condition={errorMessage !== null && !loading.form}>
                <motion.div 
                    initial={{ opacity: 0, marginBottom: 0 }} 
                    animate={{ 
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0 
                    }}
                > 
                    <Alert 
                        onClose={() => setErrorMessage(null)} 
                        type="error" 
                        showIcon
                        message={errorMessage}
                        closable
                    />
                </motion.div>
            </If>

            <Row>
                <Col md={14}>
                    <Typography.Title level={4} style={{ marginBottom: 20 }}>
                        Para se conectar ao robô siga o passo a passo:
                    </Typography.Title>

                    <Typography.Paragraph>
                        1. Abra o WhatsApp em seu celular
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        2. Toque em Menu opções ou Ajustes e selecione o WhatsApp Web
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        3. Aponte seu celular para esta tela para capturar o código
                    </Typography.Paragraph>
                </Col>
                <Col md={4}>
                    <img src={status?.qrCode} />
                </Col>
            </Row>

        </Modal>
    )
}