import React, { useState } from 'react';
import { TeamOutlined, MailOutlined, PhoneOutlined, IdcardOutlined, HomeOutlined, NumberOutlined, EnvironmentOutlined, PushpinOutlined, HeatMapOutlined } from '@ant-design/icons';
import { Button, Form, Input, Alert, message, Row, Col, Select } from "antd";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Rule } from 'antd/lib/form';
import { motion } from "framer-motion";
import { cnpj } from 'cpf-cnpj-validator';
import _ from 'lodash';

import { RootState } from './../../../store';
import { states } from '../../../constants/states.constants';
import { api } from '../../../http/http';
import { cepMask, cnpjMask, phoneMask } from '../../../helpers/masks';
import { organizationService } from '../../../services/organization.service';

export const OrganizationForm = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const { authUser } = useSelector((state: RootState) => state.user);
    const { loading } = useSelector((state: RootState) => state.organization);

    const [form] = Form.useForm();

    const onSubmit = async (values: any) => {
        if (!cnpj.isValid(values.taxpayer)) {
            message.error('CNPJ Inválido!')
            return;
        }
        values.taxpayer = values.taxpayer.replace(/[^\d]+/g, '');
        values.zipcode = values.zipcode.replace(/[^\d]+/g, '');

        await organizationService.setOrganizationInfo(values, authUser, (isValid, msg) => {
            if (!isValid) {
                message.error(msg)
                return;
            }

            navigate('/')
        })


    };

    const rules: Record<string, Rule[]> = {
        name: [
            {
                required: true,
                message: 'Informe o nome da empresa'
            },
        ],
        corporate_name: [
            {
                required: true,
                message: 'Informe a razão social da empresa'
            },
        ],
        taxpayer: [
            {
                required: true,
                message: 'Informe o CNPJ da empresa'
            },
        ],
        phone: [
            {
                required: true,
                message: 'Informe o telefone da empresa'
            },
        ],
        email: [
            {
                required: true,
                message: 'Informe o seu e-mail'
            },
            {
                type: 'email',
                message: 'E-mail inválido'
            }
        ],
        address: [
            {
                required: true,
                message: 'Informe o endereço'
            }
        ],
        address_number: [
            {
                required: true,
                message: 'Informe o número'
            }
        ],
        zipcode: [
            {
                required: true,
                message: 'Informe o CEP'
            }
        ],
        address_neighborhood: [
            {
                required: true,
                message: 'Informe o bairro'
            }
        ],
        address_city: [
            {
                required: true,
                message: 'Informe a cidade'
            }
        ],
        address_state: [
            {
                required: true,
                message: 'Informe o estado'
            }
        ],

    }

    const getAddressByZipCode = async (zip: string) => {
        zip = zip.replace(/[^\d]+/g, '');

        const response = await api.get(`/Helpers/GetAddress/${zip}`);

        if (response.data.isValid) {
            const { result } = response.data
            form.setFieldValue('city', result.localidade)
            form.setFieldsValue({
                address_city: result.localidade,
                address: result.logradouro,
                address_neighborhood: result.bairro,
                address_state: result.uf
            })
        }
    }

    return (
        <>
            <motion.div
                initial={{ opacity: 0, marginBottom: 0 }}
                animate={{
                    opacity: errorMessage ? 1 : 0,
                    marginBottom: errorMessage ? 20 : 0
                }}>
                <Alert type="error" showIcon message={errorMessage}></Alert>
            </motion.div>
            <Form
                form={form}
                layout="vertical"
                name="organization-form"
                onFinish={onSubmit}
            >
                <Form.Item
                    name="name"
                    label="Nome da Empresa"
                    rules={rules.name}
                    hasFeedback
                >
                    <Input prefix={<TeamOutlined className="text-primary" />} />
                </Form.Item>

                <Form.Item
                    name="corporate_name"
                    label="Razão Social"
                    rules={rules.corporate_name}
                    hasFeedback
                >
                    <Input prefix={<TeamOutlined className="text-primary" />} />
                </Form.Item>
                <Form.Item
                    name="taxpayer"
                    label="CNPJ"
                    rules={rules.taxpayer}
                    hasFeedback

                >
                    <Input prefix={<IdcardOutlined className="text-primary" />}
                        onChange={(e) => form.setFieldValue('taxpayer', cnpjMask(e.target.value))} />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="Email da Empresa"
                    rules={rules.email}
                    hasFeedback
                >
                    <Input prefix={<MailOutlined className="text-primary" />} />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="Telefone da Empresa"
                    rules={rules.phone}
                    hasFeedback
                >
                    <Input prefix={<PhoneOutlined className="text-primary" />}
                        onChange={(e) => form.setFieldValue('phone', phoneMask(e.target.value))} />
                </Form.Item>
                <Row>
                    <Col xl={9}>
                        <Form.Item
                            name="zipcode"
                            label="CEP"
                            rules={rules.zipcode}
                            hasFeedback
                        >
                            <Input prefix={<EnvironmentOutlined className="text-primary" />}
                                onChange={async (e) => {
                                    form.setFieldValue('zipcode', cepMask(e.target.value))

                                    if (e.target.value.length >= 8 && e.target.value.length <= 10) {
                                        await getAddressByZipCode(e.target.value)
                                    }
                                }} />
                        </Form.Item>
                    </Col>
                    <Col xl={14} style={{ marginLeft: 12 }}>
                        <Form.Item
                            name="address_complement"
                            label="Complemento"
                            hasFeedback
                        >
                            <Input prefix={<PushpinOutlined className="text-primary" />} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name="address"
                    label="Endereço"
                    rules={rules.address}
                    hasFeedback
                >
                    <Input prefix={<HomeOutlined className="text-primary" />} />
                </Form.Item>
                <Row>
                    <Col xl={15}>
                        <Form.Item
                            name="address_neighborhood"
                            label="Bairro"
                            rules={rules.address_neighborhood}
                            hasFeedback
                        >
                            <Input prefix={<HeatMapOutlined className="text-primary" />} />
                        </Form.Item>
                    </Col>
                    <Col xl={8} style={{ marginLeft: 12 }}>
                        <Form.Item
                            name="address_number"
                            label="Número"
                            rules={rules.address_number}
                            hasFeedback
                        >
                            <Input prefix={<NumberOutlined className="text-primary" />} />
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Col xl={15} >
                        <Form.Item
                            name="address_city"
                            label="Cidade"
                            rules={rules.address_city}
                            hasFeedback
                        >
                            <Input prefix={<PushpinOutlined className="text-primary" />} />
                        </Form.Item>
                    </Col>
                    <Col xl={8} style={{ marginLeft: 12 }}>
                        <Form.Item
                            name="address_state"
                            label="Estado"
                        >
                            <Select
                                placeholder="Selecioe o Estado"
                                options={states?.map((state) => ({
                                    label: state.nome,
                                    value: state.sigla,
                                }))}
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block loading={loading.setOrganization}>
                        Confirmar
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}