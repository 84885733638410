import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Alert, Modal, Select, message } from "antd";
import { If } from "react-if";
import _ from "lodash";
import Form, { Rule } from "antd/lib/form";

import { RootState } from "../../../store";
import { ChatService } from "../../../services/chat.service";
import { useGenericQuery } from "../../../hooks/useGenericQuery";
import { DepartmentTypes } from "../../../types";
import { useGenericMutation } from "../../../hooks/useGenericMutation";
import { sanitalizeApiException } from "../../../http/http";

export const TransferAttendanceForm = () => {
    const { selectedAttendanceId } = useSelector((state: RootState) => state.chat);
    const { modal } = useSelector((state: RootState) => state.chat);
    const { data: departments } = useGenericQuery<DepartmentTypes.Model[]>('getDepartmentsWithUsers', 'Organizations/GetDepartmentsWithUsers');
    const { isLoading, mutateAsync } = useGenericMutation<{ departmentId: number, userId: number, attendanceId: number }, boolean>('transferAttendance', 'Attendances/TransferAttendance');

    const [form] = Form.useForm<{
        userId: number,
        departmentId: number,
    }>();

    const departmentIdValue = Form.useWatch('departmentId', form);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const users = departments
        ?.map((department) => department.usersxdepartments)
        ?.flat()
        ?.filter((user) => user.departmentId === departmentIdValue);

    const rules: Record<string, Rule[]> = {
        userId: [
            { 
                required: true,
                message: 'Por favor, selecione o atendente',
            },
        ],
        departmentId: [
            { 
                required: true,
                message: 'Por favor, selecione o departamento',
            },
        ],
    }

    const onClose = () => {
        ChatService.dispatch.toggleModal('transferAttendance', false);
        form.resetFields();
    }

    useEffect(() => {
        form.setFieldValue('userId', null);
    }, [departmentIdValue]);
    
    return (
        <Modal
            title={'Transferir atendimento'}
            open={modal.transferAttendance}
            onCancel={onClose}
            okText={'Confirmar'}
            cancelText={'Cancelar'}
            confirmLoading={isLoading}
            onOk={() => form.submit()}
            
        >
            <If condition={errorMessage !== null && !isLoading}>
                <motion.div 
                    initial={{ opacity: 0, marginBottom: 0 }} 
                    animate={{ 
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0 
                    }}
                > 
                    <Alert 
                        onClose={() => setErrorMessage(null)} 
                        type="error" 
                        showIcon
                        message={errorMessage}
                        closable
                    />
                </motion.div>
            </If>

            <Form
                layout="vertical"
                form={form}
                onFinish={async ({ departmentId, userId }) => {
                    await mutateAsync({ departmentId, userId, attendanceId: selectedAttendanceId }, {
                        onError: (error) => {
                            const errorMsg = sanitalizeApiException(error);

                            message.error(errorMsg);

                            setErrorMessage(errorMsg);

                            onClose();
                        },
                        onSuccess: async () => {
                            message.success('Atendimento transferido com sucesso!');

                            onClose();

                            ChatService.dispatch.setSelectedAttendanceId(0);

                            await ChatService.getChatAttendancesByUser((isValid, msg) => {});
                        }
                    });
                }}  
            >
                <Form.Item
                    name="departmentId"
                    rules={rules.departmentId}
                    label="Departamento"
                    required
                >   
                    <Select 
                        placeholder="Selecione um departamento"
                        options={departments?.map((department) => ({
                            label: department.name,
                            value: department.id,
                        }))}
                    />
                </Form.Item>

                <Form.Item
                    name="userId"
                    rules={rules.UserId}
                    label="Atendente"
                    required
                >   
                    <Select 
                        placeholder="Selecione um atendente"
                        options={users?.map((user) => ({
                            label: user?.user?.name,
                            value: user.user.id,
                        }))}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}