import { Layout, Modal } from "antd";
import { AttendanceTypes } from "../../types/attendance.types";
import { ChatContent } from "../chat/chat-content";
import { ChatService } from "../../services/chat.service";

interface Props {
    selectedAttendance: AttendanceTypes.Model,
    visible: boolean,
    onClose: () => void,
}

export const ModalChatViewer: React.FC<Props> = ({ selectedAttendance, visible, onClose }) => {

    return (
        <Modal
            title={`Atendimento (#${selectedAttendance?.protocol})`}
            open={visible}
            onCancel={onClose}
            width={650}
            footer={null}
            style={{ maxHeight: 350 }}
        >
            <Layout className="chat" style={{ maxHeight: 500 }}>
                <Layout>
                    <ChatContent selectedAttendance={selectedAttendance} />
                </Layout>
            </Layout>
        </Modal>
    )
}