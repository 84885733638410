export const modulePermissions = {
    bots: {
        CREATE_BOT: 'CREATE_BOT',
        UPDATE_BOT: 'UPDATE_BOT',
        GET_MANY_BOT: 'GET_MANY_BOT',
        GET_BOT_BY_ID: 'GET_BOT_BY_ID',
        DESTROY_BOT: 'DESTROY_BOT',
    },
    leads: {
        GET_LEAD_BY_ID: 'GET_LEAD_BY_ID',
        GET_MANY_LEAD: 'GET_MANY_LEAD',
        CREATE_LEAD: 'CREATE_LEAD',
        UPDATE_LEAD: 'UPDATE_LEAD',
        DESTROY_LEAD: 'DESTROY_LEAD',
    },
    departments: {
        DEPARTMENT_CREATE: 'DEPARTMENT_CREATE',
        DEPARTMENT_UPDATE: 'DEPARTMENT_UPDATE',
        DEPARTMENT_DELETE: 'DEPARTMENT_DELETE',
        DEPARTMENT_GET_MANY: 'DEPARTMENT_GET_MANY',
    }
}

export const flagsLabelText: Record<string, string> = {
    ADMIN: 'Administrador',
    CHAT: 'Operador de Chat',
    TEST_PERMISSION: 'Testar Permissão',
    DEPARTMENT_CREATE: 'Criar departamento',
    DEPARTMENT_UPDATE: 'Atualizar departamento',
    DEPARTMENT_DELETE: 'Excluir departamento',
    ORGANIZATION_ADD_USER: 'Adicionar Usuário à Organização',
    ORGANIZATION_UPDATE_USER: 'Atualizar Usuário da Organização',
    ADD_PERMISSION_TO_USER: 'Adicionar Permissão ao Usuário',
    UPDATE_USER_PASSWORD: 'Atualizar Senha do Usuário',
    GET_EVENT_BY_ID: 'Obter Evento por ID',
    GET_MANY_EVENT: 'Obter Muitos Eventos',
    CREATE_EVENT: 'Criar Evento',
    UPDATE_EVENT: 'Atualizar Evento',
    DESTROY_EVENT: 'Excluir Evento',
    RECEIVE_WEBHOOK_PUSH: 'Receber Notificação Webhook',
    GET_LEAD_BY_ID: 'Visualizar lead',
    GET_MANY_LEAD: 'Visualizar lista de leads',
    CREATE_LEAD: 'Criar lead',
    UPDATE_LEAD: 'Atualizar lead',
    DESTROY_LEAD: 'Excluir lead',
    GET_BOT_BY_ID: 'Visualizar robô',
    GET_MANY_BOT: 'Listar robôs',
    CREATE_BOT: 'Criar robô',
    UPDATE_BOT: 'Atualizar robô',
    DESTROY_BOT: 'Excluir robô',
    CREATE_FUNNEL: 'Criar Funil',
    UPDATE_FUNNEL: 'Atualizar Funil',
    GET_MANY_FUNNEL: 'Obter Muitos Funis',
    GET_FUNNEL_BY_ID: 'Obter Funil por ID',
    DESTROY_FUNNEL: 'Excluir Funil',
    MANAGE_FUNNEL_LEADS: 'Gerenciar Leads do Funil',
    GET_FUNNEL_LEADS: 'Obter Leads do Funil',
    DEPARTMENT_GET_MANY: 'Visualizar lista de departamentos',
    METRICS_DASHBOARD: 'Painel de Métricas',
};
