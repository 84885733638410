import { Button, Dropdown, Upload, Form, Input, InputRef, Popover, Row, Tag, Typography } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { 
	SendOutlined, 
	PaperClipOutlined, 
	SmileOutlined,
    AudioOutlined,
    FilePdfOutlined,
    FileImageOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import EmojiPicker from "emoji-picker-react";
import { AudioRecorder } from 'react-audio-voice-recorder';

import { RootState } from "../../store";
import { ChatService } from "../../services/chat.service";
import { AttendanceTypes } from "../../types/attendance.types";
import { blobToBase64 } from "../../helpers/blob-to-base64";
import { fileToBase64 } from "../../helpers/file-to-base64";

interface Props {
    scrollbars: React.MutableRefObject<Scrollbars | undefined>
    inputRef: React.MutableRefObject<InputRef | undefined>
    selectedAttendance: AttendanceTypes.Model
}

export const ChatFooter: React.FC<Props> = ({ selectedAttendance, scrollbars, inputRef }) => {
    const { selectedAttendanceId, chatShortcutPagination, loading } = useSelector((state: RootState) => state.chat);
    const { authUser } = useSelector((state: RootState) => state.user);
    const [form] = Form.useForm<{ text: string }>();
    const [showShortcuts, setShowShortcuts] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });

    const onChangePagination = (page: number, perPage: number) => {
        setPagination({ page, perPage });
    }

    const renderShortcutOptions = () => {
        return chatShortcutPagination?.items?.map((shortcut, i) => ({ 
            key: String(i +1), 
            label: 
                <Row>
                    <Tag slot="6" color="green">/{shortcut.command}</Tag>
                    <Typography.Text >
                        {shortcut.content}
                    </Typography.Text>
                </Row>, 
            onClick: () => {
                setShowShortcuts(false);
                form.setFieldValue('text', shortcut.content);
            }
        }));
    }

    const onSendText = async (text: string) => {
        if (text && selectedAttendanceId) {
            
            await ChatService.sendMessage(`*${authUser?.name}:*\n${text}`, selectedAttendanceId, 'text', undefined, (isValid, msg) => {
                form.resetFields();
                scrollbars?.current!.scrollToBottom();
            });
        }
    }

    const onSendMediaMessage = async (base64: string, type: string) => {
        await ChatService.sendMessage(`*${authUser?.name}:*\n`, selectedAttendanceId, type, base64, (isValid, msg) => {
            scrollbars?.current!.scrollToBottom();
        });
    }

    const actionDisabled = selectedAttendance?.status === AttendanceTypes.Status.FINISHED || 
        selectedAttendance?.status === AttendanceTypes.Status.AWAITING ||
        selectedAttendance?.status as string === 'AWAITING_SELECT_DEPARTMENT';

    useEffect(() => {
        (async () => {
            await ChatService.fetchChatShortcuts(
                { 
                    ...pagination, 
                },
                (isValid, msg) => {}
            );
        })();
    }, [pagination, showShortcuts]);   

    return (
        <Footer style={{ background: 'white' }}>   
            <Dropdown 
                placement="topLeft" 
                menu={{ 
                    items: renderShortcutOptions(),
                    style: {
                        maxHeight: 200,
                        overflowY: 'scroll',
                    },
                    onScroll: (e: any) => {
                        const bottom = e.target.scrollHeight - e.target.scrollTop - 10 < e.target.clientHeight;
                        if (bottom && chatShortcutPagination.total !== pagination.perPage) {
                            onChangePagination(pagination.page, pagination.perPage + 5); 
                        }
                    }
                }} 
                open={showShortcuts}
            >
                <Form 
                    form={form} 
                    name="chat" 
                    className="w-100" 
                    onFinish={async (values) => {
                        await onSendText(values.text);
                    }}
                >
                    <Form.Item name="text" className="mb-0">
                        <Input 
                            ref={inputRef as any}
                            autoComplete="off" 
                            placeholder="Digite a sua mensagem..."
                            style={{ borderRadius: 0 }}
                            disabled={actionDisabled || loading.sendMessage}
                            onChange={(e) => {
                                if (e.currentTarget.value[0] === '/') {
                                    setShowShortcuts(true);
                                } else {
                                    if (showShortcuts === true) {
                                        setShowShortcuts(false);
                                    }
                                }
                            }}
                            prefix={
                                !actionDisabled &&
                                <div className="d-flex align-items-center">
                                    <a className="text-dark font-size-lg mr-2" >
                                        <Popover style={{ margin: 0 }} placement="topLeft" content={<EmojiPicker onEmojiClick={(emoji) => form.setFieldValue('text', form.getFieldValue('text') || '' + emoji?.emoji)} />}>
                                            <SmileOutlined disabled={actionDisabled} /> 
                                        </Popover>
                                    </a>
                                </div>
                            }
                            suffix={
                                <div className="d-flex align-items-center">
                                    {!actionDisabled &&
                                        <a className="text-dark font-size-lg mr-2">
                                            <Dropdown 
                                                disabled={loading.sendMessage}
                                                menu={{ 
                                                    items: [
                                                        {
                                                            key: '1',
                                                            label: (
                                                                <Upload 
                                                                    accept="image/*"
                                                                    previewFile={undefined}
                                                                    customRequest={async (options) => {
                                                                        const b64 = await fileToBase64(options.file as Blob); 
                                                                        await onSendMediaMessage(b64, 'image');
                                                                    }}
                                                                    progress={undefined}
                                                                    maxCount={1}
                                                                    itemRender={undefined}
                                                                    showUploadList={false}

                                                                >
                                                                    Enviar imagem
                                                                </Upload>
                                                            ),
                                                            icon: <FileImageOutlined />,
                                                        },
                                                        {
                                                            key: '2',
                                                            label: (
                                                                <Upload 
                                                                    accept="video/mp4,video/x-m4v,video/*"
                                                                    previewFile={undefined}
                                                                    customRequest={async (options) => {
                                                                        const b64 = await fileToBase64(options.file as Blob); 
                                                                        await onSendMediaMessage(b64, 'video');
                                                                    }}
                                                                    progress={undefined}
                                                                    maxCount={1}
                                                                    itemRender={undefined}
                                                                    showUploadList={false}
                                                                >
                                                                    Enviar vídeo
                                                                </Upload>
                                                            ),
                                                            icon: <VideoCameraOutlined />,
                                                        },
                                                        {
                                                            key: '3',
                                                            label: (
                                                                <Upload 
                                                                    accept=".pdf"
                                                                    previewFile={undefined}
                                                                    customRequest={async (options) => {
                                                                        const b64 = await fileToBase64(options.file as Blob); 
                                                                        console.log('b64 file', b64)
                                                                        await onSendMediaMessage(b64, 'document');
                                                                    }}
                                                                    progress={undefined}
                                                                    maxCount={1}
                                                                    itemRender={undefined}
                                                                    showUploadList={false}
                                                                >
                                                                    Enviar PDF
                                                                </Upload>
                                                            ),
                                                            icon: <FilePdfOutlined />,
                                                        },
                                                        {
                                                            key: '4',
                                                            label: (
                                                                <Upload 
                                                                    accept=".mp3,audio/*"
                                                                    previewFile={undefined}
                                                                    customRequest={async (options) => {
                                                                        const b64 = await fileToBase64(options.file as Blob); 
                                                                        await onSendMediaMessage(b64, 'audio');
                                                                    }}
                                                                    progress={undefined}
                                                                    maxCount={1}
                                                                    itemRender={undefined}
                                                                    showUploadList={false}
                                                                >
                                                                    Enviar aúdio
                                                                </Upload>
                                                            ),
                                                            icon: <AudioOutlined />
                                                        },
                                                    ] 
                                                }} 
                                                placement="topRight"
                                            >
                                                <PaperClipOutlined disabled={actionDisabled} />
                                            </Dropdown>
                                        </a>    
                                    }
                                    <a className="text-dark font-size-lg mr-2">
                                        {!actionDisabled &&
                                            <AudioRecorder 
                                                onRecordingComplete={async (blob) => {
                                                    const b64 = await blobToBase64(blob);
                                                    await onSendMediaMessage(b64, 'audio');
                                                }}
                                                classes={{
                                                    
                                                    AudioRecorderClass: 'bg-transparent'
                                                }}
                                            />
                                        }
                                    </a>    
                                    
                                    <Button disabled={actionDisabled} loading={loading.sendMessage} shape="circle" type="primary" size="small" htmlType="submit">
                                        {!loading.sendMessage && <SendOutlined />}
                                    </Button>
                                </div>
                            }
                        />
                    </Form.Item>
                </Form>
            </Dropdown>
        </Footer>
    )
}