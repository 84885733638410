
import React, { useCallback, useEffect, useState } from "react";
import { Modal, Form, Input, message, Alert, AutoComplete, Typography } from "antd";
import { useSelector } from "react-redux";
import { If } from 'react-if';
import { motion } from "framer-motion";
import { Rule } from "antd/lib/form";

import { leadService } from "../../../services/lead.service";
import { RootState } from "../../../store";
import { LeadTypes } from "../../../types";
import { Space, Tag } from 'antd';
import { tagService } from "../../../services/tag.service";

export const LeadForm = () => {
    const [form] = Form.useForm<LeadTypes.Form>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { modal, lead, loading } = useSelector((state: RootState) => state.lead);
    const { pagination: tagPagination, loading: loadTags } = useSelector((state: RootState) => state.tag);
    const [options, setOptions] = useState<{ value: string, key: number }[]>([]);
    const [selecteds, setSelecteds] = useState<{ value: string, key: number }[]>([]);
    const [onSelectTag, setOnSelectTag] = useState<boolean>(false);
    const isFromEdit = modal.form && lead?.id;

    const onSelect = useCallback((data: string, key: any) => {
        if(selecteds.length >= 10) {
            setErrorMessage('Você atingiu o limite máximo de tags')
            return;
        }
        
        setOnSelectTag(true);
        console.log(selecteds.map(e => e.key).indexOf(key.key));
        if(selecteds.map(e => e.key).indexOf(key.key) === -1) {
            setSelecteds([...selecteds, { value: data, key: key.key }]);
        }
        setOptions([]);
        const interval = setTimeout(() => {
            setOnSelectTag(false);
          }, 500);
          return () => clearTimeout(interval);
    }, [selecteds, setSelecteds, setOnSelectTag, setOptions]);


    const removeTag = useCallback((e: number) => {
        const toRemove = selecteds.findIndex(objeto => objeto.key === e); 
        if (toRemove !== -1) {
             selecteds.splice(toRemove, 1);
            setSelecteds(selecteds);
          }
    }, [selecteds, setSelecteds]);

    const onSearch = async (searchText: string) => {
        console.log('OnSearhc', searchText);
        await tagService.getMany({
            page: 1,
            perPage: 100, filter: { name : searchText ?? '' } });
    };

    useEffect(() => {
        let data: {key: number, value: string }[] = [];
 
        tagPagination.items.forEach(e => {
            data.push({
                key: e.id,
                value: e.name,
            })
        });
        setOptions(data);
    }, [tagPagination])


    const onClose = () => {
        leadService.dispatch.eraseFormModal();
        setSelecteds([]);
    }

    const rules: Record<string, Rule[]> = {
        name: [
            { 
                required: true,
                message: 'Informe o nome completo'
            },
        ],
        contact: [
            { 
                required: true,
                message: 'Informe o contato'
            },
        ],
    }

    const onSubmit = async (
        name: string,
        contact: string,
    ) => {
        setErrorMessage(null);
        if (isFromEdit) {
            await leadService.update(lead?.id!, name, contact, (isValid, msg) => {
                if (!isValid) {
                    setErrorMessage(msg);
                    return;
                }

                form.resetFields();
                message.success(msg);
                onClose();
            }, selecteds.map(e => e.key));
            return;
        }

        await leadService.create(name, contact, (isValid, msg) => {
            if (!isValid) {
                setErrorMessage(msg);
                return;
            }
            
            form.resetFields();
            message.success(msg);
            onClose();
        }, selecteds.map(e => e.key));
    }

    useEffect(() => {
        if (isFromEdit) {
            form.setFieldsValue({
                name: lead.name!,
                contact: lead.contact,
            });

            setSelecteds(lead.tags ? lead.tags?.map(e => { return {key: e.id, value: e.name}}) : [])
        } 

        return () => {
            setErrorMessage(null);
        }

    }, [modal.form]);

    if (!modal.form) return null;

    return (
        <Modal
            title={isFromEdit ? 'Editar Lead' : 'Novo Lead'}
            onCancel={onClose}
            open={modal.form}
            okText={'Salvar alterações'}
            cancelText={'Cancelar'}
            onOk={() => {
                form.submit();
            }}
            confirmLoading={loading.form}
        >
            <If condition={errorMessage !== null && !loading.form}>
                <motion.div 
                    initial={{ opacity: 0, marginBottom: 0 }} 
                    animate={{ 
                        opacity: errorMessage ? 1 : 0,
                        marginBottom: errorMessage ? 20 : 0 
                    }}
                > 
                    <Alert 
                        onClose={() => setErrorMessage(null)} 
                        type="error" 
                        showIcon
                        message={errorMessage}
                        closable
                    />
                </motion.div>
            </If>
            <Form
                layout="vertical"  
                form={form}
                onFinish={async (values) => await onSubmit(values.name, values.contact)}
            >
                <Form.Item 
                    name="name" 
                    label="Nome" 
                    rules={rules.name}
                    hasFeedback
                >
                    <Input placeholder="Digite o nome completo do lead" />
                </Form.Item>
                <Form.Item 
                    name="contact" 
                    label="WhatsApp" 
                    rules={rules.contact}
                    hasFeedback
                >
                    <Input placeholder="Digite o número de telefone do lead" />
                </Form.Item>

            <div style={{
                    //  border: '1px solid black'
                     }}>
                    <Typography.Text> Tags </Typography.Text>
                    <AutoComplete
                        options={options}
                        style={{ width: '100%', marginBottom: 20, marginTop: 10,}}
                        onSelect={(value, key) => onSelect(value, key)}
                        onSearch={onSearch}
                        value={onSelectTag ? '' : undefined}
                        placeholder="Procurar tags"
                    />
                    {
                        selecteds && 
                        <Space size={[0, 10]} wrap>
                        {
                            selecteds?.map(e => (
                                <Tag closable onClose={() => removeTag(e.key)}>
                                    {e.value}
                                </Tag> 
                            ))
                        }
                    </Space>
                    }
                 
                </div> 
            </Form>

         
        </Modal>
    )
}

