import React from 'react';
import { Menu, Dropdown, Avatar, message, Button } from 'antd';
import {
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { uniqueId } from 'lodash';
import { useSelector } from 'react-redux';

import Icon from '../../../components/icon';
import { RootState, store } from '../../../store';
import { AuthService } from '../../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../http/http';

const menuItem = [
  {
    title: 'Minha Conta',
    icon: UserOutlined,
    path: '/my-account',
  },
];

const NavProfile: React.FC = () => {
  const { authUser } = useSelector((state: RootState) => state.user);
  const dispatch = store.dispatch.user;
  const navigate = useNavigate();

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="pl-3">
            <h4 className="mb-0"> {authUser?.name}</h4>
            <span className="text-muted">
              {authUser?.email}
            </span>
          </div>
        </div>
      </div>
      <div>
        <Menu>
          {menuItem.map((el, i) => (
            <Menu.Item key={1}>
              <a href={el.path}>
                <Icon className="mr-3" type={el.icon} />
                <span className="font-weight-normal">
                  {el.title}
                </span>
              </a>

            </Menu.Item>
          ))}
          <Menu.Item key={2} onClick={async () => {
            await api.post('/Auth/Logout');
            dispatch.destroySession();
            localStorage.removeItem('persist:tiger-core-persistor');
            message.success('Você foi desconectado com sucesso, volte em breve, viu? 😊');
            navigate('/login');
          }}>
            <Icon className='mr-3' type={LogoutOutlined} />
            <span className="font-weight-normal">
              Sair
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown overlay={profileMenu} trigger={['click']}>
      <Menu>
        <Menu.Item style={{ height: '100%'}}> 
          <Avatar shape="square"
            style={{
                backgroundColor: 'Highlight',
                verticalAlign: 'middle',
                fontSize: 20
            }} size={40}>
            {authUser?.name[0]}
          </Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
