import { BotTypes } from "./bot.types";

export namespace InstaceEventTypes {
    export type Filters = Pick<Model, 'key' | 'identity' | 'identityId'> & { excludeKey: Key };

    export enum Key {
        CAMPAIGN_MESSAGE_AWAITING = 'CAMPAIGN_MESSAGE_AWAITING',
        CAMPAIGN_MESSAGE_UPDATE = 'CAMPAIGN_MESSAGE_UPDATE',
        CAMPAIGN_MESSAGE_SENT   = 'CAMPAIGN_MESSAGE_SENT',
        CAMPAIGN_MESSAGE_ERRORED = 'CAMPAIGN_MESSAGE_ERRORED',
    }

    export type Model = {
        id: number
        instanceId: number
        organizationId: number
        content: Object | null
        key: string
        uuid: string
        rawWebhook: Object | null
        identityId: number
        identity: string | null
        createdAt: Date
        updatedAt: Date
        bot: BotTypes.Model
    }
}