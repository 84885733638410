export const exceptions: Record<string, string> = {
    EMAIL_OR_PASSWORD_INVALID: 'Email ou senha incorreto!',
    USER_NOT_FOUND_WITH_THIS_EMAIL: 'Não há nenhum usuário cadastrado com esse e-mail.',
    USER_NOT_FOUND: 'Não há nenhum usuário cadastrado com esse e-mail.',
    WHITE_LABEL_NOT_FOUND: 'Whitelabel não identificado, contate um administrador do sistema.',
    EMAIL_ALREADY_IN_USE: 'Já existe um usuário cadastrado com esse e-mail.',
    INVALID_WHITE_LABEL: 'Whitelabel não identificado, contate um administrador do sistema.',
    INVALID_JWT_TOKEN: 'Sessão inválida.',
    E_EXPIRED_TOKEN: 'Sessão expirada, conecte novamente.',
    RESET_PASSWORD_TOKEN_EXPIRED: 'Token de alteração de senha expirado, solicite uma nova alteração de senha.',
    ATTENDANCE_NOT_FOUND: 'Atendimento não encontrado.',
    BOT_NOT_FOUND: 'Bot não encontrado.',
    BOT_NOT_CONNECTED: 'O bot selecionado deve estar conectado para realizar essa ação.',
    FAILED_TO_SEND_ATTENDANCE_MESSAGE: 'Ocorreu um erro ao tentar enviar uma mensagem, tente novamente.',
    NOT_FOUND: 'Registro não encontrado.',
    NO_FREE_INSTANCE_AVAILABLE: 'Não foi possível criar um robô devido ao nível de sobrecarga no sistema, contate um administrador.',
    CAMPAIGN_SCHEDULE_INVALID_DATE: 'A data informada para o agendamento da campanha está incorreta.',
    CAMPAIGN_SCHEDULE_DATE_MUST_BE_GREATER: 'A data de agendamento da campanha deve ser maior que a data atual.',
    CAMPAIGN_NO_CONNECTED_BOTS: 'Todos os bots selecionados para esta campanha estão desconectados, é necessário que tenha ao menos um bot conectado para realizar o disparo.',
    CAMPAIGN_EXECUTING_OR_SCHEDULED: 'Aparentemente a campanha que você está tentando reenviar ainda está em execução. Aguarde o fim da execução para poder reenvia-la.',
    CAMPAIGN_DONE_OR_CANCELED: 'A campanha que você está tentando cancelar já foi finalizada ou cancelada.',
    CAMPAIGN_NOT_FOUND: 'Campanha não encontrada.',
    DEPARTMENT_ALREADY_EXIST: 'Já existe um departamento criado com esse nome.',
    DEPARTMENT_NOT_FOUND_ON_UPDATE: 'O departamento que você está tentando editar não existe.',
    SOME_DEPARTMENT_NOT_FOUND_ON_DELETE: 'Um dos departamentos selecionados que você está tentando excluir não existe.',
    SOME_DEPARTMENT_NOT_EXIST_ON_ADD_USER: 'Um dos departamentos selecionados para atribuir ao usuário não existe.',
    USER_NOT_EXIST: 'Usuário não encontrado.',
    USER_EXIST_ORGANIZATION: 'Esse usuário já faz parte da sua empresa.',
    ORG_NOT_FOUND: 'Empresa não encontrada.',
    NOT_MATCH_PASSWORD: 'As senhas não coincidem.',
    UNKNOWN: 'Ocorreu um erro desconhecido, contate um administrador do sistema.',
    INVALID_PHONE_NUMBER: 'O telefone informado é inválido',
    INVALID_ZIPCODE: 'O CEP informado é inválido',
    TAXPAYER_INVALID: 'O CPF informado é inválido',
    password_MIN_LENGHT_8: 'A senha deve conter no mínimo 8 caracteres.',
    TAXPAYER_ORG_ALREADY_EXIST: 'Já existe uma empresa cadastrada com esse CNPJ.'
};