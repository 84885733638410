import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Descriptions, Divider, Drawer, Table, Tooltip, Col, Tag } from 'antd';
import moment from "moment";
import { MessageOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'usehooks-ts';

import { RootState } from "../../store";
import { botService } from "../../services/bot.service";
import { BotTypes, ZapfyTypes } from "../../types";
import { RegexFactory } from "../../factory/regex.factory";
import { AttendanceTypes } from "../../types/attendance.types";
import { ModalChatViewer } from "../modal-chat-viewer";
import { When } from "react-if";

export const BotDetailsDrawer = () => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const { modal, loading, bot, botAttendancesPagination } = useSelector((state: RootState) => state.bot);

    const [selectedAttendance, setSelectedAttendance] = useState<AttendanceTypes.Model>({} as AttendanceTypes.Model);
    const [viewChatModalVisible, setViewChatModalVisible] = useState(false);
    const [fetchingStatus, setFetchingStatus] = useState<boolean>(false);
    const [status, setStatus] = useState<null | ZapfyTypes.InstanceStateObject>(null);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });

    const onClose = () => {
        botService.dispatch.setBot({} as BotTypes.Model);
        botService.dispatch.toggleModal('details', false);
        setStatus(null);
    }

    const fetchAndSetStatus = async () => {
        setFetchingStatus(true);

        const result = await botService.getState(bot.id);

        setStatus(result);

        setFetchingStatus(false);
    }

    const onChangePagination = (page: number, perPage: number) => {
        setPagination({ page, perPage });
    }

    useEffect(() => {
        if (Object.values(bot)?.length > 0 && modal.details) {
            botService.fetchAttendancesByBot({ ...pagination, filter: {} as AttendanceTypes.Filters }, bot.id, (isValid, msg) => {});
        }
    }, [modal.details, bot, pagination]);


    useEffect(() => {
        if (modal.details) {
            fetchAndSetStatus();
        }
        
    }, [modal.details, bot]);

    if (fetchingStatus) return null;

    return (
        <>
            <Drawer
                title={'Detalhes do bot'}
                placement={'right'}
                open={modal.details}
                onClose={onClose}
                width={isSmallScreen ? '90vw' : 650}
            >
                <Descriptions column={2} size='default' layout='vertical'>
                    <Descriptions.Item label="Nome" labelStyle={{ fontWeight: 600 }}>
                        {bot?.name}
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Status da conexão" labelStyle={{ fontWeight: 600 }}>
                        <Tag className={status?.state === 'connected' ? 'bg-success-lightest' : 'bg-danger-lightest'} color={status?.state === 'connected' ? 'green' : 'red'}>
                            <span className="font-weight-semibold">
                                {String(status?.state) === 'connected' ? 'Conectado' : 'Desconectado'}
                            </span>
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Telefone conectado" labelStyle={{ fontWeight: 600 }}>
                        {status?.state === 'connected' ? RegexFactory.phoneNumber(status?.contact?.id!) : 'Desconectado'}
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="WhatsApp conectado" labelStyle={{ fontWeight: 600 }}>
                        {status?.state === 'connected' ? status?.contact?.name : 'Desconectado'}
                    </Descriptions.Item>
                    
                    <Descriptions.Item span={1} label="Data de criação" labelStyle={{ fontWeight: 600 }}>
                        {moment(bot?.createdAt).format('DD/MM/YYYY hh:mm')}
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Última atualização" labelStyle={{ fontWeight: 600 }}>
                        {moment(bot?.updatedAt).format('DD/MM/YYYY hh:mm')}
                    </Descriptions.Item>
                    
                </Descriptions>

                <Divider />

                <Descriptions title="Atendimentos" style={{ marginTop: 15, marginBottom: -10 }} />

                <div className="table-responsive">
                    <Table 
                        columns={[
                            {
                                title: 'Protocolo',
                                render: (_, att) => <strong># {att.protocol}</strong> 
                            },
                            {
                                title: 'Atendente',
                                render: (_, att) => <>{att?.user?.name}</> 
                            },
                            {
                                title: 'Data de criação',
                                dataIndex: 'createdAt',
                                render: (_, att) => moment(att.createdAt).format('DD/MM/YYYY HH:mm')
                            },
                            {
                                title: 'Ações',
                                dataIndex: 'actions',
                                render: (_, att) => (
                                    <div className="text-center"> 
                                        <Tooltip placement="topLeft" title="Visualizar atendimento">
                                            <MessageOutlined 
                                                size={48}
                                                onClick={() => {
                                                    setSelectedAttendance(att);
                                                    setViewChatModalVisible(true);
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                )
                            }
                        ]} 
                        locale={{
                            emptyText: <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                
                                <span>{'Aparentemente este departamento não possui nenhum atendimento.'}</span>
                            </Col>,
                            
                        }}
                        loading={loading.fetchAttendancesByBot}
                        dataSource={botAttendancesPagination?.items} 
                        pagination={{
                            pageSize: pagination.perPage,
                            total: botAttendancesPagination?.total,
                            defaultCurrent: 1,
                            onChange: onChangePagination
                        }}
                    />
                </div>
            </Drawer>

            <When condition={viewChatModalVisible}>
                <ModalChatViewer 
                    selectedAttendance={selectedAttendance}
                    visible={viewChatModalVisible}
                    onClose={() => {
                        setSelectedAttendance({} as AttendanceTypes.Model);
                        setViewChatModalVisible(false);
                    }}
                />
            </When>
        </>
    )
}