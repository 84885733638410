import { AxiosResponse } from 'axios';
import qs from 'qs-stringify';
import queryStringify from 'qs-stringify';

import { PaginationFactory, PaginatorFactory } from '../factory';
import { BotTypes, DefaultCallback, DefaultRes, ZapfyTypes } from '../types';
import { api, sanitalizeApiException } from "../http/http";
import { store } from '../store';
import { AttendanceTypes } from '../types/attendance.types';

export const botService = {
    dispatch: store.dispatch.bot,

    fetchAttendancesByBot: async (pagination: PaginatorFactory<AttendanceTypes.Filters>, botId: number, callback: DefaultCallback) => {
        try {
            botService.dispatch.toggleLoading('fetchAttendancesByBot', true);

            const qsfy = queryStringify({
                perPage: pagination.perPage,
                page: pagination?.page,
                filter: {
                    ...pagination?.filter,
                },
            });

            const { 
                data: {
                    isValid,
                    message,
                    result,
                }
            } = await api.get<
                DefaultRes<PaginationFactory<AttendanceTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<AttendanceTypes.Model>>>
            >(`/Attendances/ListAttendancesByBot/${botId}?${qsfy}`);

            botService.dispatch.toggleLoading('fetchAttendancesByBot', false);

            if (!isValid) {
                return callback(isValid, message[0]);
            }

            botService.dispatch.setBotAttendancesPagination(result);

            return callback(isValid, message);

        } catch (error) {
            botService.dispatch.toggleLoading('fetchAttendancesByBot', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    connect: async (id: number, callback?: DefaultCallback) => {
        try {
            const { data } = await api.post<
                DefaultRes<ZapfyTypes.ConnectInstanceResult['result']>,
                AxiosResponse<DefaultRes<ZapfyTypes.ConnectInstanceResult['result']>>
            >(`/Bots/${id}/Connect`);

            return data.result;
        } catch (error: any) {
            return callback && callback(false, sanitalizeApiException(error));
        }

    },

    disconnect: async (id: number, callback?: DefaultCallback) => {
        try {
            const { data } = await api.post<
                DefaultRes<ZapfyTypes.ConnectInstanceResult>,
                AxiosResponse<DefaultRes<ZapfyTypes.ConnectInstanceResult>>
            >(`/Bots/${id}/Disconnect`);

            return data.result;
        } catch (error: any) {
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    getState: async (id: number, callback?: DefaultCallback) =>     {
        const { data } = await api.post<
            DefaultRes<ZapfyTypes.InstanceStateObject>,
            AxiosResponse<DefaultRes<ZapfyTypes.InstanceStateObject>>
        >(`/Bots/${id}/GetBotState`);

        
        return data.result;
    },

    destroy: async (id: number, callback?: DefaultCallback) => {
        try {
            botService.dispatch.toggleLoading('destroy', true);

            const { 
                data,
            } = await api.delete<
                DefaultRes<boolean>,
                AxiosResponse<DefaultRes<boolean>>
            >(`/Bots/${id}`);

            if (!data.isValid) {
                botService.dispatch.toggleLoading('destroy', false);
                return callback && callback(false, data?.message);
            }

            await botService.getMany({ page: 1, perPage: 50 });
            botService.dispatch.toggleLoading('destroy', false);
            
            return callback && callback(true, data.message);

        } catch (error) {
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    getMany: async (pagination: PaginatorFactory<BotTypes.Filters>, callback?: DefaultCallback) => {
        try {
            const qsfy = qs({
                perPage: pagination.perPage,
                page: pagination?.page,
                filter: {
                    ...pagination?.filter,
                },
            });

            const { 
                data: { 
                    result: { 
                        items,
                        page,
                        perPage,
                        total,
                    }, 
                isValid, 
                message 
            }} = await api.get<
                DefaultRes<PaginationFactory<BotTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<BotTypes.Model>>>
            >(`/Bots?${qsfy}`);
            
            if (!isValid) {
                return callback && callback(false, message);
            }
            
            botService.dispatch.setPagination({ items, page, perPage, total });

            return callback && callback(true, message);
        } catch (error) {
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    create: async (name: string, skipDepartments?: boolean, info?: any, automaticMessages?: BotTypes.AutomaticMessage[], callback?: DefaultCallback) => {
        try {
            botService.dispatch.toggleLoading('form', true);

            const response = await api.post<
                DefaultRes<BotTypes.Model>,
                AxiosResponse<DefaultRes<BotTypes.Model>>
            >('/Bots', {
                name,
                info,
                automaticMessages,
                skipDepartments,
                channel: "whatsapp"
            });

            if (!response.data.isValid) {
                botService.dispatch.toggleLoading('form', false);
                return callback && callback(false, response.data?.message);
            }

            botService.dispatch.addPaginationItem(response.data.result);

            botService.dispatch.toggleLoading('form', false);

            return callback && callback(true, response.data.message);

        } catch (error) {
            botService.dispatch.toggleLoading('form', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    update: async (id: number, name: string, skipDepartments?: boolean, info?: any, automaticMessages?: BotTypes.AutomaticMessage[], callback?: DefaultCallback) => {
        try {
            botService.dispatch.toggleLoading('form', true);
            const response = await api.put<
                DefaultRes<BotTypes.Model>,
                AxiosResponse<DefaultRes<BotTypes.Model>>
            >(`/Bots/${id}`, {
                name,
                info,
                automaticMessages,
                skipDepartments,
                channel: 'whatsapp'
            });
            
            if (!response.data.isValid) {
                botService.dispatch.toggleLoading('form', false);
                return callback && callback(false, response.data?.message);
            }

            botService.dispatch.updatePaginationItem(id, response.data.result);
            botService.dispatch.toggleLoading('form', false);

            return callback && callback(true, response.data.message);

        } catch (error) {
            botService.dispatch.toggleLoading('form', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },
}