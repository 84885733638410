import React from "react";
import { Modal, Button } from "antd";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { ChatService } from "../../services/chat.service";
import Flex from "../flex";
import { ChatShortcutList } from "../lists/chart-sortcut-list";

export const ChatShortcutListModal = () => {
    const { modal } = useSelector((state: RootState) => state.chat);

    const onClose = () => {
        ChatService.dispatch.toggleModal('chatShortcut', false);
    }

    return (
        <>
            <Modal
                title={'Atalhos'}
                onCancel={onClose}
                open={modal.chatShortcut}
                footer={null}
                cancelText={'Cancelar'}
                onOk={() => {}}
                width={850}
            >
                <Flex flexDirection="row-reverse" className="mb-3">
                    <Button size='small' type="primary" className="ml-2" onClick={() => {
                        ChatService.dispatch.toggleModal('shortcutForm', true);
                    }}>
                        <span>Novo atalho</span>
                    </Button>
                </Flex>
                <ChatShortcutList />
            </Modal>
        </>
    )
};