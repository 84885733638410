import { useState } from "react";
import { Avatar, Menu, Row, message } from "antd"
import { Header } from "antd/lib/layout/layout"
import { 
	UserOutlined,
    PlayCircleOutlined,
    StopOutlined,
    SwapOutlined,
} from '@ant-design/icons';
import { useSelector } from "react-redux";
import { When } from "react-if";

import { EllipsisDropdown } from "../ellipsis-dropdown"
import { AttendanceTypes } from "../../types/attendance.types"
import { RegexFactory } from "../../factory/regex.factory";
import { ChatService } from "../../services/chat.service";
import { RootState, store } from "../../store";
import ConfirmActionModal from "../confirm-action-modal";

interface Props {
    selectedAttendance: AttendanceTypes.Model,
}

export const ChatNavbar: React.FC<Props> = ({ selectedAttendance }) => {
    const { loading } = useSelector((state: RootState) => state.chat);
    const [statusToChange, setStatusToChange] = useState<AttendanceTypes.Status | undefined>();
    const [modal, setModal] = useState({
        confirmChangeStatus: false,
        transferAttendance: false,
    });

    const toggleModal = (id: keyof typeof modal, v: boolean) => setModal(m => ({ ...m, [id]: v }));

    const changeAttendanceStatus = async (
        oldStatus: keyof AttendanceTypes.GetChatAttendancesByUser,
        status: AttendanceTypes.Status
    ) => {
        await ChatService.changeAttendanceStatus(oldStatus, selectedAttendance.id, status, async (isValid, msg) => {
            toggleModal('confirmChangeStatus', false);
            if (!isValid) {
                message.error(msg);
                return;
            }

            await ChatService.getChatAttendancesByUser((isValid, msg) => {});

            message.success(msg);

            if (status === AttendanceTypes.Status.STARTED) {
                ChatService.dispatch.setSelectedAttendanceTab(AttendanceTypes.Status.STARTED);
            }
        });
    }

    return (
        <>
            <Header style={{ 
                padding: 15, 
                justifyContent: 'space-between', 
                background: 'white', 
                alignItems: 'center', 
                display: 'flex', 
                flex: 1,
                flexDirection: 'row',
            }}>
                <When condition={selectedAttendance?.id}>
                    <>
                        <Row className="mt-3">
                            <Avatar     
                                style={{
                                    backgroundColor: '#21B573',
                                    verticalAlign: 'middle',
                                }}
                                src={selectedAttendance?.photo} 
                            >
                                {selectedAttendance?.pushName ? selectedAttendance?.pushName[0] : ''}
                            </Avatar>
                            <div className="ml-2">
                                <h5 className="mb-0">{selectedAttendance?.pushName} - <strong>{`(#${selectedAttendance?.protocol})`}</strong></h5>
                                <p>{RegexFactory.phoneNumber(selectedAttendance?.phoneNumber!)}</p>
                            </div>  
                        </Row>
                        <Row>
                            <EllipsisDropdown menu={
                                <Menu>
                                    <When condition={selectedAttendance?.status !== AttendanceTypes.Status.STARTED && selectedAttendance?.status !== AttendanceTypes.Status.FINISHED}>
                                        <Menu.Item key="1" onClick={() => {
                                            setStatusToChange(AttendanceTypes.Status.STARTED);
                                            toggleModal('confirmChangeStatus', true);
                                        }}>
                                            <PlayCircleOutlined />
                                            <span>Iniciar atendimento</span>
                                        </Menu.Item>
                                    </When>

                                    {selectedAttendance?.status !== AttendanceTypes.Status.FINISHED && 
                                        <Menu.Item 
                                            key="2" 
                                            onClick={() => {
                                                ChatService.dispatch.toggleModal('transferAttendance', true);
                                            }}
                                        >
                                            <SwapOutlined />
                                            <span>Transferir atendimento</span>
                                        </Menu.Item>
                                    }

                                    <Menu.Item key="3" onClick={() => {
                                        setStatusToChange(AttendanceTypes.Status.FINISHED);
                                        toggleModal('confirmChangeStatus', true);
                                    }}>
                                        <StopOutlined />
                                        <span>Finalizar atendimento</span>
                                    </Menu.Item>

                                    <Menu.Item key="4" onClick={() => {
                                        ChatService.dispatch.setSelectedLeadId(selectedAttendance.leadId!);
                                        ChatService.dispatch.toggleModal('leadDetails', true);
                                    }}>
                                        <UserOutlined />
                                        <span>Detalhes do lead</span>
                                    </Menu.Item>

                                    <Menu.Divider />
                                </Menu>
                            }/>
                        </Row>
                    </>
                </When>
            </Header>

            <ConfirmActionModal
                title={statusToChange === AttendanceTypes.Status.FINISHED ? 'Finalizar atendimento' : 'Iniciar atendimento'}
                loading={loading.changeAttendanceStatus}
                status={statusToChange === AttendanceTypes.Status.FINISHED ? 'warning' : 'info'}
                visible={modal.confirmChangeStatus}
                contentSubtitle={statusToChange === AttendanceTypes.Status.FINISHED ? "O atendimento será finalizado e você não poderá mais continuar com ele, a não ser que crie um novo." : "O cliente será notificado e você estará atuando no atendimento."}
                onCancel={() => {
                    setStatusToChange(undefined);
                    toggleModal('confirmChangeStatus', false);
                }}
                onDelete={async () => {
                    await changeAttendanceStatus(selectedAttendance.status as keyof AttendanceTypes.GetChatAttendancesByUser, statusToChange!);
                }}
            />
        </>
    )
}