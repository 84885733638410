import type { RootModel } from '.';
import { createModel } from "@rematch/core";

import { PaginationFactory } from '../factory';
import { UserTypes } from '../types';
import { AttendanceTypes } from '../types/attendance.types';

export interface UserStoreProps {
    user: UserTypes.Model,
    authUser: UserTypes.Model,
    token: string,
    pagination: PaginationFactory<UserTypes.Model>,
    sessionPagination: PaginationFactory<UserTypes.Session>,
    userAttendancesPagination: PaginationFactory<AttendanceTypes.Model>,
    loading: {
        login: boolean,
        register: boolean,
        forgetPassword: boolean,
        resetPassword: boolean,
        fetchUserAttendances: boolean,
        updatePassword: boolean,
        updateProfile: boolean,
        fetchSessions: boolean,
    },
    modal: {
        updateProfile: boolean,
        details: boolean,
    },
};
export const user = createModel<RootModel>()({
    state: {
        user: {} as UserTypes.Model,
        authUser: {} as UserTypes.Model,
        token: '',
        pagination: {} as PaginationFactory<UserTypes.Model>,
        sessionPagination: {} as PaginationFactory<UserTypes.Session>,
        loading: {
            login: false,
            register: false,
            forgetPassword: false,
            resetPassword: false,
            fetchUserAttendances: false,
            updatePassword: false,
            updateProfile: false,
            fetchSessions: false,
        },
        modal: {
            updateProfile: false,
            details: false,
        },
        userAttendancesPagination: {
            items: [] as AttendanceTypes.Model[],
        } as PaginationFactory<AttendanceTypes.Model>,
    },
    reducers: {
        setUserAttendancesPagination: (store, pagination: UserStoreProps['userAttendancesPagination']) => {
            store.userAttendancesPagination = pagination;
        },
        setUserSessionsPagination: (store, pagination: UserStoreProps['sessionPagination']) => {
            store.sessionPagination = pagination;
        },
        eraseFormModal: (store) => {
            store.modal.updateProfile = false;
        },
        toggleModal: (store, prop: keyof UserStoreProps['modal'], v: boolean) => {
            store.modal[prop] = v;
        },
        toggleUserAttendanceStatus: (store, isOnFire: boolean) => {
            store.authUser.isOnFire = isOnFire;
        },
        toggleLoading: (
            store, prop: keyof UserStoreProps['loading'], v: boolean) => {
                store.loading[prop] = v;
            },
        destroySession: (store) => {    
            store.authUser = {} as UserTypes.Model;
            store.token = '';
        },
        setAuthUser: 
        (store, profile: UserTypes.UpdateProfile) => {
            store.authUser = { ...store.authUser, ...profile };
        },
        setAuthUserProps:
        (store, user: UserTypes.Model) => {
            store.authUser = user;
        },
        auth: 
            (store, user: UserTypes.Model, token: string) => {
                store.authUser = user;
                store.token = token;
            },
        addUser: 
            (store, user: UserTypes.Model) => {
                store.pagination.items = [user].concat(store.pagination.items);
            },
        updateUser: 
            (store, user: UserTypes.Model) => {
                store.pagination.items = store.pagination.items?.map(i => {
                    if (i.id === user.id) {
                        return user;
                    }
                    return i;
                });
            },
        setUser: 
            (store, user: UserTypes.Model) => {
                store.user = user;
            },
        setPagination: 
            (store, pagination: PaginationFactory<UserTypes.Model>) => {
                store.pagination = pagination;
            },
        destroyUser: 
            (store, id: number) => {
                store.pagination.items = store.pagination.items.filter(i => i.id !== id);
            },
    },
});
