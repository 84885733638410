import { useEffect, useState } from "react";
import { BotTypes } from "../../../types";
import { Checkbox, Col, Form, Input, Modal, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Rule } from "antd/lib/form";

interface Props {
    visible: boolean,
    editMessage?: BotTypes.AutomaticMessage | null,
    onSubmit: (message: BotTypes.AutomaticMessage) => void,
    setVisible: (visible: boolean) => void,
}

export const AddBotMessageModal: React.FC<Props> = ({ 
    visible,
    editMessage,
    setVisible,
    onSubmit,
}) => {
    const [form] = Form.useForm<BotTypes.AutomaticMessage>();
    const redirectToAttendance = Form.useWatch('shouldRedirectToAttendance', form);

    const rules: Record<string, Rule[]> = {
        text: [
            { 
                required: true,
                message: 'Informe o texto a ser exibido como opção'
            },
        ],
        reply: [
            {
                required: true,
                message: 'Insira o texto de resposta da opção'
            }
        ],
    }

    useEffect(() => {
        if (editMessage) {
            form.setFieldsValue(editMessage);
        } else {
            form.setFieldsValue({
                shouldRedirectToAttendance: false,
                text: '',
                reply: '',
            });
        }
    }, [visible, editMessage]);

    return (
        <Modal 
            title="Adicionar mensagem automática"
            open={visible} 
            onCancel={() => {
                setVisible(false);
            }}
            okText={'Salvar'}
            cancelText={'Cancelar'}
            width={450}
            onOk={() => {
                form.submit();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={(values) => {
                    onSubmit(values);
                }}
            >
                <Form.Item
                    name={"text"}
                    label={'Texto a ser exibido na opção'}
                    hasFeedback
                    rules={rules.text}
                    style={{ marginBottom: 5 }}
                >   
                    <Input  />
                </Form.Item>
                <Typography.Text className="text-muted">
                    O texto será exibido como opção para o usuário.
                </Typography.Text>

                <Form.Item
                    name={"reply"}
                    label={'Resposta após selecionar opção'}
                    hasFeedback
                    rules={rules.reply}
                    style={{ marginBottom: 5, marginTop: 20 }}
                >   
                    <TextArea  />
                </Form.Item>
                <Typography.Text className="text-muted">
                    Aqui será o texto que o robô responderá após a opção ser selecionada.
                </Typography.Text>

                <Form.Item
                    name={'shouldRedirectToAttendance'}
                    style={{ marginBottom: 5, marginTop: 20 }}
                >
                    <Checkbox defaultChecked={redirectToAttendance} checked={redirectToAttendance} onChange={(e) => form.setFieldValue('shouldRedirectToAttendance', e.target.checked)}>
                        Redirecionar para atendimento humano
                    </Checkbox>
                </Form.Item>
                <Typography.Text className="text-muted">
                    Ao selecionar essa opção o usuário será redirecionado para o chat com um atendente.
                </Typography.Text>
            </Form>
        </Modal>
    )
}