import React, { useRef, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import { NAV_TYPE_TOP } from '../../constants/theme.constants';

interface Props {
    children?: React.ReactElement,
    background?: string,
    className?: string,
    overlap?: boolean,
    navType?: string,
	breadcrumb?: React.ReactElement,
}

export const PageHeaderAlt: React.FC<Props> = ({ children, background, className, overlap, navType, breadcrumb }) => {
	const [widthOffset, setWidthOffset] = useState(0)
	const ref = useRef(null);

    useEffect(() => {
		if (navType === NAV_TYPE_TOP) {
			const windowSize = window.innerWidth;
			const pageHeaderSize = (ref?.current as any)?.offsetWidth;
			setWidthOffset( (windowSize - pageHeaderSize) / 2 );
		}
	}, [navType]);
	
	const getStyle = () => {
		let style: any = { backgroundImage: background ? `url(${background})` : 'none' } ;
		if (navType === NAV_TYPE_TOP) {
			style.marginRight = -widthOffset;
			style.marginLeft = -widthOffset;
			style.paddingLeft = 0;
			style.paddingRight = 0;
		};
		return style;
	}

	return (
		<div
			ref={ref}
			className={`page-header-alt ${className ? className : ''} ${overlap && 'overlap'}`} 
			style={getStyle()}
		>
			{breadcrumb}
			{navType === NAV_TYPE_TOP ? <div className="container">{children}</div> : <>{children}</>}
		</div>
	)
}