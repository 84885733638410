import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MessageOutlined } from '@ant-design/icons';
import { Descriptions, Divider, Drawer, Table, Tooltip, Col, Tag } from 'antd';
import moment from "moment";
import { When } from "react-if";
import { useMediaQuery } from 'usehooks-ts';

import { RootState } from "../../store";
import { UserService } from "../../services/user.service";
import { AttendanceTypes } from "../../types/attendance.types";
import { ModalChatViewer } from "../modal-chat-viewer";
import { UserTypes } from "../../types";

export const UserDetailsDrawer = () => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const { modal, loading, user, userAttendancesPagination } = useSelector((state: RootState) => state.user);

    const [selectedAttendance, setSelectedAttendance] = useState<AttendanceTypes.Model>({} as AttendanceTypes.Model);
    const [viewChatModalVisible, setViewChatModalVisible] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });

    const onClose = () => {
        UserService.dispatch.setUser({} as UserTypes.Model);
        UserService.dispatch.toggleModal('details', false);
    }

    const onChangePagination = (page: number, perPage: number) => {
        setPagination({ page, perPage });
    }

    useEffect(() => {
        if (Object.values(user)?.length > 0 && modal.details) {
            UserService.fetchAttendancesByUserId({ ...pagination, filter: {} as AttendanceTypes.Filters }, user.id, (isValid, msg) => {});
        }
    }, [modal.details, user, pagination]);

    return (
        <>
            <Drawer
                title={'Detalhes do Atendente'}
                placement={'right'}
                open={modal.details}
                onClose={onClose}
                width={isSmallScreen ? '90vw' : 850}
            >
                <Descriptions column={2} size='default' layout='vertical'>
                    <Descriptions.Item span={1} label="Nome" labelStyle={{ fontWeight: 600 }}>
                        {user?.name}
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Email" labelStyle={{ fontWeight: 600 }}>
                        {user?.email}
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Está online? (atendimento)" labelStyle={{ fontWeight: 600 }}>
                        {user?.isOnFire ? 'Sim' : 'Não'}
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Permissões" labelStyle={{ fontWeight: 600 }}>
                        <Tag color="green">
                            {
                                Object.keys(user?.permissions?.flag || {})?.includes('ADMIN') ? 
                                    `${'Administrador'}` : 
                                    `${Object.keys(user?.permissions?.flag || {})?.length} ${'Permissões'}`
                            }
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Finalizou o cadastro?" labelStyle={{ fontWeight: 600 }}>
                        {user?.isFirstTime ? 'Não' : 'Sim'}
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Usuário habilitado?" labelStyle={{ fontWeight: 600 }}>
                        {user?.enabled ? 'Sim' : 'Não'}
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Data de criação?" labelStyle={{ fontWeight: 600 }}>
                        {moment(user?.createdAt).format('DD/MM/YYYY hh:mm')}
                    </Descriptions.Item>
                    <Descriptions.Item span={1} label="Último login" labelStyle={{ fontWeight: 600 }}>
                        {moment(user?.lastLogin).format('DD/MM/YYYY hh:mm')}
                    </Descriptions.Item>
                </Descriptions>
                <Divider />

                <Descriptions title="Atendimentos" style={{ marginTop: 15, marginBottom: -10 }} />

                <div>
                    <Table 
                        columns={[
                            {
                                title: 'Protocolo',
                                render: (_, att) => <strong># {att.protocol}</strong> 
                            },
                            {
                                title: 'Atendente',
                                render: (_, att) => <>{att?.user?.name}</> 
                            },
                            {
                                title: 'Lead',
                                render: (_, att) => <>{att?.lead?.name || att?.pushName}</> 
                            },
                            {
                                title: 'Status',
                                render: (_, att) => 
                                <Tag 
                                    className={
                                        att?.status === AttendanceTypes.Status.STARTED ? 
                                        'bg-warning-lightest' : 'bg-success-lightest'
                                    } 
                                    color={att?.status === AttendanceTypes.Status.STARTED ? 'yellow' : 'green'}>
                                    <span className="font-weight-semibold">
                                        {att?.status === AttendanceTypes.Status.STARTED  ? 'Em Atendimento' : 'Concluído'}
                                    </span>
                                </Tag>
                            },
                            {
                                title: 'Iniciado em',
                                dataIndex: 'createdAt',
                                render: (_, att) => moment(att.createdAt).format('DD/MM/YYYY HH:mm')
                            },
                            {
                                title: 'Ações',
                                dataIndex: 'actions',
                                render: (_, att) => (
                                    <div className="text-center"> 
                                        <Tooltip placement="topLeft" title="Visualizar atendimento">
                                            <MessageOutlined 
                                                size={48}
                                                onClick={() => {
                                                    setSelectedAttendance(att);
                                                    setViewChatModalVisible(true);
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                )
                            }
                        ]} 
                        locale={{
                            emptyText: <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                
                                <span>{'Aparentemente este atendente não possui nenhum atendimento.'}</span>
                            </Col>,
                            
                        }}
                        loading={loading.fetchUserAttendances}
                        dataSource={userAttendancesPagination?.items} 
                        pagination={{
                            pageSize: pagination.perPage,
                            total: userAttendancesPagination?.total,
                            defaultCurrent: 1,
                            onChange: onChangePagination
                        }}
                    />
                </div>
            </Drawer>
            <When condition={viewChatModalVisible}>
                <ModalChatViewer 
                    selectedAttendance={selectedAttendance}
                    visible={viewChatModalVisible}
                    onClose={() => {
                        setSelectedAttendance({} as AttendanceTypes.Model);
                        setViewChatModalVisible(false);
                    }}
                />
            </When>
        </>
    )
}