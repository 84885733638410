import React, { useEffect, useState } from 'react';
import { Button, Table, Card, Input, Menu, message, Empty, Form } from 'antd';
import { EyeOutlined, DeleteOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { When } from 'react-if';
import { Rule } from 'antd/lib/form';

import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import Flex from '../../flex';
import { EllipsisDropdown } from '../../ellipsis-dropdown';
import { departmentService } from '../../../services/department.service';
import ConfirmActionModal from '../../confirm-action-modal';
import { DepartmentTypes } from '../../../types';
import { useCheckPriv } from '../../../hooks/useCheckPriv';
import { modulePermissions } from '../../../constants/module.permissions';

interface Props {
    enableFilters?: boolean,
    defaultSelectedRowKeys?: number[] | string[],
    onRowSelect?(rows: DepartmentTypes.Model[]): void,
};

export const DepartmentList: React.FC<Props> = ({ enableFilters = true, defaultSelectedRowKeys, onRowSelect }) => {
    const [filterForm] = Form.useForm<DepartmentTypes.Form>();
    const { checkPriv } = useCheckPriv();
    const { pagination: departmentPagination, loading } = useSelector((state: RootState) => state.department);
    const [selectedDepartment, setSelectedDepartment] = useState<DepartmentTypes.Model>({} as DepartmentTypes.Model);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 15,
    });

    const rules: Record<string, Rule[]> = {
        name: [
            { 
                required: false,
            },
        ],
    }

    const onEditPress = (department: DepartmentTypes.Model) => {
        departmentService.dispatch.setDepartment(department);
        departmentService.dispatch.toggleModal('form', true);
    }

    const onDeletePress = () => {
        setDeleteModal(true);
    }

    const onDeleteCancel = () => {
        setDeleteModal(false);
    }

    const onChangePagination = (page: number, perPage: number) => {
        setPagination({ page, perPage });
    }

    const onDeleteConfirm = async () => {
        await departmentService.destroy([selectedDepartment?.id], (isValid, msg) => {
            if (!isValid) {
                message.error(msg);
                return;
            }

            message.success('Seu departamento foi excluído com sucesso 🧨');
            onDeleteCancel();
        });
    }

    const onFilter = async (filter: DepartmentTypes.Filters, clear = false) => {
        if (clear) {
            filterForm.resetFields();
        }

        await departmentService.getMany({ ...pagination, filter: clear ? {} as DepartmentTypes.Filters : { ..._.omitBy(filter, _.isNil) } as DepartmentTypes.Filters });
    }

    useEffect(() => {
        departmentService.getMany({ ...pagination });
    }, [pagination]);

    return (
        <>
            <ConfirmActionModal 
                title={'Excluir departamento'} 
                loading={loading.destroy} 
                visible={deleteModal} 
                onCancel={onDeleteCancel} 
                onDelete={onDeleteConfirm} 
            />
            <Card>
                <When condition={enableFilters}>
                    <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
                        <>
                            <Form
                                layout="vertical"  
                                form={filterForm}
                                onFinish={async (values) => await onFilter(values)}
                            >
                                <Flex className="mb-1" mobileFlex={false}>
                                    <>
                                        <div className="mr-md-6 mb-3">
                                            <Form.Item 
                                                name="name" 
                                                rules={rules.name}
                                                hasFeedback
                                            >
                                                <Input 
                                                    placeholder="Pesquisar por nome" 
                                                    prefix={<SearchOutlined />} 
                                                />
                                            </Form.Item>
                                        </div>
                                    </>
                                </Flex>
                            </Form>
                            
                            <Flex flexDirection='row-reverse' className='p-2'>
                                <>
                                    <Button type="primary" className='mr-2' block onClick={async () => await filterForm.submit()}>
                                        <span>Buscar</span>
                                        <SearchOutlined />
                                    </Button>
                                    <Button type="default" block onClick={async () => await onFilter({} as DepartmentTypes.Filters, true)}>Limpar filtro</Button>
                                </>
                            </Flex>
                        </>
                    </Flex>
                </When>
                {checkPriv(modulePermissions.departments.DEPARTMENT_GET_MANY) &&
                    <div className="table-responsive">
                        <Table 
                            columns={[
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                },
                                {
                                    title: 'Nome',
                                    dataIndex: 'name',
                                },
                                {
                                    title: 'Data de criação',
                                    dataIndex: 'createdAt',
                                    render: (_, dep) => moment(dep.createdAt).format('DD/MM/YYYY HH:mm')
                                },
                                {
                                    title: 'Última atualização',
                                    dataIndex: 'updatedAt',
                                    render: (_, dep) => moment(dep.updatedAt).format('DD/MM/YYYY HH:mm')
                                },
                                {
                                    title: 'Ações',
                                    dataIndex: 'actionss',
                                    render: (_, dep) => (
                                        <div className="text-left">
                                            <EllipsisDropdown 
                                                menu={
                                                    <Menu>
                                                        
                                                        <Menu.Item key="0" onClick={() => {
                                                            departmentService.dispatch.setDepartment(dep);
                                                            departmentService.dispatch.toggleModal('details', true);
                                                        }}>
                                                            <EyeOutlined />
                                                            <span className="ml-2">Visualizar</span>
                                                        </Menu.Item>
                                                        {checkPriv(modulePermissions.departments.DEPARTMENT_UPDATE) && 
                                                            <Menu.Item key="1" onClick={() => onEditPress(dep)}>
                                                                <EditOutlined />
                                                                <span className="ml-2">Editar</span>
                                                            </Menu.Item>
                                                        }
                                                        <Menu.Divider />

                                                        {checkPriv(modulePermissions.departments.DEPARTMENT_DELETE) && 
                                                            <Menu.Item key="2" onClick={() => {
                                                                setSelectedDepartment(dep);
                                                                onDeletePress();
                                                            }}>
                                                                <DeleteOutlined />
                                                                <span className="ml-2">Excluir</span>
                                                            </Menu.Item>
                                                        }
                                                        
                                                    </Menu>
                                                }
                                            />
                                        </div>
                                    )
                                }
                            ]} 
                            dataSource={departmentPagination.items} 
                            loading={loading.fetchMany}
                            rowKey={'id'}
                            rowSelection={{
                                type: 'checkbox',
                                defaultSelectedRowKeys: defaultSelectedRowKeys,
                                onChange: (selectedRowKeys: React.Key[], selectedRows) => {
                                    onRowSelect && onRowSelect(selectedRows);
                                }
                            }}  
                            locale={{
                                emptyText: 
                                    <Empty className='mt-5 mb-5' image={Empty.PRESENTED_IMAGE_DEFAULT} description={'Aparentemente, você ainda não criou nenhum departamento.'} />
                            }}
                            pagination={{
                                pageSize: pagination.perPage,
                                total: departmentPagination.total,
                                defaultCurrent: 1,
                                onChange: onChangePagination
                            }}
                        />
                    </div>
                }
            </Card>
        </>
    )
}