import React, { useState } from 'react';
import {
  Modal, 
  Row,
  Switch,
  Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ChatService } from '../../services/chat.service';

export const SettingsNotificationModal = () => {
    const { modal, settings } = useSelector((state: RootState) => state.chat);
    const [notification, setNotification] = useState({ ...settings.notification });
    
    return (
        <Modal 
            title="Notificações"
            open={modal.settingsNotification} 
            onCancel={() => ChatService.dispatch.toggleModal('settingsNotification', false)}
            okText={'Salvar'}
            cancelText={'Cancelar'}
            width={450}
            onOk={() => {
                ChatService.dispatch.setNotificationSettings(notification);
                ChatService.dispatch.toggleModal('settingsNotification', false);
                window.location.reload();
            }}
        >
            <Row justify={'space-between'} style={{ marginBottom: 10 }}>
                <Typography.Text>Ativar alerta sonoro</Typography.Text>
                <Switch 
                    checked={notification?.sound} 
                    size='default' 
                    checkedChildren="ON" 
                    unCheckedChildren="OFF" 
                    style={{ marginRight: 10 }}
                    onChange={(checked) => setNotification(n => ({ ...n, sound: checked }))}
                />
            </Row>
            <Row justify={'space-between'}>
                <Typography.Text>Ativar notificações de texto</Typography.Text>
                <Switch 
                    checked={notification?.popup} 
                    size='default' 
                    checkedChildren="ON" 
                    unCheckedChildren="OFF" 
                    onChange={(checked) => setNotification(n => ({ ...n, popup: checked }))}
                    style={{ marginRight: 10 }} 
                />
            </Row>
        </Modal>
    )
}