import { Col, Row, Typography } from "antd";
import { ChangePasswordForm } from "../../../components/forms/chage-password-form";

const backgroundURL = '/img/others/screen-green-bg.png'
const backgroundStyle = {
    backgroundImage: `url(${backgroundURL})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
}

const ChangePasswordScreen = () => {
    return (
        <div className={`h-100 'bg-white'`}>
            <Row justify="center" className="align-items-stretch h-100">
                <Col xs={20} sm={20} md={24} lg={16}>
                    <div className="container d-flex flex-column justify-content-center h-100">
                        <Row justify="center">
                            <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                                <Typography.Title level={2}>Bem-vindo ao Tiger Desk</Typography.Title>
                                <p>É o seu primeiro login, por isso precisamos que altere a senha temporária por segurança 😉</p>
                                <div className="mt-4">
                                    {/* <RegisterForm {...props} /> */}
                                    <ChangePasswordForm />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={0} sm={0} md={0} lg={8}>
                    <div className="d-flex flex-column justify-content-between h-100 px-4" style={backgroundStyle}>
                        <div className="text-right">
                            {/**<img src="/img/logo-white.png" alt="logo" />**/}
                            <Typography.Title level={2} style={{ fontWeight: 600, color: '#FFF', margin: 10 }}>
                                Tiger Desk
                            </Typography.Title>
                        </div>
                        <Row justify="center">
                            <Col xs={0} sm={0} md={0} lg={20}>
                                <img className="img-fluid mb-5" src="/img/others/img-19.png" alt="" />
                                <h1 className="text-white">Bem-vindo ao Tiger Desk</h1>
                                <p className="text-white">
                                    Envios em massa, chatbot, multiatendimento e tudo
                                    que você precisa para revolucionar a comunicação
                                    da sua empresa!
                                </p>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end pb-4">
                            <div>
                                <a className="text-white" href="/#" onClick={e => e.preventDefault()}>Termos e condições</a>
                                <span className="mx-2 text-white"> | </span>
                                <a className="text-white" href="/#" onClick={e => e.preventDefault()}>Políticas de privacidade</a>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ChangePasswordScreen;