import React, { useState } from 'react';
import { Button, Form, Input, Alert, message } from "antd";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Rule } from 'antd/lib/form';
import { motion } from "framer-motion";
import _ from 'lodash';

import { RootState } from './../../../store';
import { UserService } from '../../../services/user.service';

export const ChangePasswordForm = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const { authUser, loading } = useSelector((state: RootState) => state.user);

    const [form] = Form.useForm<{ oldPassword: string, newPassword: string, confirmPassword: string }>();

    const onSubmit = async (values: any) => {
        await UserService.updatePassword(values.oldPassword, values.newPassword, authUser, (isValid, msg) => {
            if (isValid) {
                message.success('Senha alterada com sucesso!');
                if (authUser.organizations.status === 'pending') {
                    navigate('/business-info');
                } else {
                    navigate('/');
                }
            } else {
                message.error(msg);
            }
        });
    };

    const passwordRules: Record<string, Rule[]> = {
        oldPassword: [
            {
                required: true,
                message: 'Informe a senha atual'
            },
        ],
        newPassword: [
            {
                required: true,
                min: 8,
                max: 30,
                message: 'A senha deve conter pelo menos 8 digitos'
            },
        ],
        confirmPassword: [
            {
                required: true,
                min: 8,
                max: 30,
                message: 'A senha deve conter pelo menos 8 digitos'
            },
            ({ getFieldValue }) => ({
                validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject('As senhas informadas não são iguais');
                },
            })
        ],
    }

    return (
        <>
            <motion.div
                initial={{ opacity: 0, marginBottom: 0 }}
                animate={{
                    opacity: errorMessage ? 1 : 0,
                    marginBottom: errorMessage ? 20 : 0
                }}>
                <Alert type="error" showIcon message={errorMessage}></Alert>
            </motion.div>
            <Form
                form={form}
                layout="vertical"
                name="change-password"
                onFinish={onSubmit}
            >
                <Form.Item
                    name="oldPassword"
                    label="Senha atual"
                    rules={passwordRules.oldPassword}
                    required
                >
                    <Input.Password
                        placeholder="Senha"
                    />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label="Nova Senha"
                    rules={passwordRules.newPassword}
                    required
                >
                    <Input.Password
                        placeholder="Senha"
                    />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label="Confirmar Senha"
                    rules={passwordRules.confirmPassword}
                    required
                >
                    <Input.Password
                        placeholder="Senha"
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block loading={loading.updatePassword}>
                        Confirmar
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}