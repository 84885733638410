import type { RootModel } from '.';
import { createModel } from "@rematch/core";

import { PaginationFactory } from '../factory';
import { AttendanceTypes } from '../types/attendance.types';

export interface AttendanceStoreProps {
    pagination: PaginationFactory<AttendanceTypes.Model>,
    loading: {
        fetchMany: boolean,
    },
}

export const attendance = createModel<RootModel>()({
    state: {
        pagination: {
            items: [] as AttendanceTypes.Model[],
        } as PaginationFactory<AttendanceTypes.Model>,
        loading: {
            fetchMany: false,
        },
    },
    reducers: {
        toggleLoading: (
            store, prop: keyof AttendanceStoreProps['loading'], v: boolean) => {
                store.loading[prop] = v;
            },
        setPagination: 
            (store, pagination: AttendanceStoreProps['pagination']) => {
                store.pagination = pagination;
            },
    }
});