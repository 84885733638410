import { Modal } from "antd";
import { useBetween } from 'use-between';

import { useTutorial } from "../../hooks/useTutorial"

export const TutorialVideoModal = () => {
    const { tenant, urls, visible, setVisible } = useBetween(useTutorial);

    return (
        <Modal
            title={tenant.title}
            open={visible}
            okText={'Ok, entendi'}
            cancelText={'Fechar'}
            width={'60vw'}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
        >
            <div style={{ position: 'relative', paddingBottom: '62.5%', height: 0 }}>
                <iframe 
                    src={urls[tenant?.tutorialKey!] || ''}

                    allowFullScreen 
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                >
                </iframe>
            </div>
            
        </Modal>
    )
}