import { AxiosResponse } from 'axios';
import qs from 'qs-stringify';
import { PaginationFactory, PaginatorFactory } from '../factory';

import { api, sanitalizeApiException } from "../http/http";
import { store } from '../store';
import { DefaultCallback, DefaultRes } from '../types';
import { CampaignTypes } from '../types/campaign.types';

export const campaignService = {
    dispatch: store.dispatch.campaign,

    getMany: async (pagination: PaginatorFactory<CampaignTypes.Filters>, callback?: DefaultCallback) => {
        try {
            campaignService.dispatch.toggleLoading('fetchMany', true);

            const qsfy = qs({
                perPage: pagination.perPage,
                page: pagination?.page,
                orderBy: pagination?.orderBy,
                sortOrder: pagination.sortOrder,
                filter: {
                    ...pagination?.filter,
                },
            });

            const { 
                data: { 
                    result: { 
                        items,
                        page,
                        perPage,
                        total,
                    }, 
                isValid, 
                message 
            }} = await api.get<
                DefaultRes<PaginationFactory<CampaignTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<CampaignTypes.Model>>>
            >(`/Campaigns?${qsfy}`);
            
            if (!isValid) {
                campaignService.dispatch.toggleLoading('fetchMany', false);
                return callback && callback(false, message[0]);
            }
            
            campaignService.dispatch.setPagination({ items, page, perPage, total });

            campaignService.dispatch.toggleLoading('fetchMany', false);
            return callback && callback(true, message[0]);
        } catch (error) {
            campaignService.dispatch.toggleLoading('fetchMany', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    resend: async (id: number, callback: DefaultCallback) => {
        try {
            campaignService.dispatch.toggleLoading('resend', true);

            console.log('id', id)

            const response = await api.post<
                DefaultRes<CampaignTypes.Model>,
                AxiosResponse<DefaultRes<CampaignTypes.Model>>
            >(`/Campaigns/Resend/${Number(id)}`);

            campaignService.dispatch.toggleLoading('resend', false);

            if (!response.data.isValid) {
                return callback(false, response.data?.message[0]);
            }

            const campaign = store.getState().campaign.pagination.items.find(c => c.id === id);

            if (campaign) {
                campaignService.dispatch.updatePaginationItem(id, { ...campaign, status: 'executing' });
            }

            return callback(true, 'Feito, a campanha será reenviada para todos os leads!');
        } catch (error) {
            campaignService.dispatch.toggleLoading('resend', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    create: async (
        dto: CampaignTypes.CreateParams,
        callback: DefaultCallback
    ) => {
        try {
            campaignService.dispatch.toggleLoading('form', true);

            const response = await api.post<
                DefaultRes<CampaignTypes.Model>,
                AxiosResponse<DefaultRes<CampaignTypes.Model>>
            >('/Campaigns', {
                ...dto,
                args: {
                    fileUrl: dto.fileUrl,
                    buttons: dto?.buttons?.map((button, i) => ({
                        id: `${i}-button`,
                        body: button,
                    })),
                    resend: dto.resend,
                }
            });
            
            if (!response.data.isValid) {
                campaignService.dispatch.toggleLoading('form', false);
                return callback(false, response.data?.message[0]);
            }

            campaignService.dispatch.addPaginationItem(response.data.result);
            campaignService.dispatch.toggleLoading('form', false);

            return callback(true, 'Campanha criada com sucesso!');

        } catch (error) {
            campaignService.dispatch.toggleLoading('form', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    cancel: async (id: number, callback: DefaultCallback) => {
        try {
            campaignService.dispatch.toggleLoading('cancel', true);

            console.log('id', id)

            const response = await api.post<
                DefaultRes<CampaignTypes.Model>,
                AxiosResponse<DefaultRes<CampaignTypes.Model>>
            >(`/Campaigns/Cancel/${Number(id)}`);

            campaignService.dispatch.toggleLoading('cancel', false);

            if (!response.data.isValid) {
                return callback(false, response.data?.message[0]);
            }

            const campaign = store.getState().campaign.pagination.items.find(c => c.id === id);

            if (campaign) {
                campaignService.dispatch.updatePaginationItem(id, { ...campaign, status: 'executing' });
            }

            return callback(true, 'Feito, a campanha foi pausada!');
        } catch (error) {
            campaignService.dispatch.toggleLoading('cancel', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },

    continue: async (id: number, callback: DefaultCallback) => {
        try {
            campaignService.dispatch.toggleLoading('continue', true);

            console.log('id', id)

            const response = await api.post<
                DefaultRes<CampaignTypes.Model>,
                AxiosResponse<DefaultRes<CampaignTypes.Model>>
            >(`/Campaigns/Continue/${Number(id)}`);

            campaignService.dispatch.toggleLoading('continue', false);

            if (!response.data.isValid) {
                return callback(false, response.data?.message[0]);
            }

            const campaign = store.getState().campaign.pagination.items.find(c => c.id === id);

            if (campaign) {
                campaignService.dispatch.updatePaginationItem(id, { ...campaign, status: 'executing' });
            }

            return callback(true, 'Feito, a campanha foi resumida!');
        } catch (error) {
            campaignService.dispatch.toggleLoading('continue', false);
            return callback && callback(false, sanitalizeApiException(error));
        }
    },
}