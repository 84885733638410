import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { DefaultRes } from '../types';
import { api } from '../http/http';

export function useGenericQuery<ResultWithoutDefaultRes>(
    queryName: string, 
    urlToHit: string,
    refetchInterval?: number
) {
    const makeGenericQuery = async () => {
        const { data } = await api.get<
            DefaultRes<ResultWithoutDefaultRes>,
            AxiosResponse<DefaultRes<ResultWithoutDefaultRes>>
        >(`/${urlToHit}`);

        return data.result;
    };

    return useQuery([queryName], makeGenericQuery, { 
        refetchInterval: refetchInterval || false,
    });
}
