import { useState } from "react"

export const useTutorial = () => {
    const [tenant, setTenant] = useState({
        title: '',
        description: '',
        tutorialKey: '',
    });
    const [visible, setVisible] = useState(false);

    const urls: Record<string, string> = {
        bot: "https://www.loom.com/embed/2a747412d3cd470fa8991db31de5f680?sid=09efeea0-bde6-421e-bf34-1d5866afca5e"
    }

    const showTutorial = (
        title: string,
        description: string,
        tutorialKey: string,
    ) => {
        console.log('oi')
        setTenant({ title, tutorialKey, description });
        setVisible(true);
    };

    return { visible, tenant, urls, setVisible, showTutorial };
}